import * as DataActions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { HandoverProtocol } from '../handover-protocol.model';

export interface State {
  handoverProtocols: {
    data: HandoverProtocol[];
    loading: boolean;
  };
  handoverProtocolDetails: {
    data: HandoverProtocol | null;
    loading: boolean;
  };
  savingProcess: {
    loading: boolean;
    error: boolean;
  };
}

export const initialState: State = {
  handoverProtocols: {
    data: [],
    loading: false,
  },
  handoverProtocolDetails: {
    data: null,
    loading: false,
  },
  savingProcess: {
    loading: false,
    error: false,
  }
};

const handoverProtocolReducer = createReducer(
  initialState,

  on(DataActions.LoadHandoverProtocols, DataActions.LoadHandoverProtocolsByCustomer, (state) => ({
    ...state,
    handoverProtocols: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.HandoverProtocolsLoaded, (state, { payload }) => ({
    ...state,
    handoverProtocols: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.HandoverProtocolsLoadingFailed, (state) => ({
    ...state,
    handoverProtocols: {
      ...state.handoverProtocols,
      loading: false,
    },
  })),

  on(DataActions.LoadHandoverProtocolDetails, DataActions.LoadLocalHandoverProtocolDetails, (state) => ({
    ...state,
    handoverProtocolDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.HandoverProtocolDetailsLoaded, (state, { payload }) => ({
    ...state,
    handoverProtocolDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.HandoverProtocolDetailsLoadingFailed, (state) => ({
    ...state,
    handoverProtocolDetails: {
      ...state.handoverProtocolDetails,
      loading: false,
    },
  })),
  on(DataActions.ClearHandoverProtocolDetails, (state) => ({
    ...state,
    handoverProtocolDetails: {
      data: null,
      loading: false,
    },
  })),

  on(DataActions.SaveHandoverProtocol, (state) => ({
    ...state,
    savingProcess: {
      loading: true,
      error: false,
    },
  })),
  on(DataActions.SaveHandoverProtocolSucceded, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: false,
    },
  })),
  on(DataActions.SaveHandoverProtocolFailed, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: true,
    },
  })),
  on(DataActions.DeleteHandoverProtocolSuccess, (state, { hopId }) => ({
    ...state,
    handoverProtocols: {
      ...state.handoverProtocols,
      data: state.handoverProtocols.data.filter(hop => hop.id !== hopId),
    },
  })),

);

export const reducer = (state: State | undefined, action: Action): State => handoverProtocolReducer(state, action);

export const getHandoverProtocols = (state: State): HandoverProtocol[] => state.handoverProtocols.data;
export const getHandoverProtocolsLoading = (state: State): boolean => state.handoverProtocols.loading;
export const getHandoverProtocolDetails = (state: State): HandoverProtocol | null => state.handoverProtocolDetails.data;
export const getHandoverProtocolDetailsLoading = (state: State): boolean => state.handoverProtocolDetails.loading;
export const getSavingProcessLoading = (state: State): boolean => state.savingProcess.loading;
export const getSavingProcessError = (state: State): boolean => state.savingProcess.error;
