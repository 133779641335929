import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'business-private-customers',
  templateUrl: `private-customers.component.html`,
  styles: [
    `
      :host {
        @apply block space-y-4;
      }
    `,
  ],
})
export class PrivateCustomersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formArray!: FormArray;
  @Input() showPep!: boolean;

  step = 0;

  private _subscription!: Subscription;

  constructor(private readonly _businessCustomerFormService: BusinessCustomerFormService) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.formArray.length === 0) {
      this.formArray.push(this._businessCustomerFormService.createPrivateCustomer());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._subscribeToChanges(changes.formArray.currentValue);
  }

  getAbstractControlAsFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  addPrivateCustomer(): void {
    const newCustomerControl = this._businessCustomerFormService.createPrivateCustomer();
    this.formArray.push(newCustomerControl);
    this.step = this.formArray.length - 1;

    this._subscribeToChanges();
  }

  removeCustomer(index: number): void {
    this.formArray.removeAt(index);
    this.step = this.formArray.length - 1;

    this._subscribeToChanges();

    // if (this.formArray.length === 0) {
    //   this.addPrivateCustomer();
    // }
  }

  setStep(event: number): void {
    this.step = event;
  }

  private _subscribeToChanges(formArray = this.formArray): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    this._subscription = new Subscription();

    formArray.controls.forEach((control) => {
      this._subscription.add(control.valueChanges.pipe(take(1)).subscribe(() => {
        this._businessCustomerFormService.addCustomerValidators(control as FormGroup);
      }));
    });

    formArray.updateValueAndValidity();
  }
}
