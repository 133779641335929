import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'customer-panel',
  templateUrl: 'customer-panel.component.html',
  styles: [],
})
export class PrivateCustomerPanelComponent {
  @Input() formGroup!: FormGroup;
  @Input() showPep!: boolean;
  @Input() index = 0;
  @Input() expand = true;

  @Output() openedStep = new EventEmitter<number>();
  @Output() remove = new EventEmitter<number>();

  setCurrentStep(): void {
    this.openedStep.emit(this.index);
  }

  removeStep(): void {
    this.remove.emit(this.index);
  }
}
