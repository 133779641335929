import { createSelector } from '@ngrx/store';
import {
  AccountLinkingSetting, DocumentsSettings,
  EmailSettings, ExclusiveAgreementSettings,
  ObjectsListSettings,
  PreListingSettings,
  Settings,
  ViewingPassSettings,
} from './settings.reducers';

export const selectSettingsStore = (state: any): Settings => state.settings;

export const selectViewingPassSettingsStore = (
  state: any
): ViewingPassSettings => state.settings.viewingPass;

export const selectDocumentsSettingsStore = (state: any): DocumentsSettings => state.settings.documentsSettings;

export const selectPreListingSettingsStore = (state: any): PreListingSettings =>
  state.settings.preListing;

export const selectObjectsListSettingsStore = (state: any): ObjectsListSettings =>
  state.settings.objectsList;

export const selectExclusiveAgreementSettingsStore = (
  state: any
): ExclusiveAgreementSettings => state.settings.exclusiveAgreement;

export const selectEmailSettingsStore = (state: any): EmailSettings =>
  state.settings.email;

export const selectAccountLinkingSettingsStore = (
  state: any
): AccountLinkingSetting[] => state.settings.accountLinks;

export const selectIsEdiReal = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => {
    const ediReal = state.find((idp) => idp.identityProvider === 'edi-real');
    return !!ediReal;
  }
);

export const selectIsJustimmo = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => {
    const justimmo = state.find((idp) => idp.identityProvider === 'justimmo');
    return !!justimmo;
  }
);

export const selectIsOnOffice = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => {
    const onOffice = state.find((idp) => idp.identityProvider === 'onOffice');
    return !!onOffice;
  }
);

export const selectIsMicrosoft = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => {
    const microsoft = state.find((idp) => idp.identityProvider === 'microsoft');
    return !!microsoft;
  }

);

export const selectIsRemax = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => {
    const remax = state.find((idp) => idp.identityProvider === 'remax-idp');
    return !!remax;
  }
);

export const selectSettings = createSelector(
  selectSettingsStore,
  (state) => state
);

export const selectUserProfileSettings = createSelector(
  selectSettingsStore,
  (state) => state.profileSettings
);

export const selectViewingPassSettings = createSelector(
  selectViewingPassSettingsStore,
  (state) => state
);

export const selectDocumentsSettings = createSelector(
  selectDocumentsSettingsStore,
  (state) => state
);

export const selectEmailSettings = createSelector(
  selectEmailSettingsStore,
  (state) => state
);

export const selectAccountLinkingSettings = createSelector(
  selectAccountLinkingSettingsStore,
  (state) => state
);

export const selectExclusiveAgreementSettings = createSelector(
  selectExclusiveAgreementSettingsStore,
  (state) => state
);
