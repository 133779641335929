import { CustomerWithContact } from '@domains/customer';
import { createAction, props } from '@ngrx/store';
import { BuyingOffer, BuyingOfferSignatureCreation } from '../buying-offer.model';
import { AgentCompany } from "../../../common-models/agent-company";

export const LoadBuyingOffers = createAction('[BuyingOffer] Load Buying Offers', props<{ objectId: string }>());
export const LoadBuyingOffersByCustomer = createAction('[BuyingOffer] Load Buying Offers By Customer', props<{ customerId: string }>());
export const BuyingOffersLoaded = createAction('[BuyingOffer] Buying Offers Loaded', props<{ payload: BuyingOffer[] }>());
export const BuyingOffersLoadingFailed = createAction('[BuyingOffer] Buying Offers Loading Failed');

export const LoadBuyingOfferDetails = createAction('[BuyingOffer] Load Buying Offer Details', props<{
  objectId: string;
  buyingOfferId: string;
}>());
export const BuyingOfferDetailsLoaded = createAction('[BuyingOffer] Buying Offer Details Loaded', props<{ payload: BuyingOffer }>());
export const BuyingOfferDetailsLoadingFailed = createAction('[BuyingOffer] Buying Offer Details Loading Failed');

export const SaveBuyingOffer = createAction(
  '[BuyingOffer] Save Buying Offer',
  props<{ buyingOffer: BuyingOffer; isFinal?: boolean, ignoreStateCheck?: boolean }>(),
);
export const SaveBuyingOfferSuccess = createAction('[BuyingOffer] Save Buying Offer Success');
export const SaveBuyingOfferFailed = createAction('[BuyingOffer] Save Buying Offer Failed');

export const SendSellerOwnerPortalNotification = createAction('[BuyingOffer] Send Seller Owner Portal Notification', props<{
  objectId: string;
  buyingOfferId: string;
}>());

export const CreateAndUploadBuyingOfferDocumentFailed = createAction('[BuyingOffer] Failed to create and upload buying offer document');
export const CreateBuyingOfferSucceded = createAction('[BuyingOffer] Succeeded to create and save Buying Offer');

export const UpdateBuyingOfferCustomers = createAction(
  '[BuyingOffer] Update Buying Offer Customers',
  props<{
    sellers: CustomerWithContact[];
    buyers: CustomerWithContact[];
    buyingOfferId: string;
    objectId: string;
  }>()
);

export const OrderBuyingContract = createAction('[BuyingOffer] Order Buying Contract', props<{ buyingOfferId: string, objectId: string }>());

export const CopyOfferToProperty = createAction('[BuyingOffer] Copy Offer To Property', props<{ buyingOfferId: string, destinationPropertyId: string }>());

export const DeleteBuyingOffer = createAction('[BuyingOffer] Delete Buying Offer', props<{ buyingOfferId: string, objectId: string }>());
export const DeleteBuyingOfferSuccess = createAction('[BuyingOffer] Delete Buying Offer Succeeded', props<{ buyingOfferId: string }>());
export const DeleteBuyingOfferFailed = createAction('[BuyingOffer] Delete Buying Offer Failed', props<{ buyingOfferId: string }>());

export const UpdateBuyingOfferMasterTemplate = createAction('[BuyingOffer] Update Buying Offer Master Template', props<{ buyingOfferId: string, objectId: string }>());
export const ResetBuyingOffer = createAction('[BuyingOffer] Reset Buying Offer', props<{ objectId: string; buyingOfferId: string }>());

export const SignAndCompleteBuyingOffer = createAction(
  '[BuyingOffer] Sign And Complete Buying Offer',
  props<{ buyingOfferSignature: BuyingOfferSignatureCreation }>()
);
export const DraftSignBuyingOffer = createAction(
  '[BuyingOffer] Draft Sign Buying Offer',
  props<{
    buyingOfferSignature: BuyingOfferSignatureCreation,
    openEmailDialog: boolean,
  }>()
);

export const SendBuyingOfferEmail = createAction(
  '[BuyingOffer] Send Buying Offer Email',
  props<{
    buyingOfferId: string;
    emailData: any;
    agentCompany: AgentCompany;
  }>()
);

export const SendCustomerPortalLink = createAction(
  '[BuyingOffer] Send Customer Portal Link',
  props<{
    buyingOfferId: string;
    emailData: any;
  }>()
);

export const SendCustomerPortalNotification = createAction(
  '[BuyingOffer] Send Customer Portal Notification',
  props<{
    buyingOfferId: string;
    emailData: any;
  }>()
);

export const SendSellerCustomerPortalLink = createAction(
  '[BuyingOffer] Send Seller Customer Portal Link',
  props<{
    buyingOfferId: string;
    emailData: any;
  }>()
);
