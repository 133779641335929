import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenFrameComponent } from './screen-frame.component';
import { MatCardModule } from "@angular/material/card";

@NgModule({
  declarations: [ScreenFrameComponent],
  imports: [CommonModule, MatCardModule],
  exports: [ScreenFrameComponent],
})
export class ScreenFrameModule { }
