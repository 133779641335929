import {
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BalloonToolbar,
  Bold,
  Code,
  Essentials,
  Heading,
  ImageBlock,
  ImageInsertViaUrl,
  ImageToolbar,
  ImageStyle,
  ImageResize,
  Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  SelectAll,
  SourceEditing,
  Strikethrough,
  Underline,
  Undo,
  HtmlEmbed,
  Font,
  type EditorConfig,
} from 'ckeditor5';

// @ts-ignore
import translations from 'ckeditor5/translations/en.js';

export const CKEDITOR_CONFIG: EditorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      '|',
      'heading',
      '|',
      'fontSize',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'link',
      'insertImageViaUrl',
      'mediaEmbed',
      '|',
      'alignment',
      '|',
      'bulletedList',
      'numberedList',
      'htmlEmbed',
    ],
    shouldNotGroupWhenFull: true
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    Bold,
    Code,
    Essentials,
    Heading,
    ImageBlock,
    ImageInsertViaUrl,
    ImageToolbar,
    ImageStyle,
    ImageResize,
    Italic,
    Link,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    SelectAll,
    SourceEditing,
    Strikethrough,
    Underline,
    Undo,
    HtmlEmbed,
    Font,
  ],
  balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  image: {
    toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
    resizeOptions: [
      {
        name: 'imageResize:original',
        label: 'Original',
        value: null
      },
      {
        name: 'imageResize:50',
        label: '50%',
        value: '50'
      },
      {
        name: 'imageResize:75',
        label: '75%',
        value: '75'
      }
    ],
    styles: {
      options: ['alignLeft', 'alignCenter', 'alignRight', 'full']
    }
  },
  fontSize: {
    options: [9,11,13, 'default', 17, 19, 21]
  },
  language: 'de',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  placeholder: 'Email schreiben',
  translations: [translations],
  htmlEmbed: {
    showPreviews: true
  }
};
