import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'images-input',
  template: `
    <label
      class="block bg-primary text-white text-base text-center font-source font-bold rounded-lg px-3 py-2 w-48 cursor-pointer"
    >
      {{ text }}
      <input
        hidden
        class="opacity-50"
        type="file"
        [accept]="acceptedTypes"
        multiple
        (change)="onValueChange($event)"
      />
    </label>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class ImagesInputComponent {
  @Input() text!: string;
  @Input() supportPdf = false;
  @Output() imagesTaken = new EventEmitter<FileList>();

  get acceptedTypes(): string {
    return this.supportPdf ? 'image/*,application/pdf,.heif,.heic' : 'image/*,.heif,.heic';
  }

  onValueChange($event: any): void {
    this.imagesTaken.emit($event.srcElement.files);
  }
}
