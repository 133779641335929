<div class="flex-column justify-center items-center p-2">
  <div class="w-full relative">
    <common-heading
      label="Auswahl Ansprechperson"
      align="center"
      class="font-bold text-lg pb-4"
    >
    </common-heading>
    <button
      mat-dialog-close
      class="absolute top-0 right-0 flex justify-center items-center"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="w-full flex-1">
    <div class="flex-column scroll-y">
      <div
        *ngFor="let agent of agents"
        (click)="selectAgent(agent)"
        class="p-2 cursor-pointer rounded-lg"
        [ngClass]="{ selected: isSelectedAgent(agent) }"
      >
        <agent-preview-card [agent]="agent"></agent-preview-card>
      </div>
    </div>
  </div>
  <div class="w-full flex flex-column justify-end mt-4">
    <button
      (click)="confirm()"
      [disabled]="!selectedAgents.length"
      class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary"
      [ngClass]="{ '!bg-gray-400': !selectedAgents.length }"
    >
      Weiter
    </button>
  </div>
</div>
