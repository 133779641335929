import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StaticFeatureToggles } from 'src/environments/static-feature-toggles';

@Component({
  selector: 'documents-list-filter',
  template: `
    <div class="flex flex-wrap flex-row justify-between items-center">
      <div class="mt-1">
        <div *ngIf="showFilter" class="flex space-x-3 lg:space-x-5">
          <button
            class="py-2 px-4 rounded-xl"
            (click)="onFilterChanged('all')"
            [ngClass]="{ 'text-white bg-primary-dark': filter === 'all' }"
          >
            {{ 'general.all_filter' | translate }}
          </button>
          <button
            class="py-2 px-4 rounded-xl"
            (click)="onFilterChanged('pending')"
            [ngClass]="{ 'text-white bg-primary-dark': filter === 'pending' }"
          >
            {{ 'general.pending_filter' | translate }}
          </button>
          <button
            class="py-2 px-4 rounded-xl"
            (click)="onFilterChanged('completed')"
            [ngClass]="{ 'text-white bg-primary-dark': filter === 'completed' }"
          >
            {{ 'general.completed' | translate }}
          </button>
          <button
            *ngIf="showWorkflowView"
            class="py-2 px-4 rounded-xl"
            (click)="onViewModeChanged('workflow')"
            [ngClass]="{ 'text-white bg-primary-dark': viewMode === 'workflow' }"
          >
            Workflow Ansicht
          </button>
        </div>
      </div>
      <div class="flex mt-1">
        <button
          *ngIf="showDownloadDocuments && featureToggles.showDownloadDocuments"
          (click)="downloadDocuments()"
          type="button"
          class="flex justify-center items-center bg-primary text-white py-2 px-4 rounded-xl mr-2"
        >
          {{'general.download_all_documents' | translate}}
        </button>
        <button
          (click)="toggleSortChanged()"
          mat-icon-button
          type="button"
          class="flex justify-center items-center bg-white py-2 px-4 rounded-xl"
        >
          <span class="mr-1">{{'general.last_opened' | translate}}</span>
          <mat-icon>{{sort === 'asc' ? 'arrow_drop_up': 'arrow_drop_down'}}</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class DocumentsListFilterComponent {
  @Input() filter: FilterState = 'all';
  @Input() sort: SortState = 'desc';
  @Input() showFilter = true;
  @Input() showDownloadDocuments = false;
  @Input() showWorkflowView = false;
  viewMode = 'list';

  @Output() filterChanged = new EventEmitter<{ filter: FilterState; sort: SortState }>();
  @Output() changeViewMode = new EventEmitter<string>();
  @Output() downloadClicked = new EventEmitter<void>();

  get featureToggles(): StaticFeatureToggles {
    return environment.staticToggles;
  }

  onViewModeChanged(viewMode: string): void {
    this.viewMode = viewMode;
    this.filter = 'null';
    this.changeViewMode.emit(viewMode);
  }

  onFilterChanged(filter: FilterState): void {
    this.filter = filter;
    this.viewMode = 'list';
    this.changeViewMode.emit(this.viewMode);
    this.filterChanged.emit({ filter, sort: this.sort });
  }

  toggleSortChanged(): void {
    this.sort = this.sort === 'asc' ? 'desc' : 'asc';
    this.filterChanged.emit({ filter: this.filter, sort: this.sort });
  }

  downloadDocuments(): void {
    this.downloadClicked.emit();
  }
}

export type FilterState = 'all' | 'pending' | 'completed' | 'null';
export type SortState = 'asc' | 'desc';
