import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeadingsModule } from '../headings/headings.module';
import { AdditionalContentDirective } from './additionalContent.directive';
import { DocumentReviewScreenComponent } from './document-review-screen/document-review-screen.component';
import { SignatureInputComponent } from './signature-input/signature-input.component';
import { SignatureScreenComponent } from './signature-screen/signature-screen.component';
import { PdfViewerModule } from '../pdf-viewer/pdf-viewer.module';
import { SlideToggleModule } from '../slide-toggle/slide-toggle.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddCustomersScreenComponent } from './add-customers-screen/add-customers-screen.component';
import { CustomerTypeSelectorModule } from "src/app/common-components/customer-type-selector/customer-type-selector.module";
import { BusinessCustomerPageModule } from 'src/app/customers/business/customer-page/customer-page.module';
import { SignatureNewCustomerPageComponent } from 'src/app/common-components/signature/add-customers-screen/customers/customers.component';
import { SignatureCustomerPanelComponent } from 'src/app/common-components/signature/add-customers-screen/customers/panel/panel.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomerPageModule } from 'src/app/customers/private/customer-page/customer-page.module';
import { ReactiveFormsModule } from '@angular/forms';
import {HelperModule} from "../../helper/helper.module";
import {ButtonModule} from "../button/button.module";
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";
import {SignatureFullscreenComponent} from "./signature-full-screen/signature-full-screen.component";

@NgModule({
  declarations: [
    SignatureInputComponent,
    SignatureScreenComponent,
    AddCustomersScreenComponent,
    DocumentReviewScreenComponent,
    AdditionalContentDirective,
    SignatureNewCustomerPageComponent,
    SignatureCustomerPanelComponent,
    SignatureFullscreenComponent
  ],
    imports: [
        CommonModule,
        MatIconModule,
        HeadingsModule,
        PdfViewerModule,
        SlideToggleModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        CustomerTypeSelectorModule,
        CustomerPageModule,
        BusinessCustomerPageModule,
        HelperModule,
        ButtonModule,
        TranslationComponentModule,
    ],
  exports: [
    SignatureScreenComponent,
    DocumentReviewScreenComponent,
    AdditionalContentDirective,
    AddCustomersScreenComponent,
    SignatureNewCustomerPageComponent,
    SignatureCustomerPanelComponent,
  ],
})
export class SignatureModule { }

export * from './signature-screen/signature-screen.component';
