import { Address } from 'src/app/common-models/address';
import { concat } from '../../helper/formatter';

export function getAddressAsString(address?: Address): string {

  if (!address) {
    return '';
  }

  let buildingNumberPart = concat(' ', address?.buildingNumber ?? '');
  let stairwayPart = concat('/', address?.stairway ?? '');
  let doorNumberPart = concat('/', address?.doorNumber ?? '');

  if (!address?.buildingNumber) {
    buildingNumberPart = '';
  }

  if (!address?.stairway) {
    stairwayPart = '';
  }

  if (!address?.doorNumber) {
    doorNumberPart = '';
  }

  const streetPart = concat(address?.streetName ?? '', buildingNumberPart);
  const AddressString = (streetPart + stairwayPart + doorNumberPart).trim();

  const zipCodePart = concat(address?.zipCode, ' ');
  const cityPart = (zipCodePart ?? '') + (address?.city ?? '');

  let comma = ', ';

  if (!address?.zipCode && !address?.city) {
    comma = '';
  }

  if (!address?.streetName || !AddressString) {
    comma = '';
  }

  return AddressString + comma + cityPart;
}
