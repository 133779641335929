import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SelectAgentFormComponent } from "../select-agent-form/select-agent-form.component";

@Component({
  selector: 'select-agent-stepper',
  template: `
    <form [formGroup]="formGroup" >
      <section class="flex flex-col space-y-4">
        <select-agent-form [agentFormGroup]="formGroup"></select-agent-form>
      </section>
    </form>
  `,
  imports: [
    ReactiveFormsModule,
    SelectAgentFormComponent,
  ],
  standalone: true
})

export class SelectAgentStepperComponent {
  @Input() formGroup!: FormGroup;
}
