import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessCustomerPageComponent } from './customer-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddressFieldAutocompleteModule } from 'src/app/common-components/address-field-autocomplete/address-field-autocomplete.module';
import { HeadingsModule } from 'src/app/common-components/headings/headings.module';
import { BusinessCustomerPreviewComponent } from './customer-preview/customer-preview.component';
import { HintModule } from 'src/app/common-components/hint/hint.module';
import { MarkedTextModule } from 'src/app/common-components/marked-text/marked-text.module';
import { AvatarModule } from 'src/app/common-components/avatar/avatar.module';
import { HintWithLinkModule } from 'src/app/common-components/hint-with-link/hint-with-link.module';
import { WizardBaseModule } from 'src/app/common-components/wizard-base/wizard-base.module';
import { SlideToggleModule } from 'src/app/common-components/slide-toggle/slide-toggle.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonModule } from 'src/app/common-components/button/button.module';
import { ImageManagementModule } from 'src/app/common-components/image-management/image-management.module';
import { CustomerPageModule } from 'src/app/customers/private/customer-page/customer-page.module';
import { PrivateCustomersComponent } from './private-customers/private-customers.component';
import { PrivateCustomerPanelComponent } from './private-customers/customer-panel/customer-panel.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {TranslationFeatureModule} from "../../../i18n/TranslationFeatureModule";

@NgModule({
  declarations: [
    BusinessCustomerPageComponent,
    BusinessCustomerPreviewComponent,
    PrivateCustomersComponent,
    PrivateCustomerPanelComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    HeadingsModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    AddressFieldAutocompleteModule,
    HintModule,
    MarkedTextModule,
    AvatarModule,
    HintWithLinkModule,
    WizardBaseModule,
    SlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    ButtonModule,
    ImageManagementModule,
    CustomerPageModule,
    TranslationFeatureModule,
  ],
    exports: [BusinessCustomerPageComponent, PrivateCustomersComponent],
})
export class BusinessCustomerPageModule { }
