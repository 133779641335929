import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AgentCompany } from 'src/app/common-models/agent-company';
import { ExclusiveAgreement, SignedAndCompletedExclusiveAgreement } from './exclusive-agreement.model';
import { ApolloQueryResult } from "@apollo/client";
import { SigneeSignatureResult } from "@domains/handover-protocol";
import { mapSignatureDataForGraphQL } from 'src/app/helper/mapSignatureDataForGraphQL';

@Injectable({ providedIn: 'root' })
export class ExclusiveAgreementApiFacade {
  constructor(private readonly apollo: Apollo) { }

  allowAccessFromOwnerPortal$(data: { id: string; documentUrl: string }): Observable<void> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation AllowAccessFromOwnerPortal($id: String!, $documentUrl: String!) {
            exclusiveAgreementOwnerPortalAccess(id: $id, documentUrl: $documentUrl)
          }
        `,
        variables: {
          id: data.id,
          documentUrl: data.documentUrl,
        },
      })
      .pipe(
        first(),
        map((result: any) => result.data.exclusiveAgreementOwnerPortalAccess)
      );
  }

  saveExclusiveAgreement$(data: any): Observable<ExclusiveAgreement> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation SaveExclusiveAgreement($exclusiveAgreement: ExclusiveAgreementInput) {
            exclusiveAgreement(exclusiveAgreement: $exclusiveAgreement) {
              id
              objectId
              state
              lastVisited
              generalData {
                contract
                propertyUserType
              }
              generalConditions {
                contractStart
                contractValidUntil
                validInMonths
                prolongationOption
                contractNotes
                isAwareOfCreditHistory
              }
              commission {
                commissionOption
                commissionBase
                commissionRentBase
              }
              energyPass {
                energyPassAvailable
                energyPassResponsible
                energyPassDelivery
              }
              propertyDetails {
                property {
                  use
                  propertyCreationReport
                  buildingPermit
                  buildingConsensus
                  constructionYear
                  constructionYearCa
                  cadastralCommunity
                  shares
                  entryNumber
                  parcelNumber
                  parcelNumbers
                  movingInDate
                  propertyType
                  propertyTypeNotes
                  shareInOwnership
                }
                rulesOfUse
                roomsAndArea {
                  livingArea {
                    area
                    areaCa
                  }
                  baseArea {
                    area
                    areaCa
                  }
                  usableArea {
                    area
                    areaCa
                  }
                }
                usageBoundCosts {
                  givenLater
                  costs
                  operatingCostsTax
                  electricity
                  gas
                  heating
                  telecommunication
                  water
                  other
                  otherDetails
                }
              }
              salesSpecifications {
                valuationPrice
                valuationPriceFrom
                sellingPrice
                hasVATLiabilityOption
                applicationPrice
                bottomUpPrice
                minPrice
                hasPriceOnRequest
                hasPriceToBeDiscussed
                includedInThePurchasePrice
                misc
                hasKnownHiddenDefects
                hiddenDefects
                hasEntriesInLandRegister
                entriesInLandRegister
              }
              rentalSpecifications {
                tenancyLaw
                tenancyLawApplication
                hasCommercialUseAllowance
                hasSubLeasingAllowance
                hasLeasingTerm
                contractValidUntil
                price {
                    rent
                    vat
                    additionalCosts
                }
                valorization {
                  hasValorization
                  vpi
                }
                rentalDeposit {
                  depositMethod
                  depositAmount
                }
                singlePayment {
                    singlePayment
                    singlePaymentInfo
                    singlePaymentAmount
                }
                misc
              }
              printSettings {
                hideTotalCommission
              }
              commissionBuy {
                commissionCalculationType
                commissionPercentage
                commissionTransfer
                commission
                vat
                additionalNotes
              }
              commissionRent {
                commissionCalculationType
                commissionMonths
                commissionTotal
                commissionPercentageSpecial
                commission
                vat
                additionalNotes
              }
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
              keys {
                received
                notes
                images {
                  id
                  storageUrl
                  type
                  description
                }
              }
              neededDocuments {
                landRegister
                landZoning
                developmentPlan
                contaminatedSitesPlan
                cadastralMap
                buildingPermit
                floorPlan
                permitOfUsePlan
                energyPass
                condominiumContract
                usefulValueAppraisal
                advancePayment
                annualStatement
                operatingCosts
                ownersMeetingProtocol
                supplyContracts
                disposalContracts
                insuranceContracts
                inventoryList
                rentalContract
                landRegisterCharges
                pictures
                otherDocs {
                  description
                  docStatus
                }
              }
              furtherConditions {
                hasOwnership
                hasExclusiveRight
                specialAgreement
                contractInOffice
                faggExplained
                faggAndCancelation
                startBeforeCancelation
                furtherConditions
              }
              otherConditions {
                otherConditions
              }
              expenseAllowance {
                expenseAllowance
                expenseType
                expenseAllowanceAmount
                expenseAllowancePercentage
                expenseAllowanceDescription
              }
              dataProcessing {
                generalAgreement
                newsletterAgreed
                questionaireAgreed
                propupAgreed
                headOrganisationAgreed
              }
              additionalEAServices {
                rentControlPackage
                fairRentService {
                  isSelected
                  level
                }
              }
              contractEntityType
              contractEntities {
                customerId
                ownership
                ownershipProof
              }
              businessContractEntity {
                customerId
                ownership
                ownershipProof
              }
              contractEntityTrusteeship {
                trusteeship
                trusteeshipOwner
              }
              warrantInfo {
                printWarrant
                landRegisterWarrant
                cadastreWarrant
                dossierWarrant
                propertyManagementWarrant
                arrangementWarrant
                decisionCollectionWarrant
                reserveWarrant
                annualStatementWarrant
                bankWarrant
                viewingWarrant
                warningWarrant
                notaryWarrant
                commissionWarrant
              }
              agent {
                id
                email
                isRemax
                themeConfig
                firstName
                lastName
                picture
                officeName
                officeAddress
                legalEntity
                phone
              }
              ownerPortal {
                isAvailableForOwner
                ownerSubmittedForReview
                isSigningAllowed
                isOwnerDataApplied
              }
            }
          }
        `,
        variables: {
          exclusiveAgreement: data,
        },
      })
      .pipe(
        first(),
        map((result: any) => ({
          ...result.data.exclusiveAgreement,
          lastVisited: new Date(result.data.exclusiveAgreement?.lastVisited)
        }))
      );
  }

  mergeDataFromOwnerPortal$(objectId: string, exclusiveAgreementId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation ExclusiveAgreementMergeOwnerPortalData($objectId: String!, $exclusiveAgreementId: String!) {
          exclusiveAgreementMergeOwnerPortalData(objectId: $objectId, exclusiveAgreementId: $exclusiveAgreementId)
        }
      `,
      variables: {
        objectId,
        exclusiveAgreementId
      },
    }).pipe(
      first(),
      map((result: any) => result.data.exclusiveAgreementMergeOwnerPortalData)
    );
  }

  loadExclusiveAgreement$(objectId: string): Observable<ExclusiveAgreement[]> {
    return this.apollo.query({
      query: gql`
        query GetExclusiveAgreement($objectId: String!) {
          exclusiveAgreements(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            contractEntityType
            contractEntities {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessContractEntity {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            creator {
              isCreatedByMe
              name
              email
            }
            ownerPortal {
              isAvailableForOwner
              ownerSubmittedForReview
              isSigningAllowed
              isOwnerDataApplied
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.exclusiveAgreements));

  }

  loadExclusiveAgreementsByCustomer$(customerId: string): Observable<ExclusiveAgreement[]> {
    return this.apollo.query({
      query: gql`
        query GetExclusiveAgreementsByCustomer($customerId: String!) {
          exclusiveAgreementsByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            contractEntityType
            contractEntities {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessContractEntity {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            ownerPortal {
              isAvailableForOwner
              ownerSubmittedForReview
              isSigningAllowed
              isOwnerDataApplied
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.exclusiveAgreementsByCustomer));
  }

  loadExclusiveAgreementDetails$(objectId: string, exclusiveAgreementId: string): Observable<ExclusiveAgreement> {
    return this.apollo.query({
      query: gql`
        query GetExclusiveAgreementById($objectId: String!, $exclusiveAgreementId: String!) {
          exclusiveAgreement(objectId: $objectId, exclusiveAgreementId: $exclusiveAgreementId) {
            id
            objectId
            state
            lastVisited
            generalData {
              contract
              propertyUserType
            }
            generalConditions {
              contractStart
              contractValidUntil
              validInMonths
              prolongationOption
              contractNotes
              isAwareOfCreditHistory
            }
            commission {
              commissionOption
              commissionBase
              commissionRentBase
            }
            propertyDetails {
              property {
                use
                propertyCreationReport
                buildingPermit
                buildingConsensus
                constructionYear
                constructionYearCa
                cadastralCommunity
                shares
                entryNumber
                parcelNumber
                parcelNumbers
                movingInDate
                propertyType
                propertyTypeNotes
                shareInOwnership
              }
              rulesOfUse
              roomsAndArea {
                livingArea {
                  area
                  areaCa
                }
                baseArea {
                  area
                  areaCa
                }
                usableArea {
                  area
                  areaCa
                }
              }
              usageBoundCosts {
                givenLater
                costs
                operatingCostsTax
                electricity
                gas
                heating
                telecommunication
                water
                other
                otherDetails
              }
            }
            salesSpecifications {
              valuationPrice
              valuationPriceFrom
              sellingPrice
              hasVATLiabilityOption
              applicationPrice
              bottomUpPrice
              minPrice
              hasPriceOnRequest
              hasPriceToBeDiscussed
              includedInThePurchasePrice
              misc
              hasKnownHiddenDefects
              hiddenDefects
              hasEntriesInLandRegister
              entriesInLandRegister
            }
            rentalSpecifications {
              tenancyLaw
              tenancyLawApplication
              hasCommercialUseAllowance
              hasSubLeasingAllowance
              hasLeasingTerm
              contractValidUntil
              price {
                rent
                vat
                additionalCosts
              }
              valorization {
                hasValorization
                vpi
              }
              rentalDeposit {
                depositMethod
                depositAmount
              }
              singlePayment {
                singlePayment
                singlePaymentInfo
                singlePaymentAmount
              }
              misc
            }
            printSettings {
              hideTotalCommission
            }
            commissionBuy {
              commissionCalculationType
              commissionPercentage
              commissionTransfer
              commission
              vat
              additionalNotes
            }
            commissionRent {
              commissionCalculationType
              commissionTotal
              commissionMonths
              commissionPercentageSpecial
              commission
              vat
              additionalNotes
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            keys {
              received
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            neededDocuments {
              landRegister
              landZoning
              developmentPlan
              contaminatedSitesPlan
              cadastralMap
              buildingPermit
              floorPlan
              permitOfUsePlan
              energyPass
              condominiumContract
              usefulValueAppraisal
              advancePayment
              annualStatement
              operatingCosts
              ownersMeetingProtocol
              supplyContracts
              disposalContracts
              insuranceContracts
              inventoryList
              rentalContract
              landRegisterCharges
              pictures
              otherDocs {
                description
                docStatus
              }
            }
            energyPass {
              energyPassAvailable
              energyPassResponsible
              energyPassDelivery
            }
            furtherConditions {
              hasOwnership
              hasExclusiveRight
              specialAgreement
              contractInOffice
              faggExplained
              faggAndCancelation
              startBeforeCancelation
              furtherConditions
            }
            otherConditions {
              otherConditions
            }
            expenseAllowance {
              expenseAllowance
              expenseType
              expenseAllowanceAmount
              expenseAllowancePercentage
              expenseAllowanceDescription
            }
            dataProcessing {
              generalAgreement
              newsletterAgreed
              questionaireAgreed
              propupAgreed
              headOrganisationAgreed
            }
            additionalEAServices {
              rentControlPackage
              fairRentService {
                isSelected
                level
              }
            }
            contractEntityType
            contractEntities {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              ownership
              ownershipProof
            }
            businessContractEntity {
              customerId
              ownership
              ownershipProof
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  birthdate
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  moneyLaundering {
                    askForMoneyLaundering
                    residentPerson
                    investmentIncome
                    estateSales
                    endowment
                    inheritance
                    savings
                    employment
                    investments
                    pension
                    loan
                    other
                    otherDetails
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
            }
            contractEntityTrusteeship {
              trusteeship
              trusteeshipOwner
            }
            signatureData {
              signedDocumentUrl
            }
            warrantInfo {
              printWarrant
              landRegisterWarrant
              cadastreWarrant
              dossierWarrant
              propertyManagementWarrant
              arrangementWarrant
              decisionCollectionWarrant
              reserveWarrant
              annualStatementWarrant
              bankWarrant
              viewingWarrant
              warningWarrant
              notaryWarrant
              commissionWarrant
            }
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
            }
            ownerPortal {
              isAvailableForOwner
              ownerSubmittedForReview
              isSigningAllowed
              isOwnerDataApplied
            }
            ownerPortalUserData {
              documentPreviewUrl
              documents {
                id
                storageUrl
                type
                title
                documentFrom
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        exclusiveAgreementId: exclusiveAgreementId,
      },
    }).pipe(first(), map((result: any) => result.data.exclusiveAgreement));
  }

  deleteExclusiveAgreement$(exclusiveAgreementId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteExclusiveAgreement($exclusiveAgreementId: String!) {
          deleteExclusiveAgreement(exclusiveAgreementId: $exclusiveAgreementId)
        }
      `,
      variables: {
        exclusiveAgreementId,
      },
    }).pipe(
      map((result: any) => result.data.deleteExclusiveAgreement)
    );
  }

  getLastExclusiveAgreementDetails$(objectId: string): Observable<ExclusiveAgreement> {
    return this.apollo.query({
      query: gql`
        query GetLastExclusiveAgreement($objectId: String!) {
          lastExclusiveAgreement(objectId: $objectId) {
            id
            objectId
            lastVisited
            generalData {
              contract
              propertyUserType
            }
            generalConditions {
              contractStart
              contractValidUntil
              validInMonths
              prolongationOption
              contractNotes
              isAwareOfCreditHistory
            }
            commission {
              commissionOption
              commissionBase
              commissionRentBase
            }
            energyPass {
              energyPassAvailable
              energyPassResponsible
              energyPassDelivery
            }
            furtherConditions {
              hasOwnership
              hasExclusiveRight
              specialAgreement
              contractInOffice
              faggExplained
              faggAndCancelation
              startBeforeCancelation
              furtherConditions
            }
            otherConditions {
              otherConditions
            }
            expenseAllowance {
              expenseAllowance
              expenseType
              expenseAllowanceAmount
              expenseAllowancePercentage
              expenseAllowanceDescription
            }
            keys {
              received
              notes
              images {
                id
                storageUrl
                type
                description
              }
            }
            dataProcessing {
              generalAgreement
              newsletterAgreed
              questionaireAgreed
              propupAgreed
              headOrganisationAgreed
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.lastExclusiveAgreement));
  }

  signAndCompleteExclusiveAgreement$(
    objectId: string,
    exclusiveAgreementId: string,
    contractEntitiesSignatureData: SigneeSignatureResult[] | null,
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedExclusiveAgreement> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SignAndCompleteExclusiveAgreement(
          $signatureInput: ExclusiveAgreementSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompleteExclusiveAgreement(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            lastVisited
            objectId
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          exclusiveAgreementId,
          contractEntitiesSignatureData: contractEntitiesSignatureData ?
            contractEntitiesSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const untypedExclusiveAgreement = result.data.signAndCompleteExclusiveAgreement;

          const exclusiveAgreement = untypedExclusiveAgreement as SignedAndCompletedExclusiveAgreement;
          exclusiveAgreement.lastVisited = new Date(exclusiveAgreement.lastVisited);

          return exclusiveAgreement;
        })
      );
  }
  getExclusiveAgreementOwnerPortalEmailVariables$(
    exclusiveAgreementId: string,
    customers: any[],
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetExclusiveAgreementOwnerPortalEmailVariables(
          $exclusiveAgreementId: String!
          $customers: [JSON]
        ) {
          exclusiveAgreementOwnerPortalEmailVariables(
            exclusiveAgreementId: $exclusiveAgreementId
            customers: $customers
          ) {
            propertyAddress
            customerPortalLink
            signature
            customerFullName
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        exclusiveAgreementId,
        customers
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.exclusiveAgreementOwnerPortalEmailVariables),
    );
  }

  getExclusiveAgreementEmailVariables$(
    exclusiveAgreementId: string,
    documentUrl: string,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetExclusiveAgreementEmailVariables(
          $exclusiveAgreementId: String!
          $documentUrl: String!
        ) {
          exclusiveAgreementEmailVariables(
            exclusiveAgreementId: $exclusiveAgreementId
            documentUrl: $documentUrl
          ) {
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        exclusiveAgreementId,
        documentUrl,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.exclusiveAgreementEmailVariables),
    );
  }

  createExclusiveAgreementDocument$(
    objectId: string,
    exclusiveAgreementId: string,
    agentCompany: AgentCompany,
  ): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CreateExclusiveAgreementDocument(
          $objectId: String!
          $exclusiveAgreementId: String!
          $agentCompany: AgentCompany!
        ) {
          exclusiveAgreementDocument(
            objectId: $objectId
            exclusiveAgreementId: $exclusiveAgreementId
            agentCompany: $agentCompany
          ) {
            url
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        exclusiveAgreementId: exclusiveAgreementId,
        agentCompany: agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.exclusiveAgreementDocument.url),
    );
  }

  sendExclusiveAgreementEmail$(
    exclusiveAgreementId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query TriggerSendExclusiveAgreementEmailQuery(
          $exclusiveAgreementId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          exclusiveAgreementSendEmail(
            exclusiveAgreementId: $exclusiveAgreementId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        exclusiveAgreementId,
        emailData,
        agentCompany
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.exclusiveAgreementSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  sendExclusiveAgreementOwnerPortalEmail$(
    exclusiveAgreementId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query TriggerSendExclusiveAgreementOwnerPortalEmailQuery(
          $exclusiveAgreementId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          exclusiveAgreementSendOwnerPortalEmail(
            exclusiveAgreementId: $exclusiveAgreementId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        exclusiveAgreementId,
        emailData,
        agentCompany
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.exclusiveAgreementSendOwnerPortalEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  compressFiles$(objectId: string, exclusiveAgreementId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CompressExclusiveAgreementFiles(
          $objectId: String!
          $exclusiveAgreementId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressExclusiveAgreementFiles(
            objectId: $objectId
            exclusiveAgreementId: $exclusiveAgreementId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        exclusiveAgreementId: exclusiveAgreementId,
        filePaths: filePaths,
        filenames: filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressExclusiveAgreementFiles),
    );
  }
}


