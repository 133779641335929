<div class="flex items-center">
  <div class="mr-4 mb-4">
    {{text}}
  </div>
  <mat-form-field appearance="outline">
    <mat-select [formControl]="control" required>
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{option.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
