import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { HeadingsModule } from '../headings/headings.module';
import { EmailConfigurationDialogComponent } from './email-configuration-dialog.component';
import { EmailFormComponent } from './email-form/email-form.component';
import { EmailRecipientsComponent } from './email-form/recipients.component';
import { EmailAttachmentComponent, EmailAttachmentsComponent } from './email-form/attachments.component';
import { SlideToggleModule } from 'src/app/common-components/slide-toggle/slide-toggle.module';
import { HelperModule } from 'src/app/helper/helper.module';

@NgModule({
  imports: [
    CommonModule,
    HeadingsModule,
    MatTabsModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatStepperModule,
    SlideToggleModule,
    MatButtonModule,
    HelperModule,
    MatProgressSpinnerModule,
    CKEditorModule,
  ],
  exports: [EmailConfigurationDialogComponent],
  declarations: [
    EmailConfigurationDialogComponent,
    EmailFormComponent,
    EmailRecipientsComponent,
    EmailAttachmentsComponent,
    EmailAttachmentComponent,
  ],
})
export class EmailConfigurationDialogModule { }

export * from './email-configuration-dialog.component';
export * from './models';