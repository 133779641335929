import { CustomerPortalEmailVariables } from "./customer-portal.model";
import {TranslationService} from "../../i18n/TranslationService";

const PROPERTY_ADDRESS = '{PROPERTY_ADDRESS}';
const DOCUMENT_URL = '{DOCUMENT_URL}';
const SIGNATURE = '{SIGNATURE}';
const CUSTOMER_PORTAL_LINK = '{CUSTOMER_PORTAL_LINK}';
const CUSTOMER_FULLNAME = '{CUSTOMER_FULLNAME}';
const DOCUMENT_STATUS = '{DOCUMENT_STATUS}';

const mergeVariables = (template: string, vars: CustomerPortalEmailVariables, translationService?: TranslationService) => {

  let translatedAcceptance = ''

  if(vars?.ownerAcceptance) {
    translatedAcceptance = translationService?.instant('owner_acceptance.' + vars.ownerAcceptance) || '';
  }

  return template
    .replaceAll(PROPERTY_ADDRESS, vars.propertyAddress)
    .replaceAll(SIGNATURE, vars.signature)
    .replaceAll(DOCUMENT_URL, vars.documentUrl)
    .replaceAll(CUSTOMER_PORTAL_LINK, vars.customerPortalLink)
    .replaceAll(CUSTOMER_FULLNAME, vars.customerFullName)
    .replaceAll(DOCUMENT_STATUS, translatedAcceptance);
}

export const buildTemplate = (title: string, body: string, vars: CustomerPortalEmailVariables) => {
  const mail = {
    subject: mergeVariables(title, vars),
    body: mergeVariables(body, vars),
  };

  return mail;
}

export const CustomerPortalEmail = {
  buildTemplate,
  mergeVariables,
};
