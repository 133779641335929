import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloseOptionsComponent } from './close-options/close-options.component';
import { HeadingsModule } from '../headings/headings.module';
import { MatStepperModule } from '@angular/material/stepper';
import { WizardPageHeadingComponent } from './wizard-page-heading.component';
import { WizardStepContentComponent } from './wizard-step-content/wizard-step-content.component';
import { WizardVerticalStepContentComponent } from './wizard-vertical-step-content/wizard-vertical-step-content.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslationFeatureModule} from "../../i18n/TranslationFeatureModule";

@NgModule({
  declarations: [
    CloseOptionsComponent,
    WizardPageHeadingComponent,
    WizardStepContentComponent,
    WizardVerticalStepContentComponent,
  ],
    imports: [
        CommonModule,
        HeadingsModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        TranslationFeatureModule,
    ],
  exports: [
    CloseOptionsComponent,
    WizardPageHeadingComponent,
    WizardStepContentComponent,
    WizardVerticalStepContentComponent,
  ],
})
export class WizardBaseModule {}
