import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'teamRoles'})
export class TeamRolesPipe implements PipeTransform {
  transform(value: any, ): any {

    return value.map((role: any) => {
      switch (role) {
        case "EDITOR":
          return "Bearbeiten"
        case "VIEWER":
          return "Ansehen"
        default:
          return "Team Mitglied"
      }
    }).join(", ");
  }
}
