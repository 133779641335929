import { Action, createReducer, on } from '@ngrx/store';
import { Address } from 'src/app/common-models/address';
import { CadastralCommunity, CommonPropertyExtensionConfig, Object, ObjectType, ObjectsCountByAgent, OnOfficeDocumentConfig, OrderType, PropertyActivity, PropertySharing } from '../object.model';
import * as DataActions from './actions';

export interface State {
  objectsByAgent: Record<string, {
    data: Object[],
    loading: boolean;
  }>;
  objectsLoading: boolean;
  objectDetails: {
    data: Object | null;
    hasError: boolean;
    loading: boolean;
    syncing: boolean;
  };
  propertySharing: {
    data: PropertySharing[];
    loading: boolean;
  };
  activities: {
    data: PropertyActivity[];
    loading: boolean;
  };
  availableAgents: ObjectsCountByAgent[];
  cadastralCommunities: CadastralCommunity[];
  duplicationCheck: {
    loading: boolean;
    hasUpdate: boolean;
  };
  commonPropertyExtensionConfig: {
    data: CommonPropertyExtensionConfig | null;
    loading: boolean;
  };
  onOfficeFileConfig: {
    documentAttributeOptions: any[];
    documentTypeOptions: any[];
    loading: boolean;
  };
}

export const initialState: State = {
  objectsByAgent: {},
  objectsLoading: false,
  objectDetails: {
    data: null,
    hasError: false,
    syncing: false,
    loading: false,
  },
  propertySharing: {
    data: [],
    loading: false,
  },
  activities: {
    data: [],
    loading: false,
  },
  availableAgents: [],
  cadastralCommunities: [],
  duplicationCheck: {
    loading: false,
    hasUpdate: false,
  },
  commonPropertyExtensionConfig: {
    data: null,
    loading: false,
  },
  onOfficeFileConfig: {
    documentAttributeOptions: [],
    documentTypeOptions: [],
    loading: false,
  },
};

const objectReducer = createReducer(
  initialState,

  on(DataActions.AvailableAgentNamesLoaded, (state, { payload }) => ({
    ...state,
    availableAgents: payload,
  })),
  on(DataActions.AvailableAgentNamesLoadingFailed, (state) => ({
    ...state,
    availableAgents: [],
  })),

  on(DataActions.InitObjects, (state) => ({
    ...state,
    objectsByAgent: {},
  })),

  on(DataActions.LoadObjectsByAgent, (state, { agentName }) => ({
    ...state,
    objectsByAgent: {
      ...state.objectsByAgent,
      [agentName]: {
        data: [],
        loading: true,
      },
    },
  })),
  on(DataActions.LoadObjectsByAgentFailed, (state, { agentName }) => ({
    ...state,
    objectsByAgent: {
      ...state.objectsByAgent,
      [agentName]: {
        data: [],
        loading: false,
      },
    },
  })),
  on(DataActions.LoadObjectsByAgentSucceeded, (state, { agentName, objects }) => ({
    ...state,
    objectsByAgent: {
      ...state.objectsByAgent,
      [agentName]: {
        data: objects,
        loading: false,
      },
    },
  })),

  on(DataActions.LoadPropertySharingData, (state) => ({
    ...state,
    propertySharing: {
      data: [],
      loading: true,
    },
  })),
  on(DataActions.PropertySharingDataLoaded, (state, { payload }) => ({
    ...state,
    propertySharing: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.PropertySharingDataLoadingFailed, (state) => ({
    ...state,
    propertySharing: {
      ...state.propertySharing,
      loading: false,
    },
  })),

  on(DataActions.LoadPropertyActivites, (state) => ({
    ...state,
    activities: {
      data: [],
      loading: true,
    },
  })),
  on(DataActions.PropertyActivitiesLoaded, (state, { payload }) => ({
    ...state,
    activities: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.PropertyActivitiesLoadingFailed, (state) => ({
    ...state,
    activities: {
      ...state.activities,
      loading: false,
    },
  })),

  on(DataActions.LoadObjectDetails, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: true,
      hasError: false,
    },
  })),
  on(DataActions.SyncObjectDetails, DataActions.LinkEdirealObject, DataActions.LinkOnOfficeProperty, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      syncing: true,
    },
  })),
  on(DataActions.ObjectDetailsLoaded, (state, { payload }) => ({
    ...state,
    objectDetails: {
      hasError: false,
      loading: false,
      syncing: false,
      data: payload,
    },
  })),
  on(DataActions.ObjectDetailsLoadingFailed, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      hasError: true,
      loading: false,
      syncing: false,
    },
  })),

  on(DataActions.LoadCadastralCommunities, DataActions.LoadCadastralCommunitiesFailed, (state) => ({
    ...state,
  })),
  on(DataActions.LoadCadastralCommunitiesSucceeded, (state, { payload }) => ({
    ...state,
    cadastralCommunities: payload,
  })),

  on(DataActions.SearchObjects, (state) => ({
    ...state,
    objectsByAgent: {},
    objectsLoading: true,
  })),
  on(DataActions.SearchObjectsFailed, (state) => ({
    ...state,
    objectsByAgent: {},
    objectsLoading: false,
  })),
  on(DataActions.SearchObjectsSucceeded, (state, { payload }) => {
    const result: Record<string, any> = {};

    Object.keys(payload).forEach((agentName) => {
      result[agentName] = {
        data: payload[agentName],
        loading: false,
      };
    });

    return {
      ...state,
      objectsByAgent: result,
      objectsLoading: false,
    };
  }),

  on(DataActions.CreateObject, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: true,
    },
  })),
  on(DataActions.SaveProperty, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: true,
    },
  })),
  on(DataActions.CreateObjectSucceded, (state, { payload }) => ({
    ...state,
    objectDetails: {
      loading: false,
      syncing: false,
      hasError: false,
      data: payload,
    },
  })),
  on(DataActions.CreateObjectFailed, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      syncing: false,
      loading: false,
    },
  })),

  on(DataActions.UpdateObject, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: true,
    },
  })),
  on(DataActions.UpdateObjectSucceded, (state, { payload }) => {
    return {
      ...state,
      objectDetails: {
        hasError: false,
        loading: false,
        syncing: false,
        data: payload,
      },
    };
  }),
  on(DataActions.UpdateObjectFailed, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      hasError: false,
      loading: false,
    },
  })),

  on(DataActions.UpdateObjectStatus, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: true,
    },
  })),
  on(DataActions.UpdateObjectStatusSucceded, (state, { payload }) => {
    return {
      ...state,
      objectDetails: {
        ...state.objectDetails,
        loading: false,
        data: {
          ...state.objectDetails.data!,
          objectStatus: payload.objectStatus,
        },
      },
    };
  }),
  on(DataActions.UpdateObjectStatusFailed, (state) => ({
    ...state,
    objectDetails: {
      ...state.objectDetails,
      loading: false,
    },
  })),

  on(DataActions.StartPropertiesDuplicationCheck, (state) => ({
    ...state,
    duplicationCheck: {
      loading: true,
      hasUpdate: false,
    },
  })),
  on(DataActions.FinishPropertiesDuplicationCheck, (state, { payload }) => ({
    ...state,
    duplicationCheck: {
      loading: false,
      hasUpdate: payload.hasUpdate,
    },
  })),
  on(DataActions.ResetPropertiesDuplicationCheck, (state) => ({
    ...state,
    duplicationCheck: {
      loading: false,
      hasUpdate: false,
    },
  })),

  on(DataActions.LoadCommonPropertyExtensionConfig, (state) => ({
    ...state,
    commonPropertyExtensionConfig: {
      ...state.commonPropertyExtensionConfig,
      loading: true,
    },
  })),
  on(DataActions.CommonPropertyExtensionConfigLoaded, (state, { payload }) => ({
    ...state,
    commonPropertyExtensionConfig: {
      data: payload,
      loading: false,
    },
  })),
  on(DataActions.CommonPropertyExtensionConfigLoadingFailed, (state) => ({
    ...state,
    commonPropertyExtensionConfig: {
      data: null,
      loading: false,
    },
  })),

  on(DataActions.LoadOnOfficeDocumentConfig, (state) => ({
    ...state,
    onOfficeFileConfig: {
      ...state.onOfficeFileConfig,
      loading: true,
    },
  })),
  on(DataActions.OnOfficeDocumentConfigLoaded, (state, { payload }) => ({
    ...state,
    onOfficeFileConfig: {
      documentAttributeOptions: payload.documentAttributeOptions,
      documentTypeOptions: payload.documentTypeOptions,
      loading: false,
    },
  })),
  on(DataActions.OnOfficeDocumentConfigLoadingFailed, (state) => ({
    ...state,
    onOfficeFileConfig: {
      documentAttributeOptions: [],
      documentTypeOptions: [],
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State =>
  objectReducer(state, action);

export const getCadastralCommunities = (state: State): CadastralCommunity[] => state.cadastralCommunities;
export const getAvailableAgents = (state: State): ObjectsCountByAgent[] => state.availableAgents;

export const getObjectsByAgent = (agentName: string) =>
  (state: State): Object[] => state.objectsByAgent[agentName]?.data ?? [];
export const getObjectsLoadingByAgent = (agentName: string) =>
  (state: State): boolean => state.objectsByAgent[agentName]?.loading ?? false;

export const getObjectsByAgents = (state: State): Record<
  string,
  { data: Object[]; loading: boolean }
> => state.objectsByAgent;
export const getObjectsLoading = (state: State): boolean => state.objectsLoading ?? false;

export const getObjectDetails = (state: State): Object | null =>
  state.objectDetails.data;
export const getObjectDetailsLoading = (state: State): boolean =>
  state.objectDetails.loading;
export const getObjectDetailsSyncing = (state: State): boolean =>
  state.objectDetails.syncing;
export const getPropertyDetailsError = (state: State): boolean =>
  state.objectDetails.hasError;

export const getPropertySharingData = (state: State): PropertySharing[] =>
  state.propertySharing.data;
export const getPropertySharingLoading = (state: State): boolean =>
  state.propertySharing.loading;
export const getPropertyActivitiesData = (state: State): PropertyActivity[] =>
  state.activities.data;
export const getPropertyActivitiesLoading = (state: State): boolean =>
  state.activities.loading;
export const getObjectOrderType = (
  state: State
): OrderType | null | undefined => state.objectDetails.data?.orderType;
export const getObjectType = (
  state: State
): ObjectType | null | undefined => state.objectDetails.data?.objectType;
export const getObjectId = (state: State): string | undefined =>
  state.objectDetails.data?.id;
export const getObjectAddress = (state: State): Address | undefined =>
  state.objectDetails.data?.address;

export const getDuplicationCheck = (state: State) =>
  state.duplicationCheck;

export const getPropertyExtensionConfigData = (state: State): CommonPropertyExtensionConfig | null =>
  state.commonPropertyExtensionConfig.data;
export const getPropertyExtensionConfigLoading = (state: State): boolean =>
  state.commonPropertyExtensionConfig.loading;

export const getOnOfficeDocumentTypeOptions = (state: State): OnOfficeDocumentConfig['documentTypeOptions'] =>
  state.onOfficeFileConfig.documentTypeOptions;
export const getOnOfficeDocumentAttributeOptions = (state: State): OnOfficeDocumentConfig['documentAttributeOptions'] =>
  state.onOfficeFileConfig.documentAttributeOptions;
export const getOnOfficeDocumentConfigLoading = (state: State): boolean =>
  state.onOfficeFileConfig.loading;