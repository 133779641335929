import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, toArray } from 'rxjs/operators';
import { CustomerLocalDbService } from 'src/app/domains/customer/customer-local-db.service';
import { LocalDatabase, LOCAL_DOCUMENT_ID_PREFIX } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class BusinessCustomerLocalDbService extends LocalDatabase {
  protected storeName = 'business-customers';

  constructor(private readonly _customerLocalDbService: CustomerLocalDbService) {
    super();
    this.initStorage();
  }

  protected generateLocalId(data: any): string {
    const email = data.contact?.email?.toLowerCase();
    const name = (data.companyNumber || '').trim().toLowerCase();

    let random = '';

    if (!email && !name) {
      random = Date.now().toString();
    }

    return `${LOCAL_DOCUMENT_ID_PREFIX}${email || name}${random}`;
  }

  get$(id: string): any {
    return this.getLocalBusinessCustomer$(id);
  }

  getLocalBusinessCustomer$(id: string): Observable<any | null> {
    return from(this._storage.getItem(id)).pipe(
      switchMap((data: any) => {
        if (!data) {
          return of(null);
        }

        if (!Array.isArray(data.customers) || data.customers.length === 0) {
          return of(data);
        }

        return from(data.customers).pipe(
          concatMap((customerId: any) =>
            this._customerLocalDbService.get$(customerId).pipe(catchError(() => of(null)))
          ),
          toArray(),
          map((privateCustomers: any[]) => {
            data.customers = privateCustomers.filter(Boolean);
            return data;
          }),
          catchError(() => {
            data.customers = [];
            return of(data);
          })
        );
      }),
      catchError(() => of(null))
    );
  }

}