import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import SignaturePad, { PointGroup } from 'signature_pad';

import { SignatureFullscreenComponent } from "../signature-full-screen/signature-full-screen.component";
import { getSignatureBoundingBox, scaleSignature } from "./utils";

@Component({
  selector: 'common-signature-input',
  templateUrl: './signature-input.component.html',
  styles: [`
    :host {
      display: block;
      position: relative;
    }

    canvas {
      width: 100%;
      height: 100%;
    }
  `],
})
export class SignatureInputComponent implements AfterViewInit {
  @ViewChild('signatureCanvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  @Input()
  set isEnabled(value: boolean) {
    this._isEnabled = value;
    this._activateSignpad(this._isEnabled);
  }
  get isEnabled(): boolean {
    return this._isEnabled;
  }

  @Output() signed: EventEmitter<void> = new EventEmitter();
  @Output() cleared: EventEmitter<void> = new EventEmitter();

  private _isEnabled = true;
  private _signPad!: SignaturePad;
  private _pixelRatio!: number;

  constructor(private readonly _dialog: MatDialog) { }

  @HostListener('window:resize', ['$event'])
  private onResize(): void {
    this.adjustToScreen();
  }

  ngAfterViewInit(): void {
    this._pixelRatio = Math.max(window.devicePixelRatio || 1, 1);

    this._signPad = new SignaturePad(this.canvasRef.nativeElement);
    this._setupSignatureEvents();
    this._activateSignpad(this._isEnabled);
    this.adjustToScreen();
  }

  clear(): void {
    this._signPad.clear();
    this.cleared.emit();
  }

  getSignatureAsSvg(): string {
    const svg = this._signPad.toSVG();
    return svg;
  }

  adjustToScreen(): void {
    const canvas = this.canvasRef.nativeElement;
    const signatureData = this._signPad.toData();

    canvas.width = canvas.offsetWidth * this._pixelRatio;
    canvas.height = canvas.offsetHeight * this._pixelRatio;
    canvas.getContext('2d')?.scale(this._pixelRatio, this._pixelRatio);

    this._signPad.fromData(signatureData);
  }

  openFullscreen(): void {
    const signatureData = this._signPad.toData(); // Get the vector representation of the signature

    const dialogRef = this._dialog.open(SignatureFullscreenComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'full-screen-dialog',
      data: { signatureData },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._loadSignatureFromData(result.signatureData);

        if (result.signed) {
          this.signed.emit();
        }
      } else {
        console.log('Fullscreen closed without any changes.');
      }
    });
  }

  private _loadSignatureFromData(data: PointGroup[]): void {
    if (data) {
      const canvas = this.canvasRef.nativeElement;

      const canvasWidth = canvas.width / this._pixelRatio;
      const canvasHeight = canvas.height / this._pixelRatio;

      const boundingBox = getSignatureBoundingBox(data);

      const dataWithoutOffset = scaleSignature(data, 1, -boundingBox.minX, -boundingBox.minY);
      const boundingBoxWithoutOffset = getSignatureBoundingBox(dataWithoutOffset);

      const scaleX = canvasWidth / boundingBoxWithoutOffset.width;
      const scaleY = canvasHeight / boundingBoxWithoutOffset.height;

      const initialScaleFactor = 0.9;
      const scaleFactor = Math.min(scaleX, scaleY) * initialScaleFactor;

      const scaledData = scaleSignature(dataWithoutOffset, scaleFactor, 0, 0);

      const scaledSignatureSize = getSignatureBoundingBox(scaledData);

      const offsetX = (canvasWidth - scaledSignatureSize.width) / 2;
      const offsetY = (canvasHeight - scaledSignatureSize.height) / 2;

      const centeredData = scaleSignature(scaledData, 1, offsetX, offsetY);

      this._signPad.fromData(centeredData);
    }
  }

  private _setupSignatureEvents(): void {
    this._signPad.addEventListener('endStroke', () => {
      if (!this._signPad.isEmpty()) {
        this.signed.emit();
      }
    });
  }

  private _activateSignpad(enabled: boolean): void {
    if (enabled) {
      this._signPad?.on();
    } else {
      this._signPad?.clear();
      this._signPad?.off();
    }
  }
}
