import {Component} from "@angular/core";
import { MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'delete-document-dialog',
  templateUrl: 'delete-document-dialog.component.html',
  styles: [``]
})
export class DeleteDocumentDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteDocumentDialogComponent>,
  ) {
    dialogRef.disableClose = false;
    dialogRef.addPanelClass('unset-default-padding'); // see global styles (MatDialog-styling is tricky
  }

  deleteClicked(): void {
    this.dialogRef.close('delete');
  }

  abortClicked(): void {
    this.dialogRef.close();
  }



}
