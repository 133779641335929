import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, from, Observable, of } from 'rxjs';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { BusinessCustomerApiFacade } from './business-customer-api.facade';
import { BusinessCustomerFormService } from './business-customer-form.service';
import { BusinessCustomer, BusinessCustomerWithCustomersDetails } from './business-customer.model';
import { BusinessCustomerActions } from './store/action-types';
import { BusinessCustomerFeatureState, businessCustomerSelectors } from './store';
import { BusinessCustomerLocalDbService } from 'src/app/domains/business-customer/business-customer-local-db.service';
import { CustomerDataFacade } from '@domains/customer';
import { catchError, concatMap, defaultIfEmpty, map, switchMap, toArray } from 'rxjs/operators';
import { LOCAL_DOCUMENT_ID_PREFIX } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class BusinessCustomerDataFacade {
  businessCustomers$ = this._store$.select(businessCustomerSelectors.customers);
  businessCustomersLoading$ = this._store$.select(businessCustomerSelectors.customersLoading);
  customerDetails$ = this._store$.select(businessCustomerSelectors.customerDetails);
  customerDetailsLoading$ = this._store$.select(businessCustomerSelectors.customerDetailsLoading);

  constructor(
    private readonly _store$: Store<BusinessCustomerFeatureState>,
    private readonly _businessCustomerApiFacade: BusinessCustomerApiFacade,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _businessCustomerLocalDbService: BusinessCustomerLocalDbService,
    private readonly _customerDataFacade: CustomerDataFacade,
  ) { }

  loadBusinessCustomers(): void {
    this._store$.dispatch(BusinessCustomerActions.LoadBusinessCustomers());
  }

  loadBusinessCustomerDetails(id: string): void {
    this._store$.dispatch(BusinessCustomerActions.LoadBusinessCustomerDetails({ payload: { id } }));
  }

  searchBusinessCustomer$(companyNumber: string): Observable<BusinessCustomer[]> {
    return this._businessCustomerApiFacade.searchBusinessCustomer$(companyNumber);
  }

  getCustomerById$(id: string): Observable<BusinessCustomerWithCustomersDetails> {
    return this._businessCustomerApiFacade.getCustomerById$(id);
  }

  deleteBusinessCustomer$(businessCustomerId: string): void {
    return this._store$.dispatch(BusinessCustomerActions.DeleteBusinessCustomer({ businessCustomerId }))
  }

  saveCustomer$(customerForm: FormGroup): Observable<BusinessCustomer | null> {
    return this._prepareAndValidateCustomer(
      customerForm,
      customerData => this._businessCustomerApiFacade.createBusinessCustomer$(customerData),
    );
  }

  saveBusinessCustomerOnline$(customers: string[], customerForm: FormGroup) {
    const ids = new FormArray(customers.map((item) => new FormControl(item)));
    customerForm.setControl('customers', ids);

    return this.saveCustomer$(customerForm);
  }

  saveBusinessCustomerOffline$(customers: string[], customerForm: FormGroup): Observable<BusinessCustomer | null> {
    const ids = new FormArray(customers.map((item) => new FormControl(item)));
    customerForm.setControl('customers', ids);

    return this._prepareAndValidateCustomer(
      customerForm,
      customerData => this._businessCustomerLocalDbService.save$(customerData),
    );
  }

  syncLocalBusinessCustomer$(id: string): Observable<string | null> {
    return this._businessCustomerLocalDbService.get$(id).pipe(
      defaultIfEmpty(null),
      switchMap((data: any) => {
        if (!data) {
          return of(null);
        }

        return from(data.customers).pipe(
          concatMap(({ id: customerId }: any) =>
            this._customerDataFacade.syncLocalCustomer$(customerId).pipe(
              defaultIfEmpty(null as any),
              catchError((error) => {
                console.error(`Failed to sync private customer ${customerId}:`, error);
                return of(null);
              })
            )
          ),
          toArray(),
          switchMap((newCustomerIds: string[]) => {
            data.customers = newCustomerIds.filter(Boolean) as string[];

            if (data?.id.startsWith(LOCAL_DOCUMENT_ID_PREFIX)) {
              data.id = '';
            }

            return this._businessCustomerApiFacade.createBusinessCustomer$(data).pipe(
              switchMap((customer) =>
                this._businessCustomerLocalDbService.delete$(id).pipe(
                  map(() => customer.id as string)
                )
              ),
              catchError((error) => {
                console.error('Failed to sync local business customer:', error);
                return of(null);
              })
            );
          }),
          catchError((error) => {
            console.error('Failed to sync private customers:', error);
            return of(null);
          })
        );
      }),
      catchError((error) => {
        console.error('Failed to retrieve business customer:', error);
        return of(null);
      })
    );
  }

  private _prepareAndValidateCustomer(
    customerForm: FormGroup,
    saveMethod: (data: any) => Observable<BusinessCustomer | null>
  ): Observable<BusinessCustomer | null> {
    if (!this._validateCustomerForm(customerForm)) {
      return of(null);
    }

    const customerData = this._businessCustomerFormService.prepareCustomerToPersist(customerForm.value);

    return saveMethod(customerData);
  }

  private _validateCustomerForm(form: FormGroup): boolean {
    form.updateValueAndValidity();
    form.markAllAsTouched();

    if (form.invalid) {
      console.warn('Business Customer Form Invalid!', form.value);
      return false;
    }

    return true;
  }
}
