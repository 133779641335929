<mat-card class="my-2 w-full" [formGroup]="formGroup">
  <mat-card-content>
    <div class="flex items-center">
      <div class="mr-4">
        <ng-container *ngIf="showUploadSpinner; else uploadedIcon">
          <mat-spinner *ngIf="uploadStatus === 'IN_PROGRESS'" diameter="20" color="primary"></mat-spinner>
          <mat-icon *ngIf="uploadStatus === 'PENDING'" class="text-gray-400">cloud_upload</mat-icon>
          <mat-icon *ngIf="!storageUrl" class="text-warn">error_outline</mat-icon>
        </ng-container>

        <!-- Show File Link If Available -->
        <ng-template #uploadedIcon>
          <a *ngIf="storageUrl; else uploadError" [href]="storageUrl" target="_blank">
            <mat-icon *ngIf="isPdf" class="text-gray-600">picture_as_pdf</mat-icon>
            <mat-icon *ngIf="!isPdf" class="text-gray-600">image</mat-icon>
          </a>
        </ng-template>

        <ng-template #uploadError>
          <span *ngIf="showUploadError" class="text-red-500 font-bold">Fehler beim Hochladen!</span>
        </ng-template>
      </div>

      <div class="flex-1">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Bildbeschreibung</mat-label>
          <input formControlName="description" matInput placeholder="Bildbeschreibung" />
        </mat-form-field>
      </div>
    </div>

    <mat-error *ngIf="showUploadError">
      Fehler beim Hochladen des Fotos. Bitte versuchen Sie es erneut.
    </mat-error>

    <mat-error *ngIf="showNoStorageUrlError">
      Etwas ist schief gelaufen. Entfernen Sie das Bild und versuchen Sie es erneut.
    </mat-error>

    <!-- Upload Progress Bar -->
    <mat-progress-bar
      *ngIf="uploadStatus === 'IN_PROGRESS'"
      mode="determinate"
      [value]="uploadProgress"
    ></mat-progress-bar>
  </mat-card-content>

  <mat-card-actions align="end">
    <button *ngIf="showUploadError" mat-button color="primary" (click)="retry.emit()">
      Erneut versuchen
    </button>
    <button mat-button color="warn" (click)="delete.emit()">Löschen</button>
  </mat-card-actions>
</mat-card>
