import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { SnackbarService } from 'services/snackbar.service';
import * as DataActions from './actions';
import { CustomerPortalApiFacade } from '../customer-portal-api.facade';
import {
  SaveGenericCustomerPortalSettingsFailed,
} from "./actions";

@Injectable()
export class CustomerPortalEffects {
  public loadCustomerPortals$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCustomerPortals),
      switchMap(({ objectId }) =>
        this._customerPortalApiFacade.loadCustomerPortals$(objectId).pipe(
          switchMap((value) => [DataActions.CustomerPortalsLoaded({ payload: value })]),
          catchError(() => [DataActions.CustomerPortalsLoadingFailed()])
        )
      )
    )
  );

  public loadCustomerPortalsByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCustomerPortalsByCustomer),
      switchMap(({ customerId }) =>
        this._customerPortalApiFacade.loadCustomerPortalsByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.CustomerPortalsLoaded({ payload: value })]),
          catchError(() => [DataActions.CustomerPortalsLoadingFailed()])
        )
      )
    )
  );

  public loadCustomerPortalFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCustomerPortalFeedback),
      switchMap(({ objectId }) =>
        this._customerPortalApiFacade.loadCustomerPortalFeedback$(objectId).pipe(
          switchMap((value) => [DataActions.CustomerPortalFeedbackLoaded({ payload: value })]),
          catchError(() => [DataActions.CustomerPortalFeedbackLoadingFailed()])
        )
      )
    )
  );

  public loadCustomerPortalDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCustomerPortalDetails),
      switchMap(({ objectId, id }) =>
        this._customerPortalApiFacade.loadCustomerPortalDetails$(objectId, id).pipe(
          switchMap((value) => [DataActions.CustomerPortalDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.CustomerPortalDetailsLoadingFailed()])
        )
      )
    )
  );

  public saveCustomerPortal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveCustomerPortal),
      switchMap(({ isFinal, data }) =>
        this._customerPortalApiFacade.saveCustomerPortal$(data).pipe(
          switchMap((pass) => {
            if (isFinal) {
              this._router.navigateByUrl(`/objects/${pass.objectId}/customer-portal`);

              return [DataActions.SaveCustomerPortalSucceded({ showToast: true })];
            }

            return [DataActions.SaveCustomerPortalSucceded({ showToast: false })];
          }),
          catchError(() => [DataActions.SaveCustomerPortalFailed({ showToast: isFinal ?? true })]),
        ))
    ),
  );

  public createCustomerPortalSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveCustomerPortalSucceded),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Interessentenportal erfolgreich erstellt',
            'mat-primary',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public createCustomerPortalFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveCustomerPortalFailed),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Fehler beim Erstellen des Interessentenportal',
            'mat-warn',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public sendCustomerPortalEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendCustomerPortalEmail),
      switchMap(({ objectId, id, emailData }) =>
        this._customerPortalApiFacade.sendCustomerPortalEmail$(objectId, id, emailData).pipe(
          tap((success) => {
            if (success) {
              this._snackbarService.showSnackbar(
                'Interessentenportal E-Mail erfolgreich versendet',
                'mat-primary',
                true
              );
            } else {
              throwError(new Error());
            }
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Interessentenportal E-Mail konnte nicht versendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  deleteCustomerPortal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteCustomerPortal),
      switchMap(({ id }) =>
        this._customerPortalApiFacade.deleteCustomerPortal$(id).pipe(
          switchMap((success) => {
            const resultAction = success ?
              [DataActions.DeleteCustomerPortalSucceded({ id })] :
              [DataActions.DeleteCustomerPortalFailed()];

            return resultAction;
          }),
        )
      ),
    )
  );

  postRemaxFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.PostRemaxFeedback),
      switchMap(({ id }) =>
        this._customerPortalApiFacade.postRemaxFeedback$(id).pipe(
          switchMap((success) => {

            console.log("Effect", success);

            if (success) {
              this._snackbarService.showSnackbar(
                'Feedback erfolgreich versendet',
                'mat-primary',
                true
              );
              return [DataActions.PostRemaxFeedbackSuceeded({ id })]
            } else {
              this._snackbarService.showSnackbar(
                'Feedback konnte nicht versendet werden',
                'mat-warn',
                true
              );
              return [DataActions.PostRemaxFeedbackFailed()];
            }
          }),
        )
      ),
    )
  );

  public loadGenericCustomerPortalSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadGenericCustomerPortalSettings),
      switchMap(() =>
        this._customerPortalApiFacade.loadGenericCustomerPortalSettings$().pipe(
          switchMap((value) => [DataActions.GenericCustomerPortalSettingsLoaded({ payload: value })]),
          catchError(() => [DataActions.GenericCustomerPortalSettingsLoadingFailed()])
        )
      )
    )
  );

  public saveGenericCustomerPortalSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveGenericCustomerPortalSettings),
      switchMap(({ data }) =>
        this._customerPortalApiFacade.saveGenericCustomerPortalSettings$(data).pipe(
          switchMap((data) => {
            if (data) {
              return [DataActions.SaveGenericCustomerPortalSettingsSucceeded({ showToast: true })];
            }
            return [SaveGenericCustomerPortalSettingsFailed({ showToast: false })];
          }),
          catchError(() => [SaveGenericCustomerPortalSettingsFailed({ showToast: true })])
        ))
      ),
    );

  public saveCustomerPortalSettingsSuccess$ = createEffect(() =>
      this._actions$.pipe(
        ofType(DataActions.SaveGenericCustomerPortalSettingsSucceeded),
        tap(({ showToast }) => {
          if (showToast) {
            this._snackbarService.showSnackbar(
              'Einstellungen erfolgreich gespeichert',
              'mat-primary',
              true
            );
          }
        })
      ),
    { dispatch: false }
  );

  public saveCustomerPortalSettingsFailed$ = createEffect(() =>
      this._actions$.pipe(
        ofType(DataActions.SaveGenericCustomerPortalSettingsFailed),
        tap(({ showToast }) => {
          if (showToast) {
            this._snackbarService.showSnackbar(
              'Einstellungen konnten nicht gespeichert werden´',
              'mat-warn',
              true
            );
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly _router: Router,
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _customerPortalApiFacade: CustomerPortalApiFacade,
  ) { }
}
