import { Component, Inject, ElementRef, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import SignaturePad, { PointGroup } from 'signature_pad';
import { getSignatureBoundingBox, scaleSignature } from 'src/app/common-components/signature/signature-input/utils';

@Component({
  selector: 'signature-fullscreen',
  template: `
    <div class="fullscreen-container">
      <div class="cancel-button text-center" (click)="close()">{{ 'general.continue' | translate }}</div>
      <canvas #fullscreenCanvas class="fullscreen-canvas"></canvas>
    </div>
  `,
  styles: [`
    .fullscreen-container {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: white;
    }
    .fullscreen-canvas {
      width: 100%;
      height: 100%;
    }
    .cancel-button {
      position: absolute;
      top: 10px;
      background-color: #5893ED;
      color: white;
      padding: 10px;
      cursor: pointer;
      z-index: 10;
      width: 100%;
    }
  `],
})
export class SignatureFullscreenComponent implements AfterViewInit {
  @ViewChild('fullscreenCanvas') canvasRef!: ElementRef<HTMLCanvasElement>;

  @Output() signed: EventEmitter<void> = new EventEmitter();

  private _signPad!: SignaturePad;
  private _pixelRatio!: number;

  constructor(
    public dialogRef: MatDialogRef<SignatureFullscreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { signatureData: PointGroup[] }
  ) { }

  ngAfterViewInit(): void {
    this._pixelRatio = Math.max(window.devicePixelRatio || 1, 1);
    this._signPad = new SignaturePad(this.canvasRef.nativeElement);

    this._adjustCanvas();
    this._loadSignatureFromData();
    this._setupSignatureEvents();
  }

  close(): void {
    if (this._signPad.isEmpty()) {
      this.dialogRef.close(null);
      return;
    }

    this.dialogRef.close({
      signatureData: this._signPad.toData(),
      signed: true,
    });
  }

  private _loadSignatureFromData(): void {
    const data = this.data.signatureData;

    if (!data) {
      return;
    }

    const fullScreenWidth = this.canvasRef.nativeElement.width / this._pixelRatio;
    const fullScreenHeight = this.canvasRef.nativeElement.height / this._pixelRatio;

    const boundingBox = getSignatureBoundingBox(data);

    const dataWithoutOffset = scaleSignature(data, 1, -boundingBox.minX, -boundingBox.minY);
    const boundingBoxWithoutOffset = getSignatureBoundingBox(dataWithoutOffset);

    const initialScaleFactor = 0.9;

    const scaleX = fullScreenWidth / boundingBoxWithoutOffset.width;
    const scaleY = fullScreenHeight / boundingBoxWithoutOffset.height;

    const scaleFactor = Math.min(scaleX, scaleY) * initialScaleFactor;

    const scaledData = scaleSignature(dataWithoutOffset, scaleFactor, 0, 0);
    const scaledSignatureSize = getSignatureBoundingBox(scaledData);

    const offsetX = (fullScreenWidth - scaledSignatureSize.width) / 2;
    const offsetY = (fullScreenHeight - scaledSignatureSize.height) / 2;

    const centeredData = scaleSignature(scaledData, 1, offsetX, offsetY);

    this._signPad.fromData(centeredData);
  }

  private _adjustCanvas(): void {
    const canvas = this.canvasRef.nativeElement;
    canvas.width = canvas.offsetWidth * this._pixelRatio;
    canvas.height = canvas.offsetHeight * this._pixelRatio;
    canvas.getContext('2d')?.scale(this._pixelRatio, this._pixelRatio);
  }

  private _setupSignatureEvents(): void {
    this._signPad.addEventListener('endStroke', () => {
      if (!this._signPad.isEmpty()) {
        this.signed.emit();
      }
    });
  }
}
