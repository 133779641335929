import { BusinessCustomerWithCustomersDetails } from '@domains/business-customer';
import { CustomerWithContact } from '@domains/customer';
import { SigneeSignatureResult } from '@domains/handover-protocol';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { DraftSignature } from 'src/app/common-components/signature/signature-screen/signature-screen.component';
import { Object, RealtyPrice } from '@domains/object';
import { DocumentCreator } from 'src/app/common-models/document-creator';

export interface RentalOffer {
  id?: string;
  objectId: string;
  state: string;
  lastVisited: string;
  propertyDescription: PropertyDescription;
  generalInformation: GeneralInformation;
  realtyPrice: RealtyPrice;
  monthlyBilling: MonthlyBilling;
  handoverAndCondition: HandoverAndCondition;
  depositDrafterFee: DepositDrafterFee;
  additionalDetails: AdditionalDetails;
  tenantType: CustomerType;
  tenants: Tenant[];
  businessTenant?: {
    customerId: string;
    isSponsor: boolean; //Bürgschaft
    customerData: BusinessCustomerWithCustomersDetails;
    creditworthinessCertificateIsConsideredForRent: boolean; // Mieter-Bonitätsauskunft fließt in die Vermiet-Enscheidung mit ein
    isAwareOfRentCertificates: boolean; // Mieter kennt Möglichkeit einer Mieter-Bonitätsauskunft
    selfDisclosureIsAttached: boolean; // Mieterselbstauskunft liegt bei
    selfDisclosureDate: Date; // Mieterselbstauskunftsdatum
    selfDisclosureType: TENANTSELFDISCLOSURE; // Mieterselbstauskunftstyp
    selfDisclosureTypeDescription: string; // Mieterselbstauskunftstyp=MISC description
    sharesCreditworthinessInformation: boolean; // Bonitätsauskunft wird geteilt
    sharesCreditworthinessInformationOfPreviousTenant: boolean; // Bonitätsauskunft vom Vorvermieter wird geteilt
    phoneNumberOfPreviousTenant: string; // Telefonnummer des Vorvermieters
    previousTenantNotes: string; // Notizen zum Vorvermieter
    sharesCreditworthinessInformationOfEmployer: boolean; // Bonitätsauskunft des Arbeitgebers wird geteilt
    phoneNumberOfEmployer: string; // Telefonnummer des Arbeitsgebers
    employerNotes: string; // Notizen zum Arbeitgeber
  };
  timeLimitation: TimeLimitation;
  landlordType: CustomerType;
  landlords: CustomerWithContact[];
  landlordAcceptance: LANDLORDACCEPTANCE;
  businessLandlord?: {
    customerId: string;
    customerData: BusinessCustomerWithCustomersDetails;
  };
  signatureData?: {
    tenants?: DraftSignature[];
    signedDocumentUrl?: string;
  };
  isMasterTemplate: boolean;
  isFromCustomerPortal: boolean;
  object?: Object;
  creator: DocumentCreator;
  isAvailableForOwner: boolean;
  customerPortal: {
    showLandlordDecision: boolean;
  };
}

export interface PropertyDescription {
  rooms: number;
  hasKitchen: boolean;
  hasBathroom: boolean;
  hasBalcony: boolean;
  hasLobby: boolean;
  hasCentralHeating: boolean;
  hasCellar: boolean;
  hasParkingSlot: boolean;
  misc: boolean;
  miscDescription: string;
}


export interface DepositDrafterFee {
  singePlayment: SinglePayment;
  rentalDeposit: RentalDeposit;
  drafterOfDeeds: DrafterOfDeeds;
  legalTransactionFee: LegalTransactionFee;
}

export interface TimeLimitation {
  date: string;
}

export interface GeneralInformation {
  propertyType: PROPERTYTYPE; // Eigentumsform
  propertyTypeNotes: String;
  shareInOwnership: String; // Anteil
  tenancyLaw: boolean; // Mietrechtsgesetz
  tenancyLawApplication: TENANCYLAWAPPLICATION;
  rentalStart: Date; // Mietverhältnisbeginn
  rentalDuration: RENTALDURATION; // Befristung
  rentDurationDetails: string;
  maximumRentalPeriod: number;
  unlimitedRentalPeriod: boolean;
  waiverOfCancellationPeriod: number;
  rentalEnd?: Date; // befristet bis
}

export interface MonthlyBilling {
  houseRent: number; // Hauptmietziens Netto
  tax: number; // Steuer
  serviceCharges: number; // Betriebskosten
  otherCosts: number; // Sonstige lfd. Kosten
  billingNotes: string; //Anmerkungen
  houseRentTotal: number; // Gesamtmietzins Brutto
  usageBoundCosts: UsageBoundCosts; // Verbrauchsabhängige Kosten
  vpi: string; // Verbraucherpreisindex
  valorization: VALORIZATION;
  vpiValueAdjustmentMonth: string;
  vpiValueAdjustmentChange: number;
}

export interface UsageBoundCosts {
  givenLater?: boolean;
  costs?: number;
  operatingCostsTax?: boolean; // hint: equals "including all taxes" @buying-offer-model
  electricity: boolean; // Strom
  gas: boolean; // Gas
  heating: boolean; // Heizung
  telecommunication: boolean; // Telekommunikation
  water: boolean; // Wasser
  other: boolean; // Sonstige
  otherDetails: string; // Info über Sonstige
}

export interface HandoverAndCondition {
  handoverDate: Date; // Übergabedatum
  handoverConditionDate: Date; // Übergabe-Zustand
  removeCondition: string; // zu entfernen
  addingCondition: string; // beizubringen, zu belassen
  secondViewingDate: Date;
}

export interface RentalDeposit {
  depositAmount: number; // Kaution
  depositMethod: DEPOSITMETHOD; // Kautionssicherstellung
  depositDescription: string;
}

export interface DrafterOfDeeds {
  contractPartnerId: string; // Vertragspartner
  hasDrafterOfDeeds: boolean; // Vertragsserrichter wird beauftragt
  name: string; // Rechtsanwalt/Notar
  address: string; // Adresse
  phone: string; // Telefonnummer
  email: string; // E-Mail
  costCoverage: COSTCOVERAGE; // Mieter oder Vermieter
  assignmentCoverage: ASSIGNMENTCOVERAGE; // Makler, Mieter oder Vermieter
  drafterOfDeedsCosts: string; // Kosten Vertragserstellung
}

export interface LegalTransactionFee {
  hasTransactionFee: boolean; // Rechtsgeschäftsgebühr fällig
  calculationType: string;
  tax: number; // Prozent
  feeBase: number; // Berechnungsbasis
  amount: number; // Gesamtbetrag
  fixedAmount: number;
  legalTransactionFeeNotes: string;
}

export interface AdditionalDetails {
  subleaseForbidden: boolean; // Untermietverbot
  usageRules: boolean; // Benützungsregeln
  gotUsageRules: boolean; // Benützungsregeln übergeben
  houseRules: boolean; // Hausordnung
  gotHouseRules: boolean; // Hausordnung übergeben
  additionalDetails: boolean; // Sonstige Vereinbarungen
  additionalDetailsInfo: string; // Sonstige Vereinbarungen Beschreibung
}

export interface SinglePayment {
  singlePayment?: boolean; // Einmalige Investitionsabslöe
  singlePaymentInfo: string; // Beschreibungstext
  singlePaymentAmount: number; // Betrag der Investitionsablöse
}

export interface Tenant extends CustomerWithContact {
  isSponsor: boolean; //Bürgschaft
  creditworthinessCertificateIsConsideredForRent: boolean; // Mieter-Bonitätsauskunft fließt in die Vermiet-Enscheidung mit ein
  isAwareOfRentCertificates: boolean; // Mieter kennt Möglichkeit einer Mieter-Bonitätsauskunft
  selfDisclosureIsAttached: boolean; // Mieterselbstauskunft liegt bei
  selfDisclosureDate: Date; // Mieterselbstauskunftsdatum
  selfDisclosureType: TENANTSELFDISCLOSURE; // Mieterselbstauskunftstyp
  selfDisclosureTypeDescription: string; // Mieterselbstauskunftstyp=MISC description
  sharesCreditworthinessInformation: boolean; // Bonitätsauskunft wird geteilt
  sharesCreditworthinessInformationOfPreviousTenant: boolean; // Bonitätsauskunft vom Vorvermieter wird geteilt
  phoneNumberOfPreviousTenant: string; // Telefonnummer des Vorvermieters
  previousTenantNotes: string; // Notizen zum Vorvermieter
  sharesCreditworthinessInformationOfEmployer: boolean; // Bonitätsauskunft des Arbeitgebers wird geteilt
  phoneNumberOfEmployer: string; // Telefonnummer des Arbeitsgebers
  employerNotes: string; // Notizen zum Arbeitgeber
}

export interface RentalOfferListItem {
  id: string;
  objectId: string;
  state: string;
  isCompleted: boolean;
  isSigning: boolean;
  lastVisited: Date;
  username: string;
  tenantsEmails: string[];
  landlordEmails: string[];

  tenantType: CustomerType;

  signatureData?: {
    signedDocumentUrl?: string;
  }

  isMasterTemplate: boolean;
  isFromCustomerPortal: boolean;
  object: Object;
  creator?: DocumentCreator;
  isAvailableForOwner: boolean;
  canNotifyAboutLandlordDecision: boolean;
}

export interface RentalOfferEmailConfigurationData {
  id: string;
  objectId: string;
  tenantsEmails: string[];
  landlordEmails: string[];
  variables: RentalOfferEmailVariables;
}

// todo: use enum or union type or simple values in FormGroup?
// todo: translate enums and add to dictionary.md
export enum PROPERTYTYPE {
  ALLEINEIGENTUM = 'ALLEINEIGENTUM',
  WOHNUNGSEIGENTUM = 'WOHNUNGSEIGENTUM',
  ANTEILSEIGENTUM = 'ANTEILSEIGENTUM',
}

export enum TENANTSELFDISCLOSURE {
  INCOMETAXRETURN = 'INCOMETAXRETURN',
  ANNUALPAYSLIP = 'ANNUALPAYSLIP',
  CURRENTPAYSLIP = 'CURRENTPAYSLIP',
  MISC = 'MISC',
}

export enum DEPOSITMETHOD {
  CASH = 'CASH',
  DEPOSIGUARANTEE = 'DEPOSIGUARANTEE',
  BANKGUARANTEE = 'BANKGUARANTEE',
  SAVINGACCOUNT = 'SAVINGACCOUNT',
  NOTDEFINED = 'NOTDEFINED',
  BANKTRANSFER = 'BANKTRANSFER'
}

export enum TENANCYLAWAPPLICATION {
  PARTIAL = 'Teilanwendung',
  FULL = 'Vollanwendung',
}

export enum RENTALDURATION {
  UNLIMITED = 'Unbefristet',
  LIMITED = 'Befristet',
}

export enum VALORIZATION {
  ANNUALLY = 'Jaehrlich',
  PERCENTAGE = 'Prozentual',
}

export enum COSTCOVERAGE {
  TENANT = 'Mieter',
  LANDLORD = 'Vermieter',
}

export enum ASSIGNMENTCOVERAGE {
  TENANT = 'Mieter',
  LANDLORD = 'Vermieter',
  AGENT = 'Makler',
}

export enum LANDLORDACCEPTANCE {
  ACCEPTED = 'accepted',
  NOTACCEPTED = 'notAccepted',
  NOTDEFINED = 'notDefined'
}

export interface RentalOfferSignatureCreation {
  objectId: string;
  rentalOfferId: string;
  tenantsSignatureData: SigneeSignatureResult[];
  landlordSignatureData: SigneeSignatureResult[] | null;
  landlordAcceptance: LANDLORDACCEPTANCE | null;
}

export type RentalOfferState = 'pending' | 'completed' | 'signing';

export interface SignedAndCompletedRentalOffer {
  id: string;
  objectId: string;
  state: RentalOfferState;
  lastVisited: Date;
  signatureData: {
    signedDocumentUrl: string;
  };
}

export interface RentalOfferEmailVariables {
  documentUrl: string;
  signature: string;
  propertyAddress: string;
}
