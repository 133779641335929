import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DashboardResolver } from './dashboard.resolver';
import { PostLoginComponent } from './post-login/post-login.component';
import { ConfigurableGuard } from './configurable.guard';
import { environment } from 'src/environments/environment';
import { FeatureToggleGuardService } from './services/featureToggle.guard.service';

const routes: Routes = [
  {
    path: 'post-login',
    component: PostLoginComponent,
  },
  {
    path: 'onoffice',
    loadChildren: () =>
      import('./onoffice/onoffice.module').then((m) => m.OnOfficeModule),
  },
  {
    path: 'justimmo',
    loadChildren: () =>
      import('./justimmo/justimmo.module').then((m) => m.JustimmoModule),
  },
  {
    path: 'dev-playground',
    loadChildren: () =>
      import('./_dev-playground/dev-playground.module').then(
        (m) => m.DevPlaygroundModule
      ),
    canActivate: [ConfigurableGuard],
    canLoad: [ConfigurableGuard],
    data: { enabled: environment.staticToggles.allowDevPlayground },
  },
  {
    path: 'dashboard',
    resolve: { data: DashboardResolver },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'objects',
    loadChildren: () =>
      import('./objects/objects.module').then((m) => m.ObjectsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./customers/customers.module').then((m) => m.CustomersModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./documents-overview/documents-overview.module').then((m) => m.DocumentsOverviewModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'marketplace',
    loadChildren: () =>
      import('./marketplace/marketplace.module').then(
        (m) => m.MarketplaceModule
      ),
    canActivate: [FeatureToggleGuardService, AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'help-news',
    loadChildren: () =>
      import('./help-news/help-news.module').then((m) => m.HelpNewsModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: 'office-area',
    loadChildren: () =>
      import('./office-area/office-area.module').then((m) => m.OfficeAreaModule),
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
