import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerPageComponent } from './customer-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddressFieldAutocompleteModule } from 'src/app/common-components/address-field-autocomplete/address-field-autocomplete.module';
import { HeadingsModule } from 'src/app/common-components/headings/headings.module';
import { CustomerPreviewComponent } from './customer-preview/customer-preview.component';
import { HintModule } from 'src/app/common-components/hint/hint.module';
import { MarkedTextModule } from 'src/app/common-components/marked-text/marked-text.module';
import { AvatarModule } from 'src/app/common-components/avatar/avatar.module';
import { HintWithLinkModule } from 'src/app/common-components/hint-with-link/hint-with-link.module';
import { WizardBaseModule } from 'src/app/common-components/wizard-base/wizard-base.module';
import { SlideToggleModule } from 'src/app/common-components/slide-toggle/slide-toggle.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from 'src/app/common-components/button/button.module';
import { ImageManagementModule } from 'src/app/common-components/image-management/image-management.module';
import {MatRadioModule} from "@angular/material/radio";
import {SharedModule} from "../../../shared/shared.module";
import {TranslationFeatureModule} from "../../../i18n/TranslationFeatureModule";
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [CustomerPageComponent, CustomerPreviewComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HeadingsModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        AddressFieldAutocompleteModule,
        HintModule,
        MarkedTextModule,
        AvatarModule,
        HintWithLinkModule,
        WizardBaseModule,
        SlideToggleModule,
        MatIconModule,
        MatTooltipModule,
        ButtonModule,
        ImageManagementModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        ReactiveFormsModule,
        SharedModule,
        TranslationFeatureModule,
        MatCheckboxModule,
    ],
  exports: [CustomerPageComponent],
})
export class CustomerPageModule { }
