import { Country } from '@domains/viewing-pass';
import { Action, createReducer, on } from '@ngrx/store';
import {
  globalStateHasBeenLoaded,
  initializeCountries,
  setEdiRealTokenValidity,
  setJustimmoTokenValidity,
  setRemaxTokenValidity,
  setMicrosoftTokenValidity, setOnOfficeTokenValidity,
} from './global.actions';

export interface GlobalState {
  isGlobalStateLoaded: boolean;
  isEdiRealTokenValid: boolean;
  isJustimmoTokenValid: boolean;
  isOnOfficeTokenValid: boolean;
  isRemaxTokenValid: boolean;
  isMicrosoftTokenValid: boolean;
  countries: Country[];
}

const initialState: GlobalState = {
  isGlobalStateLoaded: false,
  isEdiRealTokenValid: false,
  isJustimmoTokenValid: false,
  isOnOfficeTokenValid: false,
  isRemaxTokenValid: false,
  isMicrosoftTokenValid: false,
  countries: [],
};

const createReducerInternal = createReducer(
  initialState,
  on(initializeCountries, (state, payload) => ({
    ...state,
    countries: payload.countries,
  })),
  on(globalStateHasBeenLoaded, (state) => ({
    ...state,
    isGlobalStateLoaded: true,
  })),
  on(setEdiRealTokenValidity, (state, payload) => ({
    ...state,
    isEdiRealTokenValid: payload.isTokenValid,
  })),
  on(setJustimmoTokenValidity, (state, payload) => ({
    ...state,
    isJustimmoTokenValid: payload.isTokenValid,
  })),
  on(setOnOfficeTokenValidity, (state, payload) => ({
    ...state,
    isOnOfficeTokenValid: payload.isTokenValid,
  })),
  on(setRemaxTokenValidity, (state, payload) => ({
    ...state,
    isRemaxTokenValid: payload.isTokenValid,
  })),
  on(setMicrosoftTokenValidity, (state, payload) => ({
    ...state,
    isMicrosoftTokenValid: payload.isTokenValid,
  }))
);

export function globalReducer(
  state: GlobalState | undefined,
  action: Action
): GlobalState {
  return createReducerInternal(state, action);
}
