import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-wizard-page-heading',
  template: `
    <common-heading
      *ngIf="title"
      [label]="title"
      align="center"
      class="mb-12 lg:mb-16"
    ></common-heading>
  `,
  styles: [],
})
export class WizardPageHeadingComponent {
  @Input() title?: string;
}
