import { BuyingOfferEmailVariables } from "@domains/buying-offer";
import { CustomerPortalEmailVariables } from "@domains/customer-portal";
import { ExclusiveAgreementEmailVariables } from "@domains/exclusive-agreement";
import { HandoverProtocolEmailVariables } from "@domains/handover-protocol";
import { PlainAgreementEmailVariables } from "@domains/plain-agreement";
import { RentalOfferEmailVariables } from "@domains/rental-offer";
import { ViewingPassEmailVariables } from "@domains/viewing-pass";
import { OwnerPortalEmailVariables } from "src/app/domains/owner-portal";

export enum EmailConfigurationEntityType {
  ViewingPass = 'ViewingPass',
  RentalOffer = 'RentalOffer',
  BuyingOffer = 'BuyingOffer',
  HandoverProtocol = 'HandoverProtocol',
  ExclusiveAgreement = 'ExclusiveAgreement',
  CustomerPortal = 'CustomerPortal',
  CustomerPortalBulkMail = 'CustomerPortalBulkMail',
  CustomerPortalDocument = 'CustomerPortalDocument',
  CustomerPortalNotification = 'CustomerPortalNotification',
  OwnerPortal = 'OwnerPortal',
  OwnerPortalOffer = 'OwnerPortalOffer',
  PlainAgreement = 'PlainAgreement',
}

export interface EmailConfigurationDialogOutput {
  entityType: EmailConfigurationEntityType;
  emailData: {
    recipients: string[];
    recipientsCopy: string[];
    title: string;
    body: string;
    attachments: Array<{ storageUrl: string; description: string }>;
    includeImages?: boolean;
  };
}

export interface RentalOfferEmailConfigurationData {
  tenantsEmails: string[];
  landlordEmails: string[];
  variables: RentalOfferEmailVariables;
}

export interface BuyingOfferEmailConfigurationData {
  buyersEmails: string[];
  sellersEmails: string[];
  variables: BuyingOfferEmailVariables;
}

export interface ViewingPassEmailConfigurationData {
  id: string;
  objectId: string;
  customerEmails: string[];
  ccEmails?: string[];
  variables: ViewingPassEmailVariables;
}

export interface HandoverProtocolEmailConfigurationData {
  transferorEmails: string[];
  transfereeEmails: string[];
  ccEmails?: string[];
  variables: HandoverProtocolEmailVariables;
}

export interface ExclusiveAgreementEmailConfigurationData {
  contractEntitiesEmails: string[];
  variables: ExclusiveAgreementEmailVariables,
}

export interface PlainAgreementEmailConfigurationData {
  contractEntitiesEmails: string[];
  variables: PlainAgreementEmailVariables;
}

export interface CustomerPortalEmailConfigurationData {
  customerEmails: string[];
  variables: CustomerPortalEmailVariables;
}

export interface CustomerPortalBulkEmailConfigurationData {
  variables: CustomerPortalEmailVariables;
}

export interface CustomerPortalDocumentEmailConfigurationData {
  customerEmails: string[];
  variables: CustomerPortalEmailVariables;
}

export interface CustomerPortalNotificationEmailConfigurationData {
  customerEmails: string[];
  variables: CustomerPortalEmailVariables;
}

export interface OwnerPortalEmailConfigurationData {
  ownerEmails: string[];
  variables: OwnerPortalEmailVariables;
}

export interface OwnerPortalOfferEmailConfigurationData {
  ownerEmails: string[];
  variables: OwnerPortalEmailVariables;
}

export interface EmailConfigurationDialogData {
  entityType: EmailConfigurationEntityType;
  data: {
    viewingPass?: ViewingPassEmailConfigurationData;
    rentalOffer?: RentalOfferEmailConfigurationData;
    buyingOffer?: BuyingOfferEmailConfigurationData;
    handoverProtocol?: HandoverProtocolEmailConfigurationData;
    exclusiveAgreement?: ExclusiveAgreementEmailConfigurationData;
    plainAgreement?: PlainAgreementEmailConfigurationData;
    customerPortal?: CustomerPortalEmailConfigurationData;
    customerPortalBulkMail?: CustomerPortalBulkEmailConfigurationData;
    customerPortalDocument?: CustomerPortalDocumentEmailConfigurationData;
    customerPortalNotification?: CustomerPortalNotificationEmailConfigurationData;
    ownerPortal?: OwnerPortalEmailConfigurationData;
    ownerPortalOffer?: OwnerPortalOfferEmailConfigurationData;
  };
}
