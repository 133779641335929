import { Country } from '@domains/viewing-pass';
import { createAction, props } from '@ngrx/store';

export const initializeGlobalState = createAction('[Global] Initialize');

export const initializeCountries = createAction(
  '[Countries] Initialize',
  props<{ countries: Country[] }>(),
);

export const setEdiRealTokenValidity = createAction(
  '[EDI_REAL_TOKEN] Set token validity',
  props<{ isTokenValid: boolean }>(),
);

export const setJustimmoTokenValidity = createAction(
  '[JUSTIMMO_TOKEN] Set token validity',
  props<{ isTokenValid: boolean }>(),
);

export const setOnOfficeTokenValidity = createAction(
  '[ONOFFICE_TOKEN] Set token validity',
  props<{ isTokenValid: boolean }>(),
);

export const setRemaxTokenValidity = createAction(
  '[REMAX_TOKEN] Set token validity',
  props<{ isTokenValid: boolean }>(),
);

export const setMicrosoftTokenValidity = createAction(
  '[MICROSOFT_TOKEN] Set token validity',
  props<{ isTokenValid: boolean }>(),
);

export const globalStateHasBeenLoaded = createAction(
  '[Global] Has been loaded'
);
