import { BusinessCustomerWithCustomersDetails } from '@domains/business-customer';
import { CustomerWithContact } from '@domains/customer';
import { SigneeSignatureResult } from '@domains/handover-protocol';
import { PROPERTYTYPE } from '@domains/rental-offer';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { DraftSignature } from 'src/app/common-components/signature/signature-screen/signature-screen.component';
import { Object, RealtyPrice } from '@domains/object';
import { DocumentCreator } from 'src/app/common-models/document-creator';

export interface BuyingOfferListItem {
  id: string;
  objectId: string;
  state: string;
  isCompleted: boolean;
  isSigning: boolean;
  lastVisited: Date;

  username: string;
  buyersEmails: string[];
  sellersEmails: string[];

  signatureData?: {
    signedDocumentUrl?: string;
  };

  isMasterTemplate: boolean;
  isFromCustomerPortal: boolean;
  object?: Object;
  creator?: DocumentCreator;
  isAvailableForOwner: boolean;
  canNotifyAboutSellerDecision: boolean;
  canNotifyAboutBuyerDecision: boolean;
  hasPROPUPContractor: boolean;
}

export interface BuyingOffer {
  id?: string;
  objectId: string;
  state: string;
  workflowState: string;
  lastVisited: string;
  language: string;
  propertyDetails: PropertyDetails;
  realtyPrice: RealtyPrice;
  paymentDetails: PaymentDetails;
  drafterOfDeedsInfo: DrafterOfDeedsInfo;
  handoverAndConditionBuy: HandoverAndConditionBuy;
  additionalConditionsBuy: AdditionalConditionsBuy;
  buyerType: CustomerType;
  buyers: Buyer[];
  businessBuyer?: {
    customerId: string;
    customerData: BusinessCustomerWithCustomersDetails;
    shares: Shares[];
  };
  buyersTrusteeShip: BuyersTrusteeship;
  contractTerms: ContractTerms;
  printSettings: PrintSettings;
  sellerType: CustomerType;
  sellers: Seller[];
  businessSeller?: {
    customerId: string;
    customerData: BusinessCustomerWithCustomersDetails;
    shares: Shares[];
  };
  sellersAcceptance: SellersAcceptance;
  buyersAcceptanceWithChange: BuyersAcceptanceWithChange;
  signatureData?: {
    buyers?: DraftSignature[];
    sellers?: DraftSignature[];
    signedDocumentUrl?: string;
  };
  isMasterTemplate: boolean;
  isFromCustomerPortal: boolean;
  object?: Object;
  creator?: DocumentCreator;
  isAvailableForOwner: boolean;
  customerPortal: {
    showSellerDecision: boolean;
  };
  ownerCustomerPortal: {
    showBuyerDecision: boolean;
  };
  customerPortalId: string;
}

export interface BuyersAcceptanceWithChange {
  buyersAcceptanceWithChange: boolean;
}

export interface PropertyData {
  objectType: string;
  use?: string;
  address: string;
  areaOfLiving: string;
  areaOfLivingCa: boolean;
  areaOfLivingLoggia: boolean;
  areaOfBase: string;
  areaOfBaseCa: boolean;
  constructionYear: number;
  constructionYearCa: boolean;
  registrationLand?: string;  // hint: is not registered in exclusive agreement
  cadastralCommunity: string;
  entryNumber: string;
  parcelNumber: string;
  parcelNumbers: string[];
  movingInDate: string;
  propertyType: PROPERTYTYPE;
  propertyTypeNotes: string;
  shareInOwnership?: string;  // hint: is not registered in exclusive agreement
  cadastreLabel?: string;
  parkingSlotsLabel?: string[];
  complementLabel?: string[];
}

export interface PropertyDetails {
  property: PropertyData;
  housingSubsidies?: boolean; // hint: is not registered in exclusive agreement
  rulesOfUse: boolean;
  price?: number; // hint: is not registered in exclusive agreement
  includingAllTaxes: boolean;
  additionalPrices: AdditionalPrice[];
  totalCalculatedPrice: number;
}

export interface AdditionalPrice {
  price: number;
  name: string;
}

export interface PaymentDetails {
  repayment: boolean;
  repayments: RepaymentDetails[];
  repaimentObligation: number; // Rückzahlungsverpflichtung
  repaimentHolder: string; // Rückzahlungsverpflichtung bei
  repaimentAmount: number; // Rückzahlungsbetrag
  automatedCalculation: boolean;
  additionalConditions: boolean; // Sonstige Zahlungs-Vereinbarungen
  additionalConditionsDetails: string; // Sonstige Vereinbarungen
}

export interface RepaymentDetails {
  cadastreNumber: string; // C-LNR oder A2-LNR
  takeover: boolean; //Übernahme von Verbindlichkeiten
  obligationType: string; // Art der Belastung
  obligationTypeOther: string; // Sonstige Belastung
  pawnee: string; //Pfandgläubiger
  pawneeIBAN: string; //IBAN Pfandgläubiger
  repaimentObligation: number; // Rückzahlungsverpflichtung
  repaimentHolder: string; // Rückzahlungsverpflichtung bei
}

export interface DrafterOfDeedsInfo {
  contractorId: string; // Vertragspartner-ID
  contractPartnerId: string; // ContractPartner-ID
  autoSendContractPartnerEmail: boolean;
  name: string; // Rechtsanwalt/Notar
  address: string; // Adresse
  phone: string; // Telefonnummer
  email: string; // E-Mail
  assignment: boolean; // Beauftragung
  additionalCosts: boolean; // inkl. Nebenkosten
  additionalConditions: boolean; // Sonstige Kostenübernahme-Vereinbarungen
  additionalConditionsDetails: string; // Sonstige Vereinbarungen
  contractorCosts: CONTRACTPARTICIPANTS;
}

export interface HandoverAndConditionBuy {
  handoverOnSigning: Boolean; // Übergabe = Zeichnung
  handoverOnMoneyReceived: Boolean; // Übergabe = Geldeingang
  handoverOnOther: String; // Alternativer Übergabetermin
  handoverDate: Date; // Übergabetermin
  handoverConditionDate: Date; // Übergabe-Zustand
  handoverNotes: string;
  warranty: boolean; // Gewährleistung
  warrantyException: boolean; // Ausnahme Gewährleistung
  warrantyExceptionDetails: string; // Ausnahme Gewährleistung
  warrantyExclusion: boolean;
  needOfRedevelopment: boolean;
  removeCondition: boolean; // Entfernungsvereinbarung
  removeConditionDetails: string; // Entfernungs Optionen
  removeResponsibility: CONTRACTPARTICIPANTS; // Verantwortung über Entfernung
  costResponsibility: CONTRACTPARTICIPANTS; // Bezahlung Entfernung
  leaveCondition: boolean; // belassen / beigebracht
  leaveConditionDetails: string;
  promiseCondition: boolean; // Zugesichert
  promiseConditionDetails: string;
  warrantyClause: boolean;
  warrantyClauseDetails: string;
  warrantyClauseOption: string;
}

export interface AdditionalConditionsBuy {
  additionalConditionsDetails: string;
  expenseAllowanceAmount: number;
  commissionBase: number;
  urgentLivingNeed: boolean;
  useAsPrimaryResidence: boolean;
}

export interface Buyer extends CustomerWithContact {
  nationalInsuranceNumber: number; // Sozialversicherungsnummer
  askForBankData: boolean;
  bankData: {
    iban: string;
    bic: string;
  },
  shares: Shares[];
}

export interface Seller extends CustomerWithContact {
  nationalInsuranceNumber: number; // Sozialversicherungsnummer
  askForBankData: boolean;
  bankData: {
    iban: string;
    bic: string;
  },
  shares: Shares[];
}

export interface Shares {
  stake: string;
  bLnr: string;
}

export interface BuyersTrusteeship {
  trusteeship: boolean; // Treuhandschaft
  trusteeshipOwner: string; // Daten des Treuhänders
}

export interface ContractTerms {
  contractValidUntil: Date; // Gültigkeit Angebot
  alternativeValidity: string; // Alternative Gültigkei
  restriction: boolean; // Ohne Vorbehalt
  restrictionDetails: string; // Vorbehalte
  financeAgreement: boolean; // Finanzierung geklärt
  financeRestriction: boolean; // Finanzierungsvorbehalt
  financeRestrictionDays: number; //Anzahl Tage
}

export interface SellersAcceptance {
  sellerAcceptance: SELLERACCEPTANCE; // Annahme Verkäufer
  changeAcceptanceUntil: Date; // Einverständnis bis
  changeDetails: string; // Änderungsbedingungen
}

export interface BuyingOfferContractorOrderData {
  id: string;
  objectId: string;
}

export interface BuyingOfferEmailConfigurationData {
  id: string;
  objectId: string;
  buyersEmails: string[];
  sellersEmails: string[];
  variables: BuyingOfferEmailVariables;
}

export interface BuyingOfferSignatureCreation {
  objectId: string;
  buyingOfferId: string;
  buyersSignatureData: SigneeSignatureResult[];
  sellersSignatureData: (SigneeSignatureResult & { landlordAcceptance: string })[] | null;
  sellersAcceptance: BuyingOfferSellersAcceptance | null;
}

export interface PrintSettings {
  hideSellerOnPrint: Boolean;
}

export interface BuyingOfferSellersAcceptance {
  sellerAcceptance: SELLERACCEPTANCE;
  changeAcceptanceUntil: string;
  changeDetails: string;
}

export enum CONTRACTPARTICIPANTS {
  BUYER = 'BUYER',
  SELLER = 'SELLER'
}

export enum SELLERACCEPTANCE {
  DECISSIONPENDING = 'DECISSIONPENDING',
  ACCEPTANCE = 'ACCEPTANCE',
  NOTACCEPTED = 'NOTACCEPTED',
  ACCEPTANCEWITHCHANGE = 'ACCEPTANCEWITHCHANGE'
}

export interface SignedAndCompletedBuyingOffer {
  id: string;
  objectId: string;
  state: string;
  lastVisited: Date;
  signatureData: {
    signedDocumentUrl: string;
  };
}

export interface BuyingOfferEmailVariables {
  documentUrl: string;
  signature: string;
  propertyAddress: string;
}
