import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ClassicEditor } from 'ckeditor5';

import { CKEDITOR_CONFIG } from 'src/app/common-components/ckeditor';

@Component({
  selector: 'email-form',
  templateUrl: './email-form.component.html',
  styles: [`
    :host ::ng-deep .ck-editor__editable_inline {
      min-height: 200px;
    }
  `],
})
export class EmailFormComponent {
  @Input() emailFormGroup!: FormGroup;
  @Input() isBulkEmail = false;

  public isLayoutReady = false;
  public Editor = ClassicEditor;
  public ckEditorConfig = CKEDITOR_CONFIG;

  get recipientsControl(): FormControl {
      return this.emailFormGroup.get('recipients') as FormControl;
  }

  get attachmentsFormArray(): FormArray {
    return this.emailFormGroup.get('attachments') as FormArray;
  }

  get recipientsCopyControl(): FormControl {
    return this.emailFormGroup.get('recipientsCopy') as FormControl;
  }

  get includeImagesControl(): FormControl {
    return this.emailFormGroup.get('includeImages') as FormControl;
  }

  constructor() { }

  public ngAfterViewInit(): void {
    this.isLayoutReady = true;
  }
}
