import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'image-list',
  template: `
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <ul>
        <li *ngFor="let control of imagesControls" cdkDrag>
          <image-list-item
            [formGroup]="$any(control.group)"
            (delete)="removeImage.emit({ index: control.index })"
            (retry)="retryUpload.emit({ index: control.index })"
          ></image-list-item>
        </li>
      </ul>
    </div>
  `,
  styles: [],
})
export class ImageListComponent implements OnDestroy, OnChanges {
  @Input() imagesFormArray!: FormArray;
  @Input() documentTypes: Array<{ value: string; label: string }> = [];

  @Output() removeImage = new EventEmitter<{ index: number }>();
  @Output() retryUpload = new EventEmitter<{ index: number }>();

  imagesControls: any[] = [];
  private _sub = new Subscription();

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagesFormArray && changes.imagesFormArray.currentValue !== changes.imagesFormArray.previousValue) {
      this._sub.unsubscribe();

      // Ensure changes are reflected in UI
      this._sub = this.imagesFormArray.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        this.buildImageControls();
      });

      this.buildImageControls();
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  updateOrder() {
    this.imagesControls.forEach((control, index) => {
      control.group.get('order')?.setValue(index + 1);
    });
  }

  buildImageControls() {
    this.imagesControls = this.imagesFormArray.controls.map((control, i) => ({
      group: control,
      index: i,
    }));

    this.imagesControls.sort(
      (a, b) => (a.group.get('order')?.value || 0) - (b.group.get('order')?.value || 0)
    );

    this._cdr.markForCheck();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imagesControls, event.previousIndex, event.currentIndex);
    this.updateOrder();
    this._cdr.markForCheck();
  }
}
