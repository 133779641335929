<mat-card class="agent-card">
  <mat-card-header *ngIf="showTitle">
    <mat-card-title>{{ 'general.contact_person' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="my-2 mx-4 flex items-center">
      <div class="flex justify-center items-center">
        <img
          *ngIf="agent?.picture; else noAgentImage"
          class="agent-image mr-4"
          [src]="agent?.picture"
          alt="Maklerbild"
        />
        <ng-template #noAgentImage>
          <mat-icon
            class="mr-2 !w-10 !h-10 text-4xl text-gray-medium"
            fontIcon="account_circle"
          ></mat-icon>
        </ng-template>
      </div>
      <div>
        <p class="text-md font-semibold">
          {{ agent?.firstName }} {{ agent?.lastName }}
        </p>
        <div class="flex">
          <div *ngIf="agent?.legalEntity || agent?.officeName">
            <p class="text-sm text-gray" *ngIf="agent?.legalEntity">
              {{ agent!.legalEntity }}
            </p>
            <p class="text-sm text-gray" *ngIf="agent?.officeName">
              {{ agent!.officeName }}
            </p>
          </div>
          <div class="ml-1" *ngFor="let ci of agent?.companyInformation">
            <p class="text-sm text-gray" *ngIf="ci?.legalEntity">
              | {{ ci!.legalEntity }}
            </p>
            <p class="text-sm text-gray" *ngIf="ci?.officeName">
              | {{ ci!.officeName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
