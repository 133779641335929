import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeadingComponent } from './heading/heading.component';
import { SubHeadingComponent } from './sub-heading/sub-heading.component';
import {SettingsSubHeadingComponent} from "./settings-sub-heading/settings-sub-heading.component";

@NgModule({
  declarations: [HeadingComponent, SubHeadingComponent, SettingsSubHeadingComponent],
  imports: [CommonModule],
  exports: [HeadingComponent, SubHeadingComponent, SettingsSubHeadingComponent],
})
export class HeadingsModule {}
