import { Component, Input } from '@angular/core';
import { BusinessCustomer } from '@domains/business-customer';

@Component({
  selector: 'customer-preview',
  template: `
    <div>
      <common-avatar
        [namesForInitials]="[customer.companyNumber, '']"
        size="small"
      ></common-avatar>
    </div>

    <div class="ml-4">
      <p class="font-bold text-black text-base leading-none">
        <common-marked-text
          [text]="customer.companyNumber"
          [textToMark]="companyNumberSearchTerm"
        ></common-marked-text>
      </p>
      <p class="text-sm text-primary">{{ customer.companyRegister }}</p>
      <p class="text-sm text-primary">{{ customer.uidNumber }}</p>
      <p class="text-sm text-primary">{{ customer.contact.email }}</p>
      <p class="text-sm text-primary">{{ customer.contact.phone }}</p>
      <p class="text-sm text-primary">
        {{ customer.contact.address.zipCode }}
        {{ customer.contact.address.city }}
      </p>
    </div>
  `,
  styleUrls: ['customer-preview.component.scss'],
})
export class BusinessCustomerPreviewComponent {
  @Input() customer!: BusinessCustomer;

  @Input() companyNumberSearchTerm = '';
}
