import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import _ from 'lodash';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import {
  BusinessCustomer,
  BusinessCustomerDataFacade,
  BusinessCustomerWithCustomersDetails,
} from '@domains/business-customer';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';

@Component({
  selector: 'business-customer-page',
  templateUrl: 'customer-page.component.html',
  styleUrls: ['customer-page.component.scss'],
})
export class BusinessCustomerPageComponent implements OnChanges, OnDestroy {
  @Input() title = '';
  @Input() formGroup!: FormGroup;
  @Input() formArray!: FormArray;
  @Input() showPep = true;

  @ViewChild('identicalCustomersSelect')
  identicalCustomersSelect!: MatSelect;

  appearance: MatFormFieldAppearance = 'outline';
  maxDate: Date = moment().subtract(18, 'years').toDate();

  customers$!: Observable<BusinessCustomer[]>;
  customers: BusinessCustomer[] = [];

  private _subscription = new Subscription();

  private initialCurrentCustomerInfo: Pick<
    BusinessCustomer,
    'companyNumber' | 'companyRegister' | 'uidNumber' | 'contact' | 'customers'
  > = {
      companyNumber: '',
      companyRegister: '',
      uidNumber: '',
      contact: {
        address: {
          country: '',
          city: '',
          zipCode: '',
          streetName: '',
          buildingNumber: '',
          doorNumber: '',
          stairway: '',
        },
        email: '',
        phone: '',
      },
      customers: [],
    };
  private currentCustomerAsPersisted = this.initialCurrentCustomerInfo;

  constructor(
    private _businessCustomerDataFacade: BusinessCustomerDataFacade,
    private _customerFormService: CustomerFormService
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.customers$ = this.buildSearchStream(this.companyNumberControl.valueChanges).pipe(
      switchMap((companyNumber) => this._businessCustomerDataFacade.searchBusinessCustomer$(companyNumber)),
      map((customers) => customers.slice(0, 50)),
      tap((c) => (this.customers = c))
    );

    this.currentCustomerAsPersisted = {
      companyNumber: this.formGroup.controls.companyNumber.value ?? '',
      companyRegister: this.formGroup.controls.companyRegister.value ?? '',
      uidNumber: this.formGroup.controls.uidNumber.value ?? '',
      contact: this.formGroup.controls.contact.value,
      customers: this.formGroup.controls.customers.value,
    };
  }

  public buildSearchStream(companyNumber$: Observable<string>): Observable<string> {
    return companyNumber$.pipe(
      map((n) => ((n as string) ?? '').trim()),
      startWith(this.companyNumberControl.value),
      distinctUntilChanged(),
    );
  }

  private get companyNumberControl(): AbstractControl {
    return this.formGroup.controls.companyNumber;
  }

  get isEmailValid(): boolean | undefined {
    return this.formGroup.get('contact')?.get('email')?.invalid;
  }

  get sanitizedCompanyNumber(): string {
    return (this.companyNumberControl.value ?? '').trim();
  }

  onCustomerSelected(customer: BusinessCustomer): void {
    this.currentCustomerAsPersisted = customer;

    this._subscription.add(
      this._businessCustomerDataFacade.getCustomerById$(customer.id ?? '').pipe(
        first(),
        tap((c) => this.show(c))
      ).subscribe()
    );
  }

  onAddNewCustomer($event: MatOptionSelectionChange): void {
    if (!$event.source.selected) {
      return;
    }

    if (this.isPersistedCustomer) {
      this.clearForm();
      this.currentCustomerAsPersisted = this.initialCurrentCustomerInfo;
    } else {
      this.ensureCompanyNumberIsMaintained();
    }
  }

  /*
   INFO: MatAutocomplete is going to override the value of the field in which the onAddNewCustomer event was triggered with the value "".
   Therefore, we have restore the values of first/last name after the current event loop is done.
  */
  private ensureCompanyNumberIsMaintained(): void {
    const currentCompanyNumber = this.companyNumberControl.value;

    new Promise<void>((resolve) => resolve()).then(() => {
      this.companyNumberControl.patchValue(currentCompanyNumber);
    });
  }

  private show(customer: BusinessCustomerWithCustomersDetails): void {
    this.updateFormGroup(customer);
  }

  private clearForm(): void {
    this.updateFormGroup(undefined);
  }

  private updateFormGroup(customer?: BusinessCustomerWithCustomersDetails): void {
    this.formGroup.controls.id.setValue(customer?.id || '');
    this.formGroup.controls.companyNumber.setValue(customer?.companyNumber || '');
    this.formGroup.controls.companyRegister.setValue(customer?.companyRegister || '');
    this.formGroup.controls.uidNumber.setValue(customer?.uidNumber || '');

    const contactFormGroup = this.formGroup.get('contact') as FormGroup;
    contactFormGroup.controls.email.setValue(customer?.contact.email || '');
    contactFormGroup.controls.phone.setValue(customer?.contact.phone || '');

    const addressFormGroup = contactFormGroup.get('address') as FormGroup;
    addressFormGroup.controls.country.setValue(
      customer?.contact.address?.country || ''
    );
    addressFormGroup.controls.city.setValue(
      customer?.contact.address?.city || ''
    );
    addressFormGroup.controls.streetName.setValue(
      customer?.contact.address?.streetName || ''
    );
    addressFormGroup.controls.zipCode.setValue(
      customer?.contact.address?.zipCode || ''
    );
    addressFormGroup.controls.buildingNumber.setValue(
      customer?.contact.address?.buildingNumber || ''
    );
    addressFormGroup.controls.stairway.setValue(
      customer?.contact.address?.stairway || ''
    );
    addressFormGroup.controls.doorNumber.setValue(
      customer?.contact.address?.doorNumber || ''
    );

    const customersFormArray = this.formGroup.get('customers') as FormArray;

    this.formArray.clear();
    customersFormArray.clear();

    if (customer?.customers && customer?.customers.length) {
      customer.customers.forEach((customer: CustomerWithContact) => {
        customersFormArray.push(new FormControl(customer.id));
      });

      customer.customers.forEach((customer: CustomerWithContact) => {
        this.formArray.push(this._customerFormService.convertToFormGroup(customer));
      });

      customersFormArray.markAsPristine();
      customersFormArray.markAsUntouched();
    }

    contactFormGroup.markAsPristine();
    contactFormGroup.markAsUntouched();
    addressFormGroup.markAsPristine();
    addressFormGroup.markAsUntouched();

    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  private isNameIdenticalToControls(companyNumber: string): boolean {
    return this.companyNumberControl.value?.toLowerCase() === companyNumber.toLowerCase();
  }

  get showIdenticalCustomerWarning(): boolean {
    const isSameNameAsCurrentCustomer = this.isNameIdenticalToControls(
      this.currentCustomerAsPersisted.companyNumber
    );

    const nameExistsInCustomers = this.identicalCustomers.length > 0;

    const isFormDirty = this.formGroup && this.formGroup.dirty;

    return isFormDirty && !isSameNameAsCurrentCustomer && nameExistsInCustomers;
  }

  showIdenticalCustomers(): void {
    this.identicalCustomersSelect.open();
  }

  get identicalCustomers(): BusinessCustomer[] {
    return this.customers.filter((c) => this.isNameIdenticalToControls(c.companyNumber));
  }

  get showUpdateWarning(): boolean {
    return this.isPersistedCustomer && this.formIsDirty;
  }

  private get isPersistedCustomer(): boolean {
    return this.formGroup.controls.id.value;
  }

  private get formIsDirty(): boolean {
    const companyNumberIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.companyNumber.value !==
      this.currentCustomerAsPersisted.companyNumber
      : false;

    const companyRegisterIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.companyRegister.value !==
      this.currentCustomerAsPersisted.companyRegister
      : false;

    const uidNumberIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.uidNumber.value !==
      this.currentCustomerAsPersisted.uidNumber
      : false;

    const addressIsDirty = this.currentCustomerAsPersisted
      ? !_.isEqualWith(
        _.omit(this.formGroup.controls.contact.value.address, [
          'displayAddress',
          '__typename',
        ]),
        _.omit(this.currentCustomerAsPersisted.contact.address, [
          'displayAddress',
          '__typename',
        ]),
        (value, other) => value || '' === other || ''
      )
      : false;

    const otherFieldsAreDirty = this.formGroup.controls.contact.dirty

    return (
      this.formGroup.dirty &&
      (companyNumberIsDirty ||
        companyRegisterIsDirty ||
        uidNumberIsDirty ||
        addressIsDirty ||
        otherFieldsAreDirty)
    );
  }
}
