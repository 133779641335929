import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { OwnerPortal, CreateOwnerPortal } from './owner-portal.model';

@Injectable({ providedIn: 'root' })
export class OwnerPortalApiFacade {
  constructor(private readonly apollo: Apollo) { }

  saveOwnerPortal$(data: CreateOwnerPortal): Observable<OwnerPortal> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SaveOwnerPortal($data: CreateOwnerPortal!) {
          ownerPortal(data: $data) {
            id
            objectId
            creationDate
            lastVisited
          }
        }
      `,
      variables: {
        data,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.ownerPortal),
      map((cp: any) => ({
        ...cp,
        lastVisited: new Date(cp.lastVisited),
        creationDate: new Date(cp.creationDate),
      })),
    );
  }

  mergeOwnerDocuments$(id: string, urls: string[]): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation OwnerPortalMergeOwnerDocuments($id: String!, $urls: [String!]!) {
          ownerPortalMergeOwnerDocuments(id: $id, urls: $urls)
        }
      `,
      variables: {
        id,
        urls,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.ownerPortalMergeOwnerDocuments),
    );
  }

  loadOwnerPortalDetails$(id: string): Observable<OwnerPortal> {
    return this.apollo.query({
      query: gql`
        query getOwnerPortalDetails($id: String!) {
          ownerPortal(id: $id) {
            id
            objectId
            customerType
            customers {
              id
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessCustomer {
              id
              companyNumber
              companyRegister
              uidNumber
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                pep {
                  isPep
                  wasPepLastYear
                  isFamilyMemberPep
                  isKnownToBeCloseToPep
                  firstName
                  lastName
                }
                moneyLaundering {
                  askForMoneyLaundering
                  residentPerson
                  investmentIncome
                  estateSales
                  endowment
                  inheritance
                  savings
                  employment
                  investments
                  pension
                  loan
                  other
                  otherDetails
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            userDocuments {
              id
              storageUrl
              type
              title
              documentFrom
            }
            isNewDataAvailableForAgent
            generalSettings {
              showWelcomeCard
              welcomeCardTitle
              welcomeCardText
              mustHaveUploads {
                title
                description
                mandatory
              }
              hasDocumentAccess
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
            exclusiveAgreementSettings {
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
            }
            lastVisited
            creationDate
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { id },
    }).pipe(
      first(),
      map((result: any) => result.data.ownerPortal),
      map((cp: any) => ({
        ...cp,
        lastVisited: new Date(cp.lastVisited),
        creationDate: new Date(cp.creationDate),
      })),
    );
  }

  loadOwnerPortals$(objectId: string): Observable<OwnerPortal[]> {
    return this.apollo.query({
      query: gql`
        query GetOwnerPortals($objectId: String!) {
          ownerPortals(objectId: $objectId) {
            id
            objectId
            lastVisited
            creationDate
            customerType
            customers {
              id
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessCustomer {
              id
              companyNumber
              companyRegister
              uidNumber
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
            userDocuments {
              id
              storageUrl
              type
              title
              documentFrom
            }
            isNewDataAvailableForAgent
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId,
      },
    }).pipe(first(), map((result: any) => result.data.ownerPortals));
  }

  getOwnerPortalEmailVariables$(id: string, customers: any[]): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetOwnerPortalEmailVariables(
          $id: String!
          $customers: [JSON]
        ) {
          ownerPortalEmailVariables(
            id: $id
            customers: $customers
          ) {
            customerPortalLink
            propertyAddress
            signature
            customerFullName
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: { id, customers },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.ownerPortalEmailVariables),
    );
  }

  sendOwnerPortalEmail$(
    objectId: string,
    id: string,
    emailData: any,
  ): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query TriggerSendOwnerPortalEmailQuery(
          $objectId: String!
          $id: String!
          $emailData: EmailData!
        ) {
          ownerPortalSendMail(
            objectId: $objectId
            id: $id
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        id,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.ownerPortalSendMail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  deleteOwnerPortal$(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteOwnerPortal($id: String!) {
          deleteOwnerPortal(id: $id)
        }
      `,
      variables: {
        id,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.deleteOwnerPortal),
    );
  }
}
