export function formatNumberAsStringWithDots(
  x: number,
  decimalLength = 2
): string {
  const ts = '.';
  const ds = ',';

  const re = '\\d(?=(\\d{3})+' + (decimalLength > 0 ? '\\D' : '$') + ')';

  const num = (x ?? 0).toFixed(Math.max(0, ~~decimalLength));

  return (ds ? num.replace('.', ds) : num).replace(
    new RegExp(re, 'g'),
    '$&' + ts
  );
}

export function parseStringWithDotsToNumber(x: string): number {
  return parseFloat(x.replace(/\./g, '').replace(/\,/g, '.'));
}
