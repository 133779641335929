import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ValidateAddressDirective } from './validate-address.directive';
import { GoogleMapsAutocompleteComponent } from './google-maps-autocomplete.component';
import { PropupAgmModule } from 'src/app/utils/propup-agm/propup-agm.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    PropupAgmModule,
  ],
  exports: [
    GoogleMapsAutocompleteComponent,
    ValidateAddressDirective,
  ],
  declarations: [
    GoogleMapsAutocompleteComponent,
    ValidateAddressDirective,
  ],
})
export class GoogleMapsAutocompleteModule { }
