<section [formGroup]="formGroup">
  <mat-accordion>
    <mat-expansion-panel class="divide-y" (opened)="setCurrentStep()" [expanded]="expand">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-source">
          {{customerName}} {{ index + 1 }}
        </mat-panel-title>
        <mat-panel-description class="flex-1 justify-between">
          <div class="flex-1">
            <span *ngIf="formGroup.invalid" class="text-red-600">Bitte füllen Sie alle Pflichtfelder aus.</span>
          </div>
          <!-- [UI FIX] Empty icon to make the same offsets -->
          <mat-icon *ngIf="index === 0"></mat-icon>

          <mat-icon *ngIf="index !== 0" (click)="removeStep()">cancel</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="py-4">
        <customers-customer-page [formGroup]="formGroup" [isOptional]="true" [showPep]="true">
        </customers-customer-page>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</section>