<div [formGroup]="emailFormGroup">
  <p class="flex justify-center italic" *ngIf="isBulkEmail">Folgendes E-Mail wird an die ausgewählten Personen versendet.</p>
  <email-recipients *ngIf="!isBulkEmail" label="An" [control]="recipientsControl"></email-recipients>
  <email-recipients
    label="CC"
    [control]="recipientsCopyControl"
  ></email-recipients>
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Betreff</mat-label>
    <input formControlName="title" matInput />
  </mat-form-field>
  <div class="mb-4">
    <ckeditor
      *ngIf="isLayoutReady"
      [editor]="Editor"
      [config]="ckEditorConfig"
      formControlName="body"
    ></ckeditor>
  </div>
  <email-attachments [attachments]="attachmentsFormArray"></email-attachments>
  <!-- <common-slide-toggle
    class="mb-4"
    *ngIf="includeImagesControl"
    label="Fotos im Anhang mitsenden"
    [control]="includeImagesControl"
  ></common-slide-toggle> -->
</div>
