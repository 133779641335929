import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ButtonModule } from 'src/app/common-components/button/button.module';

import { DocumentsListFilterComponent } from './filter.component';
import {TranslationFeatureModule} from "../../i18n/TranslationFeatureModule";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        ButtonModule,
        TranslationFeatureModule
    ],
  exports: [DocumentsListFilterComponent],
  declarations: [DocumentsListFilterComponent],
})
export class DocumentsListFilterModule { }
