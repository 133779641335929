import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, from, iif, Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, switchMap } from 'rxjs/operators';
import { BusinessCustomerLocalDbService } from 'src/app/domains/business-customer/business-customer-local-db.service';
import { CustomerLocalDbService } from 'src/app/domains/customer/customer-local-db.service';
import { HandoverProtocol, LocalHandoverProtocol } from 'src/app/domains/handover-protocol/handover-protocol.model';

import { LOCAL_DOCUMENT_ID_PREFIX, LocalDatabase } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class LocalHandoverProtocolService extends LocalDatabase {
  protected storeName = 'handover-protocols';

  constructor(
    private readonly _customerLocalDbService: CustomerLocalDbService,
    private readonly _businessCustomerLocalDbService: BusinessCustomerLocalDbService,
  ) {
    super();
    this.initStorage();
  }

  protected generateLocalId(data: any): string {
    return `${LOCAL_DOCUMENT_ID_PREFIX}${Date.now()}`;
  }

  get$(id: string): Observable<LocalHandoverProtocol> {
    return from(this._storage.getItem(id)).pipe(
      switchMap((handoverProtocol: any) => {
        if (!handoverProtocol) {
          return of(null);
        }

        const transferorRequests$ = Array.isArray(handoverProtocol.transferors) && handoverProtocol.transferors.length > 0
          ? forkJoin(
            handoverProtocol.transferors.map((id: string) =>
              this._customerLocalDbService.get$(id).pipe(map(data => data || null))
            )
          ).pipe(defaultIfEmpty([] as any[]))
          : of([]);

        const transfereeRequests$ = Array.isArray(handoverProtocol.transferees) && handoverProtocol.transferees.length > 0
          ? forkJoin(
            handoverProtocol.transferees.map((id: string) =>
              this._customerLocalDbService.get$(id).pipe(map(data => data || null))
            )
          ).pipe(defaultIfEmpty([] as any[]))
          : of([]);

        const businessTransferorRequest$ = handoverProtocol.businessTransferor
          ? this._businessCustomerLocalDbService.get$(handoverProtocol.businessTransferor).pipe(
            map(data => data || null)
          )
          : of(null);

        const businessTransfereeRequest$ = handoverProtocol.businessTransferee
          ? this._businessCustomerLocalDbService.get$(handoverProtocol.businessTransferee).pipe(
            map(data => data || null)
          )
          : of(null);

        return combineLatest([
          transferorRequests$,
          transfereeRequests$,
          businessTransferorRequest$,
          businessTransfereeRequest$,
        ]).pipe(
          map(([transferorsData, transfereesData, businessTransferorData, businessTransfereeData]) => {
            return {
              ...handoverProtocol,
              transferorsData: transferorsData.filter(Boolean),
              transfereesData: transfereesData.filter(Boolean),
              businessTransferorData,
              businessTransfereeData,
            };
          })
        );
      })
    );
  }

  getHandoverProtocolsByPropertyId$(propertyId: string): Observable<LocalHandoverProtocol[]> {
    const ids: string[] = [];

    return from(
      this._storage.iterate((value: any, key: string) => {
        if (value.objectId === propertyId) {
          ids.push(key);
        }
      }).then(() => ids)
    ).pipe(
      switchMap((handoverProtocolIds) => {
        if (handoverProtocolIds.length === 0) {
          return of([]);
        }

        return forkJoin(handoverProtocolIds.map((id) => this.get$(id)));
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }

  saveFullModel$(hop: HandoverProtocol): Observable<void> {
    const {
      transfereesData = [],
      transferorsData = [],
      businessTransferorData,
      businessTransfereeData,
      // TODO: save also transferors signatures for local use
      signatureData,
      ...hopWithoutCustomers
    } = hop;

    return forkJoin([
      this.save$(hopWithoutCustomers),

      transfereesData.length > 0
        ? forkJoin(transfereesData.filter((c: any) => !!c.id).map((c: any) => this._customerLocalDbService.save$(c)))
        : of(null),
      transferorsData.length > 0
        ? forkJoin(transferorsData.filter((c: any) => !!c.id).map((c: any) => this._customerLocalDbService.save$(c)))
        : of(null),

      businessTransferorData?.id
        ? this.processBusinessCustomerData(businessTransferorData)
        : of(null),

      businessTransfereeData?.id
        ? this.processBusinessCustomerData(businessTransfereeData)
        : of(null),
    ]).pipe(map(() => undefined));
  }

  private processBusinessCustomerData(businessCustomerData: any): Observable<void> {
    const { customers = [], ...businessCustomerWithoutCustomers } = businessCustomerData;

    businessCustomerWithoutCustomers.customers = customers.map((c: any) => c.id).filter(Boolean);

    return forkJoin([
      this._businessCustomerLocalDbService.save$(businessCustomerWithoutCustomers),

      customers.length > 0
        ? forkJoin(customers.filter((c: any) => !!c.id).map((c: any) => this._customerLocalDbService.save$(c)))
        : of(null)
    ]).pipe(map(() => undefined));
  }
}
