import { Injectable } from '@angular/core';
import { forkJoin, from, iif, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BusinessCustomerLocalDbService } from 'src/app/domains/business-customer/business-customer-local-db.service';
import { CustomerLocalDbService } from 'src/app/domains/customer/customer-local-db.service';
import { LocalViewingPass, ViewingPass } from 'src/app/domains/viewing-pass/viewing-pass.model';

import { LOCAL_DOCUMENT_ID_PREFIX, LocalDatabase } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class LocalViewingPassService extends LocalDatabase {
  protected storeName = 'viewing-passes';

  constructor(
    private readonly _customerLocalDbService: CustomerLocalDbService,
    private readonly _businessCustomerLocalDbService: BusinessCustomerLocalDbService,
  ) {
    super();
    this.initStorage();
  }

  protected generateLocalId(data: any): string {
    return `${LOCAL_DOCUMENT_ID_PREFIX}${Date.now()}`;
  }

  get$(id: string): Observable<LocalViewingPass> {
    return from(this._storage.getItem(id)).pipe(
      switchMap((viewingPass: any) => {
        if (!viewingPass) {
          return of(null);
        }

        const customerRequests: Observable<any>[] = [];

        if (Array.isArray(viewingPass.customers) && viewingPass.customers.length > 0) {
          const privateCustomerRequests = viewingPass.customers.map((customerId: string) =>
            this._customerLocalDbService.get$(customerId).pipe(
              map((customerData) => customerData || null)
            )
          );
          customerRequests.push(...privateCustomerRequests);
        }

        let businessCustomerRequest: Observable<any> = of(null);
        if (viewingPass.businessCustomer) {
          businessCustomerRequest = this._businessCustomerLocalDbService.get$(viewingPass.businessCustomer).pipe(
            map((businessCustomerData) => businessCustomerData || null)
          );
          customerRequests.push(businessCustomerRequest);
        }

        return iif(
          () => customerRequests.length === 0,
          of({
            ...viewingPass,
            customersData: [],
            businessCustomerData: null,
          }),
          forkJoin(customerRequests).pipe(
            map((customerDataList) => {
              const privateCustomers = customerDataList.slice(
                0,
                viewingPass.customers?.length || 0
              );
              const businessCustomer =
                customerDataList[viewingPass.customers?.length || 0] || null;
              return {
                ...viewingPass,
                customersData: privateCustomers.filter(Boolean),
                businessCustomerData: businessCustomer,
              };
            })
          )
        );
      })
    );
  }

  getViewingPassesByPropertyId$(propertyId: string): Observable<LocalViewingPass[]> {
    const ids: string[] = [];

    return from(
      this._storage.iterate((value: any, key: string) => {
        if (value.objectId === propertyId) {
          ids.push(key);
        }
      }).then(() => ids)
    ).pipe(
      switchMap((viewingPassIds) => {
        if (viewingPassIds.length === 0) {
          return of([]);
        }

        return forkJoin(viewingPassIds.map((id) => this.get$(id)));
      }),
      catchError((error) => {
        return of([]);
      })
    );
  }

  saveFullModel$(vp: ViewingPass): Observable<void> {
    const {
      customersData = [],
      businessCustomerData,
      signatureData,
      ...vpWithoutCustomers
    } = vp;

    return forkJoin([
      this.save$(vpWithoutCustomers),

      customersData.length > 0
        ? forkJoin(customersData.filter((c: any) => !!c.id).map((c: any) => this._customerLocalDbService.save$(c)))
        : of(null),

      businessCustomerData?.id
        ? this.processBusinessCustomerData(businessCustomerData)
        : of(null)
    ]).pipe(map(() => undefined));
  }

  private processBusinessCustomerData(businessCustomerData: any): Observable<void> {
    const { customers = [], ...businessCustomerWithoutCustomers } = businessCustomerData;

    businessCustomerWithoutCustomers.customers = customers.map((c: any) => c.id).filter(Boolean);

    return forkJoin([
      this._businessCustomerLocalDbService.save$(businessCustomerWithoutCustomers),

      customers.length > 0
        ? forkJoin(customers.filter((c: any) => !!c.id).map((c: any) => this._customerLocalDbService.save$(c)))
        : of(null)
    ]).pipe(map(() => undefined));
  }
}
