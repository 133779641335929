import { formatNumberAsStringWithDots } from './formatNumberAsStringWithDots';

export function formatArea(area: number | undefined, areaUnit = 'm²'): string {
  return formatNumberAsStringWithDots(area || 0) + ' ' + areaUnit;
}

export function formatCurrency(
  price: number | undefined,
  currency = '€'
): string {
  return formatNumberAsStringWithDots(price || 0) + ' ' + currency;
}

export function concat(
  s1: string | null | undefined,
  s2: string | null | undefined
): string {
  return concatInternal(s1 ? s1 : null, s2);
}

function concatInternal(
  s1: string | null | undefined,
  s2: string | null | undefined
): string {
  return s1 === null || s1 === undefined || s2 === null || s2 === undefined
    ? ''
    : s1 + s2;
}
