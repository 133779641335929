<div mat-dialog-actions class="flex justify-center items-center gap-4 p-8">
  <div class="w-full relative">
    <common-heading label="E-Mail Versand" align="center" class="font-bold text-lg pb-4">
    </common-heading>
    <button mat-dialog-close class="absolute top-0 right-0 flex justify-center items-center">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="w-full">
    <mat-horizontal-stepper *ngIf="isRentalOfferEmails" #rentalOfferEmailsStepper>
      <mat-step [stepControl]="rentalOfferForms.tenants">
        <ng-template matStepLabel>Miet-Interessenten</ng-template>
        <email-form [emailFormGroup]="rentalOfferForms.tenants"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(rentalOfferForms.tenants, rentalOfferEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="rentalOfferForms.landlord">
        <ng-template matStepLabel>Vermieter</ng-template>
        <email-form [emailFormGroup]="rentalOfferForms.landlord"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(rentalOfferForms.landlord, rentalOfferEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="rentalOfferForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="rentalOfferForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(rentalOfferForms.others, rentalOfferEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-horizontal-stepper *ngIf="isBuyingOfferEmails" #buyingOfferEmailsStepper>
      <mat-step [stepControl]="buyingOfferForms.buyers">
        <ng-template matStepLabel>Kauf-Interessenten</ng-template>
        <email-form [emailFormGroup]="buyingOfferForms.buyers"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(buyingOfferForms.buyers, buyingOfferEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="buyingOfferForms.sellers">
        <ng-template matStepLabel>Verkäufer</ng-template>
        <email-form [emailFormGroup]="buyingOfferForms.sellers"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(buyingOfferForms.sellers, buyingOfferEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="buyingOfferForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="buyingOfferForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(buyingOfferForms.others, buyingOfferEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-horizontal-stepper *ngIf="isHandoverProtocolEmails" #hopEmailsStepper>
      <mat-step [stepControl]="handoverProtocolForms.transferors">
        <ng-template matStepLabel>Übergeber</ng-template>
        <email-form [emailFormGroup]="handoverProtocolForms.transferors"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(handoverProtocolForms.transferors, hopEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="handoverProtocolForms.transferees">
        <ng-template matStepLabel>Übernehmer</ng-template>
        <email-form [emailFormGroup]="handoverProtocolForms.transferees"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(handoverProtocolForms.transferees, hopEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="handoverProtocolForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="handoverProtocolForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(handoverProtocolForms.others, hopEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-horizontal-stepper *ngIf="isViewingPassEmails" #vpEmailsStepper>
      <mat-step [stepControl]="viewingPassForms.customers">
        <ng-template matStepLabel>Interessenten</ng-template>
        <email-form [emailFormGroup]="viewingPassForms.customers"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(viewingPassForms.customers, vpEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="viewingPassForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="viewingPassForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(viewingPassForms.others, vpEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-horizontal-stepper *ngIf="isExclusiveAgreementEmails" #eaEmailsStepper>
      <mat-step [stepControl]="exclusiveAgreementForms.contractEntities">
        <ng-template matStepLabel>Auftraggeber</ng-template>
        <email-form [emailFormGroup]="exclusiveAgreementForms.contractEntities"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(exclusiveAgreementForms.contractEntities, eaEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="exclusiveAgreementForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="exclusiveAgreementForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(exclusiveAgreementForms.others, eaEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-horizontal-stepper *ngIf="isPlainAgreementEmails" #eaEmailsStepper>
      <mat-step [stepControl]="plainAgreementForms.contractEntities">
        <ng-template matStepLabel>Auftraggeber</ng-template>
        <email-form [emailFormGroup]="plainAgreementForms.contractEntities"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(plainAgreementForms.contractEntities, eaEmailsStepper)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="plainAgreementForms.others">
        <ng-template matStepLabel>Weitere</ng-template>
        <email-form [emailFormGroup]="plainAgreementForms.others"></email-form>
        <div class="w-full flex flex-column justify-end">
          <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(plainAgreementForms.others, eaEmailsStepper, true)">E-Mail senden
            <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
          </button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <div *ngIf="isCustomerPortalBulkEmails" class="pb-4">
      <email-form [emailFormGroup]="customerPortalBulkForms.config" [isBulkEmail]="true"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(customerPortalBulkForms.config, undefined, true)">E-Mails senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="isCustomerPortalEmails" class="pb-4">
      <email-form [emailFormGroup]="customerPortalForms.customers"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(customerPortalForms.customers, undefined, true)">E-Mail senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="isCustomerPortalDocumentEmails" class="pb-4">
      <email-form [emailFormGroup]="customerPortalDocumentForms.customers"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(customerPortalDocumentForms.customers, undefined, true)">E-Mail senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="isCustomerPortalNotificationEmails" class="pb-4">
      <email-form [emailFormGroup]="customerPortalNotificationForms.customers"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
              'bg-gray-400' : loading
            }" (click)="sendEmail(customerPortalNotificationForms.customers, undefined, true)">E-Mail senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="isOwnerPortalEmails" class="pb-4">
      <email-form [emailFormGroup]="ownerPortalForms.owners"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
                  'bg-gray-400' : loading
                }" (click)="sendEmail(ownerPortalForms.owners, undefined, true)">E-Mail senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="isOwnerPortalOfferEmails" class="pb-4">
      <email-form [emailFormGroup]="ownerPortalOfferForms.owners"></email-form>
      <div class="w-full flex flex-column justify-end">
        <button [disabled]="loading" class="rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary" [ngClass]="{
                  'bg-gray-400' : loading
                }" (click)="sendEmail(ownerPortalOfferForms.owners, undefined, true)">E-Mail senden
          <mat-icon class="ml-1 align-middle" *ngIf="loading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
