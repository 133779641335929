import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectIsEdiRealTokenValid,
  selectIsJustimmoTokenValid, selectIsMicrosoftTokenValid, selectIsOnOfficeTokenValid,
  selectIsRemaxTokenValid,
} from 'src/app/store/_global/global.selectors';
import { ExternalSystem } from 'src/app/common-components/integration-wrapper/integration-wrapper.component';
import { AccessService } from "services/access-service";
import {
  selectIsEdiReal,
  selectIsJustimmo,
  selectIsMicrosoft, selectIsOnOffice,
  selectIsRemax
} from "../../store/settings/settings.selectors";
import { MatDialog } from "@angular/material/dialog";
import { AccountLinkingService } from "services/account-linking.service";

@Component({
  selector: 'integration-statuses',
  templateUrl: './integration-statuses.component.html',
})
export class IntegrationStatusesComponent {
  isEdiRealTokenValid$ = this._store.select(selectIsEdiRealTokenValid);
  isRemaxTokenValid$ = this._store.select(selectIsRemaxTokenValid);
  isJustimmoTokenValid$ = this._store.select(selectIsJustimmoTokenValid);
  isOnOfficeTokenValid$ = this._store.select(selectIsOnOfficeTokenValid);
  isMicrosoftTokenValid$ = this._store.select(selectIsMicrosoftTokenValid);
  hasFreeVersion$ = this.accessService.hasFreeVersion$;

  ExternalSystem = ExternalSystem;

  isEdiReal$ = this._store.select(selectIsEdiReal);
  isJustimmo$ = this._store.select(selectIsJustimmo);
  isRemax$ = this._store.select(selectIsRemax);
  isMicrosoft$ = this._store.select(selectIsMicrosoft);
  isOnOffice$ = this._store.select(selectIsOnOffice);

  ediLink = this._accountLinkingService.generateLink('edi-real', window.location.href);
  remaxLink = this._accountLinkingService.generateLink('remax-idp', window.location.href);
  justimmoLink = this._accountLinkingService.generateLink('justimmo', window.location.href);
  microsoftLink = this._accountLinkingService.generateLink('microsoft', window.location.href);

  constructor(
    private readonly _store: Store,
    private readonly _accountLinkingService: AccountLinkingService,
    public accessService: AccessService,
    public dialog: MatDialog,
  ) { }
}
