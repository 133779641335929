import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'sidebar-sync-item',
  template: `
    <mat-card>
      <mat-card-content>
        <div class="flex justify-center items-center">
          <mat-spinner
            [diameter]="20"
            [matTooltip]="text"
          ></mat-spinner>
          <div *ngIf="size === 'lg'" class="ml-4 font-bold">{{text}}</div>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    ::ng-deep .mat-card {
      padding: 8px !important;
    }

    ::ng-deep.mat-progress-spinner circle {
      stroke: #536b91 !important;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
})
export class SidebarSyncItemComponent {
  @Input() text = '';
  @Input() size: 'sm' | 'lg' = 'sm';
}
