<div class="p-4">
  <ng-container *ngIf="isExpanded || isMobile else openSidenavTemplate">
    <div class="flex justify-end mb-4">
      <button class="flex items-center text-sm text-primary" (click)="toggleSidenav()">
        {{ 'general.close' | translate }} <mat-icon [inline]="true">keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <sidebar-profile-block></sidebar-profile-block>
    <div class="mt-4">
      <integration-statuses></integration-statuses>
    </div>
    <div class="mt-4">
      <sidebar-sync-block size="lg"></sidebar-sync-block>
    </div>

    <div class="mt-8">
      <banner-block-component></banner-block-component>
    </div>

    <div *ngIf="selectedPortal" class="portal-outlet">
      <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
    </div>


    <div class="mt-4">
      <account-status></account-status>
    </div>


  </ng-container>
</div>

<ng-template #openSidenavTemplate>
  <div class="flex flex-col" (click)="toggleSidenav()">
    <common-user-avatar size="mid"></common-user-avatar>
    <div class="mt-4 flex items-center text-sm text-primary cursor-pointer">
      <mat-icon [inline]="true">keyboard_arrow_left</mat-icon>
      {{ 'general.open' | translate }}
    </div>
    <div class="mt-2">
      <sidebar-sync-block size="sm"></sidebar-sync-block>
    </div>
  </div>
</ng-template>
