import { Component, Input } from '@angular/core';
import { CustomerWithContact } from '@domains/customer';

@Component({
  selector: 'customer-preview',
  template: `
    <div>
      <common-avatar
        [namesForInitials]="[customer.firstname, customer.lastname]"
        size="small"
      ></common-avatar>
    </div>

    <div class="ml-4">
      <p class="font-bold text-black text-base leading-none">
        <span>{{customer.title}} </span>
        <common-marked-text
          [text]="customer.firstname"
          [textToMark]="firstnameSearchTerm"
        ></common-marked-text>
        <common-marked-text
          [text]="customer.lastname"
          [textToMark]="lastnameSearchTerm"
        ></common-marked-text>
        <span *ngIf="customer.externalId" class="text-sm text-primary">({{ customer.externalId }})</span>
      </p>
      <p class="text-sm text-primary">{{ customer.contact.email }}</p>
      <p class="text-sm text-primary">{{ customer.contact.phone }}</p>
      <p class="text-sm text-primary">
        {{ customer.contact.address?.zipCode }}
        {{ customer.contact.address?.city }}
      </p>
    </div>
  `,
  styleUrls: ['customer-preview.component.scss'],
})
export class CustomerPreviewComponent {
  @Input() customer!: CustomerWithContact;

  @Input() firstnameSearchTerm = '';
  @Input() lastnameSearchTerm = '';
}
