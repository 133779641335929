import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ImageAdditionService } from './image-addition.service';
import { DocumentService } from 'services/document.service';
import { Subscription } from 'rxjs';

export interface ImageUploadInfo {
  id: string;
  type: string;
  data: Blob;
}

@Component({
  selector: 'image-management',
  template: `
    <images-input
      (imagesTaken)="onImagesTaken($event)"
      [text]="buttonText"
      [supportPdf]="supportPdf"
      class="mb-6"
    ></images-input>
    <image-list
      [imagesFormArray]="imagesFormArray"
      [documentTypes]="documentTypes"
      (removeImage)="onRemoveImage($event)"
      (retryUpload)="onRetryUpload($event)"
    ></image-list>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
    `,
  ],
})
export class ImageManagementComponent implements OnDestroy {
  @Input() imageType!: string;
  @Input() descriptionTemplate = 'Image #{0}';
  @Input() imagesFormArray!: FormArray;
  @Input() buttonText = 'Fügen Sie Fotos hinzu';
  @Input() supportPdf = false;
  @Input() documentTypes: Array<{ value: string; label: string }> = [];

  private _sub = new Subscription();

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _imageAdditionService: ImageAdditionService,
    private readonly _documentService: DocumentService
  ) { }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  async onImagesTaken(files: FileList): Promise<void> {
    await Promise.all(
      Array.from(files).map(async (originalImage) => {
        const id = Math.random().toString().replace('0.', ''); // Unique ID

        // Immediately add the image to the form and show the upload progress bar
        this._addImageToForm(
          id,
          originalImage,
          0, // Initially loaded 0 bytes
          originalImage.size, // File size
          'PENDING' // Initial status
        );

        // Start the upload process immediately
        this._saveImage(id, originalImage);
      })
    );
  }

  onRemoveImage({ index }: { index: number }): void {
    console.log('remove image at index', index);
    this.imagesFormArray.removeAt(index);
  }

  onRetryUpload({ index }: { index: number }): void {
    const image = this.imagesFormArray.at(index).value;

    this._saveImage(image.id, image.originalImage);
  }

  private _saveImage(id: string, originalImage: File): void {
    const payload: ImageUploadInfo = {
      id,
      type: this.imageType,
      data: originalImage,
    };

    this._sub.add(
      this._documentService.saveImage(payload)
        .subscribe({
          next: (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                this._updateUploadProgress(
                  id,
                  event.loaded,
                  event.total ?? originalImage.size,
                  'IN_PROGRESS'
                );
                break;
              case HttpEventType.Response:
                if (!event.ok || !event.body?.uploads) {
                  this._handleUploadError(id, 'ERROR');
                  return;
                }

                const uploadedImages = event.body.uploads;
                this._documentService.updateCompletedUploads(
                  uploadedImages,
                  this.imagesFormArray,
                  ['originalImage']
                );
                break;
            }
          },
          error: (err) => {
            console.error('[Frontend] Upload Failed:', err);
            this._handleUploadError(id, 'ERROR');
          }
        })
    );
  }

  private _addImageToForm(
    id: string,
    originalImage: File,
    loaded: number,
    uploadSize: number,
    uploadStatus: string,
  ): void {
    const cnt = this.imagesFormArray.length + 1;
    const description = this.descriptionTemplate.replace('{0}', cnt.toString());

    this.imagesFormArray.push(
      this._fb.group({
        id: [id],
        // PRO-660: This is only added here temporarily in case "a re-upload" is triggered, and we need to access it
        originalImage: [originalImage],
        description: [description],
        type: [this.imageType],
        storageUrl: [undefined],
        loaded: [loaded],
        uploadSize: [uploadSize],
        uploadStatus: [uploadStatus],
        uploadProgress: [0],
        order: [cnt],
      })
    );
  }

  private _updateUploadProgress(
    id: string,
    loaded: number,
    uploadSize: number,
    uploadStatus: string
  ): void {
    const image = this.imagesFormArray.controls.find(
      (img) => img.value.id === id
    );
    if (image) {
      image.patchValue({
        loaded: loaded,
        uploadStatus: uploadStatus,
        uploadSize: uploadSize,
        uploadProgress: Math.round((loaded / uploadSize) * 100),
      });
    }
  }

  private _handleUploadError(id: string, uploadStatus: string): void {
    const image = this.imagesFormArray.controls.find(
      (img) => img.value.id === id
    );
    if (image) {
      image.patchValue({ uploadStatus });
    }
  }
}
