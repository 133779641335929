import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'email-attachment',
  template: `
    <div [formGroup]="formGroup" class="relative p-2 pt-0 bg-white">
      <mat-form-field
        appearance="standard"
        class="w-full"
      >
        <mat-label>Dokument</mat-label>
        <a *ngIf="storageUrl" matPrefix [href]="storageUrl" target="_blank" class="flex-shrink-0">
          <mat-icon class="cursor-pointer mr-4">
            attach_file
          </mat-icon>
        </a>
        <input matInput readonly formControlName="description" />
        <mat-icon matSuffix (click)="delete.emit()" class="cursor-pointer">
          clear
        </mat-icon>
      </mat-form-field>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }
    `,
  ],
})
export class EmailAttachmentComponent {
  @Input() formGroup!: FormGroup;

  @Output() delete = new EventEmitter<void>();

  get storageUrl(): string {
    const control = this.formGroup.controls.storageUrl;
    return (control && control.value) ?? '';
  }
}

@Component({
  selector: 'email-attachments',
  template: `
  <div class="flex flex-row scrollable">
    <div *ngFor="let doc of attachments.controls; let i = index">
      <email-attachment
        [formGroup]="$any(doc)"
        (delete)="removeAttachment(i)"
      ></email-attachment>
    </div>
  </div>
  `,
  styles: [
    `
      .scrollable {
        overflow-y: hidden;
        overflow-x: scroll;
      }
    `,
  ],
})
export class EmailAttachmentsComponent {
  @Input() attachments!: FormArray;

  removeAttachment(index: number): void {
    this.attachments.removeAt(index);
  }
}
