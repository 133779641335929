import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AddressFieldAutocompleteComponent } from './address-field-autocomplete.component';
import { GoogleMapsAutocompleteModule } from './google-maps-autocomplete/google-maps-autocomplete.module';

@NgModule({
  declarations: [AddressFieldAutocompleteComponent],
  imports: [CommonModule, GoogleMapsAutocompleteModule, MatFormFieldModule],
  exports: [AddressFieldAutocompleteComponent],
})
export class AddressFieldAutocompleteModule { }
