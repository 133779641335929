import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { CustomerTypeSelectorComponent } from './customer-type-selector.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [CustomerTypeSelectorComponent],
  declarations: [CustomerTypeSelectorComponent],
  providers: [],
})
export class CustomerTypeSelectorModule { }

export * from './customer-type-selector.component';
export * from './model';
