import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CustomerPortalAgent } from '@domains/customer-portal';
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";

@Component({
  standalone: true,
  selector: 'agent-preview-card',
  templateUrl: 'agent-preview-card.component.html',
  styles: [`
    .agent-image {
      max-width: 6rem;
      max-height: 6rem;
      object-fit: contain;

      border-radius: 3rem;
    }
  `],
  imports: [CommonModule, MatCardModule, MatIconModule, TranslationComponentModule],
})
export class AgentPreviewCardComponent {
  @Input() agent?: CustomerPortalAgent;
  @Input() showTitle: boolean = false;
}
