import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'image-list-item',
  templateUrl: './image-list-item.component.html',
  styles: [
    `
      .image-place {
        width: 60px;
        height: 60px;
        background-color: #f5f5f5;
      }

      .icon {
        color: #263238;
      }

      .mat-card-actions, .mat-card-content {
        margin-bottom: 0;
      }
    `,
  ],
})
export class ImageListItemComponent {
  @Input() formGroup!: FormGroup;
  @Output() delete = new EventEmitter<void>();
  @Output() retry = new EventEmitter<void>();


  get storageUrl(): string {
    return this.formGroup.controls.storageUrl?.value;
  }

  get isPdf(): boolean {
    return (this.storageUrl ?? '').endsWith('.pdf');
  }

  get uploadProgress(): number {
    return this.formGroup.controls.uploadProgress?.value ?? 0;
  }

  get uploadStatus(): string {
    return this.formGroup.controls.uploadStatus?.value ?? '';
  }

  get showUploadSpinner(): boolean {
    return this.uploadStatus === 'IN_PROGRESS' || (!this.uploadStatus && !this.storageUrl);
  }

  get showUploadError(): boolean {
    return this.uploadStatus === 'ERROR'
  }

  get showNoStorageUrlError(): boolean {
    return this.storageUrl === null;
  }
}
