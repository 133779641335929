import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleComponent } from './slide-toggle.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";

@NgModule({
  declarations: [SlideToggleComponent],
    imports: [CommonModule, ReactiveFormsModule, MatSlideToggleModule, MatTooltipModule, TranslationComponentModule],
  exports: [SlideToggleComponent],
})
export class SlideToggleModule {}
