import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { Profile } from '../store/profile/profile.reducers';
import { catchError, first, map } from 'rxjs/operators';
import { cleanTypenames } from './cleanup';
import { Store } from '@ngrx/store';
import {
  failedToLoadProfile,
  setProfile,
} from '../store/profile/profile.actions';
import {
  UpdateProfile,
  UpdateProfileAvatar,
  UpdateProfileCompanyLogo,
} from '../settings/profile.domain';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apollo: Apollo, private store: Store) { }

  getProfile(): Observable<Profile> {
    return this.apollo
      .query({
        query: gql`
          query GetProfile {
            profile {
              id
              givenName
              familyName
              email
              isRemax
              themeConfig
              legalEntity
              officeName
              phone
              officeAddress
              companyLogo
              googleReviewLink
              meetingLink
              createdTimeStamp
              companyInformation {
                legalEntity
                officeName
                officeAddress
                taxNumber
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((error) => {
          this.store.dispatch(failedToLoadProfile());
          return throwError(error);
        }),
        map((result: any) => cleanTypenames(result.data.profile))
      );
  }

  getProfileById(id: string): Observable<Profile> {
    return this.apollo
      .query({
        query: gql`
          query GetProfile($id: String) {
            profileById(id: $id) {
              id
              givenName
              familyName
              email
              isRemax
              themeConfig
              legalEntity
              officeName
              phone
              officeAddress
              googleReviewLink
              meetingLink
              createdTimeStamp
            }
          }
        `,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        catchError((error) => {
          this.store.dispatch(failedToLoadProfile());
          return throwError(error);
        }),
        map((result: any) => cleanTypenames(result.data.profileById))
      );
  }

  updateProfile(data: UpdateProfile): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateProfile($profile: UpdateProfile!) {
          profile(profile: $profile) {
            id
            givenName
            familyName
            email
            isRemax
            themeConfig
            legalEntity
            officeName
            phone
            officeAddress
            googleReviewLink
            meetingLink
            companyInformation {
              legalEntity
              officeName
              officeAddress
              taxNumber
            }
          }
        }
      `,
      variables: {
        profile: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateProfileAvatar(data: UpdateProfileAvatar): Observable<string> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation UpdateProfileAvatar($profileAvatar: UpdateProfileAvatar!) {
            profileAvatar(profileAvatar: $profileAvatar)
          }
        `,
        variables: {
          profileAvatar: data,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        first(),
        map((result: any) => result.data.profileAvatar)
      );
  }

  updateCompanyLogo(data: UpdateProfileCompanyLogo): Observable<string> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation UpdateCompanyLogo($profileCompanyLogo: UpdateProfileCompanyLogo!) {
            profileCompanyLogo(profileCompanyLogo: $profileCompanyLogo)
          }
        `,
        variables: {
          profileCompanyLogo: data,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        first(),
        map((result: any) => result.data.profileCompanyLogo)
      );
  }

  loadProfile(): void {
    this.getProfile()
      .pipe(first())
      .subscribe((profile) => {
        this.store.dispatch(setProfile({ profile: profile }));
      });
  }
}
