<common-wizard-page-heading [title]="title"></common-wizard-page-heading>
<form [formGroup]="formGroup" class="flex flex-col">
  <image-management class="max-w-xl" [supportPdf]="true" [imageType]="imageType" [imagesFormArray]="IDImagesFormArray"
    descriptionTemplate="Ausweiskopie #{0}" buttonText="Ausweiskopie hochladen"></image-management>
  <ng-container *ngIf="{ customers: customers$ | async } as sync">
    <common-slide-toggle
      class="isConsumer"
      *ngIf="!isBusiness"
      [control]="isConsumerControl"
      label="Ist Konsument?"
    ></common-slide-toggle>
    <mat-form-field [appearance]="appearance">
      <mat-label>Titel</mat-label>
      <input formControlName="title" matInput />
    </mat-form-field>
    <mat-form-field [appearance]="appearance" *ngIf="!isOptional">
      <mat-label>Nachname</mat-label>
      <input formControlName="lastname" matInput required [matAutocomplete]="lastname" />


      <mat-autocomplete #lastname="matAutocomplete">
        <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
        <mat-progress-spinner *ngIf="loading && loadingField === 'lastName'" matSuffix [diameter]="20" color="primary" class="ml-4 mb-2"
                              mode="indeterminate"></mat-progress-spinner>
        <mat-option class="customer" *ngFor="let customer of customers$ | async; trackBy: trackByCustomer" [value]="customer.lastname"
          (onSelectionChange)="onCustomerSelected(customer, 'lastName')">
          <customer-preview [customer]="customer" [firstnameSearchTerm]="sanitizedFirstname"
            [lastnameSearchTerm]="sanitizedLastname"></customer-preview>
        </mat-option>
        <mat-option class="add-new-option" value="" (onSelectionChange)="onAddNewCustomer($event)">
          <div class="border-t-2 text-primary font-bold">
            + Neuen Kontakt erfassen
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field [appearance]="appearance" *ngIf="isOptional">
      <mat-label>Nachname</mat-label>
      <input formControlName="lastname" matInput [matAutocomplete]="lastname" />

      <mat-autocomplete #lastname="matAutocomplete">
        <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
        <mat-progress-spinner *ngIf="loading && loadingField === 'lastName'" matSuffix [diameter]="20" color="primary" class="ml-4 mb-2"
                              mode="indeterminate"></mat-progress-spinner>
        <mat-option class="customer" *ngFor="let customer of customers$ | async; trackBy: trackByCustomer" [value]="customer.lastname"
          (onSelectionChange)="onCustomerSelected(customer, 'lastName')">
          <customer-preview [customer]="customer" [firstnameSearchTerm]="sanitizedFirstname"
            [lastnameSearchTerm]="sanitizedLastname"></customer-preview>
        </mat-option>
        <mat-option class="add-new-option" value="" (onSelectionChange)="onAddNewCustomer($event)">
          <div class="border-t-2 text-primary font-bold">
            + Neuen Kontakt erfassen
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [appearance]="appearance" *ngIf="!isOptional">
      <mat-label>Vorname</mat-label>
      <input formControlName="firstname" matInput required [matAutocomplete]="firstname" />

      <mat-autocomplete #firstname="matAutocomplete">
        <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
        <mat-progress-spinner *ngIf="loading && loadingField === 'firstName'" matSuffix [diameter]="20" color="primary" class="ml-4 mb-2"
                              mode="indeterminate"></mat-progress-spinner>
        <mat-option class="customer" *ngFor="let customer of customers$ | async; trackBy: trackByCustomer" [value]="customer.firstname"
          (onSelectionChange)="onCustomerSelected(customer, 'firstName')">
          <customer-preview [customer]="customer" [firstnameSearchTerm]="sanitizedFirstname"
            [lastnameSearchTerm]="sanitizedLastname"></customer-preview>
        </mat-option>
        <mat-option class="add-new-option" value="" (onSelectionChange)="onAddNewCustomer($event)">
          <div class="border-t-2 text-primary font-bold">
            + Neuen Kontakt erfassen
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field [appearance]="appearance" *ngIf="isOptional">
      <mat-label>Vorname</mat-label>
      <input formControlName="firstname" matInput [matAutocomplete]="firstname" />

      <mat-autocomplete #firstname="matAutocomplete">
        <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
        <mat-progress-spinner *ngIf="loading && loadingField === 'firstName'" matSuffix [diameter]="20" color="primary" class="ml-4 mb-2"
                              mode="indeterminate"></mat-progress-spinner>
        <mat-option class="customer" *ngFor="let customer of customers$ | async; trackBy: trackByCustomer" [value]="customer.firstname"
          (onSelectionChange)="onCustomerSelected(customer, 'firstName')">
          <customer-preview [customer]="customer" [firstnameSearchTerm]="sanitizedFirstname"
            [lastnameSearchTerm]="sanitizedLastname"></customer-preview>
        </mat-option>
        <mat-option class="add-new-option" value="" (onSelectionChange)="onAddNewCustomer($event)">
          <div class="border-t-2 text-primary font-bold">
            + Neuen Kontakt erfassen
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <common-hint-with-link class="mb-4" *ngIf="this.showIdenticalCustomerWarning"
      message="Ein ähnlicher Kunde besteht bereits." clickableMessage="Jetzt ansehen"
      (clickableMessageClicked)="showIdenticalCustomers()"></common-hint-with-link>

    <mat-select #identicalCustomersSelect id="identicalCustomersSelect" class="col-span-4">
      <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
      <mat-option class="customer" *ngFor="let customer of identicalCustomers"
        (onSelectionChange)="onCustomerSelected(customer)">
        <customer-preview [customer]="customer" [firstnameSearchTerm]="sanitizedFirstname"
          [lastnameSearchTerm]="sanitizedLastname"></customer-preview>
      </mat-option>
      <mat-option class="add-new-option" (onSelectionChange)="onAddNewCustomer($event)">
        <div class="border-t-2 text-primary font-bold">
          Mit Anlage eines neuen Kunden fortfahren
        </div>
      </mat-option>
    </mat-select>
  </ng-container>

  <mat-form-field [appearance]="appearance">
    <mat-label appRemoveAriaOwns>Nationalität</mat-label>
    <mat-select formControlName="nationality" *ngIf="countries$ | async as countries">
      <mat-option *ngFor="let country of countries.slice(0, 1)" [value]="country.code">
        {{ country.fullName }}
      </mat-option>
      <mat-optgroup label="Andere">
        <mat-option *ngFor="let country of countries.slice(1)" [value]="country.code">
          {{ country.fullName }}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <ng-container formGroupName="contact">
    <ng-container formGroupName="address">
      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>Straße</mat-label>
          <input formControlName="streetName" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Hausnummer</mat-label>
          <input formControlName="buildingNumber" matInput />
        </mat-form-field>
      </div>

      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>{{"address.stairway" | translate}}</mat-label>
          <input formControlName="stairway" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>{{"address.door_number" | translate}}</mat-label>
          <input formControlName="doorNumber" matInput />
        </mat-form-field>
      </div>

      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>PLZ</mat-label>
          <input formControlName="zipCode" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>Stadt</mat-label>
          <input formControlName="city" matInput />
        </mat-form-field>
      </div>

      <mat-form-field [appearance]="appearance">
        <mat-label>Land</mat-label>
        <mat-select formControlName="country" *ngIf="countries$ | async as countries">
          <mat-option *ngFor="let country of countries.slice(0, 1)" [value]="country.fullName">
            {{ country.fullName }}
          </mat-option>
          <mat-optgroup label="Andere">
            <mat-option *ngFor="let country of countries.slice(1)" [value]="country.fullName">
              {{ country.fullName }}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

    </ng-container>
  </ng-container>

  <mat-form-field [appearance]="appearance">
    <mat-label>Geburtsdatum</mat-label>
    <input matInput [matDatepicker]="birthday" [max]="maxDate" formControlName="birthdate" />
    <mat-error *ngIf="isBirthdateValid">Mindestalter 18 Jahre</mat-error>
    <mat-datepicker-toggle matSuffix [for]="birthday"></mat-datepicker-toggle>
    <mat-datepicker #birthday disabled="false"></mat-datepicker>
  </mat-form-field>

  <ng-container formGroupName="contact" *ngIf="!isOptional">
    <div class="flex gap-x-4 flex-col md:flex-row w-full">
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Telefonnummer</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput required />
        <mat-error *ngIf="isEmailValid">Bitte eine gültige Email Adresse eingeben</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container formGroupName="contact" *ngIf="isOptional">
    <div class="flex gap-x-4 flex-col md:flex-row w-full">
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Telefonnummer</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput />
        <mat-error *ngIf="isEmailValid">Bitte eine gültige Email Adresse eingeben</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

</form>

<ng-container [formGroup]="pepFormGroup" *ngIf="showPep">
  <div class="flex justify-between items-center rounded-lg h-12 pep-info p-4 mb-4">
    <h3 class="text-primary">PEP-Informationen</h3>
    <mat-icon color="primary" [matTooltip]="pepTooltip">info</mat-icon>
  </div>
  <div class="flex gap-x-4 flex-col w-full pl-6">
    <div class="flex flex-col xl:flex-row justify-between font-source text-lg pb-2">
      <label id="is-pep">Person ist eine PEP</label>
      <mat-radio-group aria-labelledby="is-pep" formControlName="isPep">
        <mat-radio-button [value]="true">Ja</mat-radio-button>
        <mat-radio-button [value]="false" class="ml-4">Nein</mat-radio-button>
      </mat-radio-group>
    </div >

    <div class="flex flex-col xl:flex-row justify-between font-source text-lg pb-2">
      <label id="wasPepLastYear">Person war in den letzten 12 Monaten eine PEP</label>
      <mat-radio-group aria-labelledby="wasPepLastYear" formControlName="wasPepLastYear">
        <mat-radio-button [value]="true">Ja</mat-radio-button>
        <mat-radio-button [value]="false" class="ml-4">Nein</mat-radio-button>
      </mat-radio-group>
    </div >

    <div class="flex flex-col xl:flex-row justify-between font-source text-lg pb-2">
      <label id="isFamilyMemberPep">Ein Familienmitglied ist eine PEP</label>
      <mat-radio-group aria-labelledby="isFamilyMemberPep" formControlName="isFamilyMemberPep">
        <mat-radio-button [value]="true">Ja</mat-radio-button>
        <mat-radio-button [value]="false" class="ml-4">Nein</mat-radio-button>
      </mat-radio-group>
    </div >

    <div class="flex flex-col xl:flex-row justify-between font-source text-lg pb-2">
      <label id="isKnownToBeCloseToPep">Person ist einer PEP bekanntermaßen nahestehend</label>
      <mat-radio-group aria-labelledby="isKnownToBeCloseToPep" formControlName="isKnownToBeCloseToPep">
        <mat-radio-button [value]="true">Ja</mat-radio-button>
        <mat-radio-button [value]="false" class="ml-4">Nein</mat-radio-button>
      </mat-radio-group>
    </div >

    <div
      *ngIf="this.pepFormGroup.controls.isPep.value || this.pepFormGroup.controls.wasPepLastYear.value || this.pepFormGroup.controls.isFamilyMemberPep.value || this.pepFormGroup.controls.isKnownToBeCloseToPep.value"
      class="flex gap-x-4 flex-col md:flex-row w-full">
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Vorname</mat-label>
        <input formControlName="firstName" matInput />
      </mat-form-field>
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Nachname</mat-label>
        <input formControlName="lastName" matInput />
      </mat-form-field>
    </div>

    <div>
      <common-button (click)="resetAllRadioButtons()" [dynamicWidth]="true">PEP Auswahl zurücksetzen</common-button>
    </div>

  </div>
</ng-container>

<ng-container [formGroup]="moneyLaunderingFormGroup" *ngIf="showMoneyLaundring">

  <common-slide-toggle
    label="Geldwäsche"
    [control]="moneyLaunderingFormGroup.controls.askForMoneyLaundering"
  ></common-slide-toggle>

  <ng-container *ngIf="moneyLaunderingFormGroup?.controls?.askForMoneyLaundering?.value">

  <div class="pt-4">
  <div class="flex justify-between items-center rounded-lg h-12 pep-info p-4 mb-4">
    <h3 class="text-primary">Mittelherkunft</h3>
  </div>
  <div class='grid grid-cols-1 sm:grid-cols-2 text-lg pl-6'>
    <p>
      <mat-checkbox color='primary' formControlName='investmentIncome'>Veranlagungserträge</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='estateSales'>Liegenschaftsverkauf</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='endowment'>Schenkung</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='inheritance'>Erbschaft</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='savings'>Ersparnisse</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='employment'>Berufstätigkeit</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='investments'>Beteiligung / Gewinnausschüttung</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='pension'>Pension / Abfertigung</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='loan'>Darlehen</mat-checkbox>
    </p>
    <p>
      <mat-checkbox color='primary' formControlName='other'>Sonstiges</mat-checkbox>
    </p>
  </div>
  <p *ngIf="moneyLaunderingFormGroup.controls.other.value">
    <mat-form-field class='my-4 w-full' appearance='outline'>
      <mat-label>Sonstiges</mat-label>
      <textarea
        formControlName='otherDetails'
        matInput
        id='otherDetails'
        rows='3'
      ></textarea>
    </mat-form-field>
  </p>
  </div>
  <div class="pt-4">
    <div class="flex justify-between items-center rounded-lg h-12 pep-info p-4 mb-4">
      <h3 class="text-primary">Deviseninländer / Devisenausländer</h3>
    </div>
    <div class="flex flex-col xl:flex-row justify-between font-source text-lg pb-2 pl-6">
      <mat-radio-group formControlName="residentPerson">
        <mat-radio-button [value]="true">Deviseninländer</mat-radio-button>
        <mat-radio-button [value]="false" class="ml-4">Devisenausländer</mat-radio-button>
      </mat-radio-group>
    </div >
  </div>

  </ng-container>

</ng-container>

<common-hint *ngIf="showUpdateWarning"
             message="Änderungen am Kunden überschreiben die vorherigen Informationen über diesen Kunden."></common-hint>
