import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CreateOwnerPortal } from './owner-portal.model';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';

@Injectable({ providedIn: 'root' })
export class OwnerPortalFormService {

  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _fb: FormBuilder,
  ) { }

  public createEmailsArrayItemControl(email?: string): FormControl {
    return this._fb.control(email ?? '');
  }

  public createCustomersArrayItemForm(): FormGroup {
    return this._customerFormService.createCustomerFormGroup();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact): FormGroup {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createBusinessCustomersArrayItemForm(): FormGroup {
    return this._businessCustomerFormService.createPrivateCustomer();
  }

  public createMustHaveUploadsArrayItemForm(): FormGroup {
    return this._fb.group({
      title: '',
      description: '',
      mandatory: true,
    });
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createGeneralSettingsForm(): FormGroup {
    return this._fb.group({
      showWelcomeCard: false,
      welcomeCardTitle: '',
      welcomeCardText: '',
      mustHaveUploads: this._fb.array([]),
      hasDocumentAccess: true,
      documents: this._fb.array([]),
    })
  }

  public createExclusiveAgreementSettingsForm(): FormGroup {
    return this._fb.group({
      mustHaveUploadsIndividual: this._fb.array([]),
    })
  }

  public createOwnerPortalForm(): FormGroup {
    const form = this._fb.group({
      objectId: [''],
      customerType: [CustomerType.Private],
      businessCustomer: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      customers: this._fb.array([]),
      generalSettings: this.createGeneralSettingsForm(),
      exclusiveAgreementSettings: this.createExclusiveAgreementSettingsForm(),
    });

    form.get('customerType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessCustomer')?.disable();
        form.get('customers')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessCustomer')?.enable();
        form.get('customers')?.disable();
      }
    });

    return form;
  }

  public createOwnerPortalInitialValue(data: { objectId: string }): CreateOwnerPortal {
    const { objectId } = data;
    const customerPortalForm = this.createOwnerPortalForm();

    const result = {
      ...customerPortalForm.value,
      objectId,
      id: '',
      customerType: CustomerType.Private,
      businessCustomer: '',
    };

    return result;
  }
}
