import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { SnackbarService } from "services/snackbar.service";
import { Observable } from "rxjs";
import { ViewingPass } from "@domains/viewing-pass";
import { first, map } from "rxjs/operators";
import { ExclusiveAgreement } from "../exclusive-agreement";
import { RentalOffer } from "@domains/rental-offer";
import { BuyingOffer } from "@domains/buying-offer";
import { HandoverProtocol } from "@domains/handover-protocol";
import { PlainAgreement } from "../plain-agreement";

@Injectable({ providedIn: 'root' })
export class DocumentsApiFacade {

  constructor(
    private readonly apollo: Apollo,
    private readonly _snackbarService: SnackbarService,
  ) { }

  public loadAllViewingPasses$(): Observable<ViewingPass[]> {
    return this.apollo.query({
      query: gql`
          query allViewingPasses {
            allViewingPasses {
              id
              objectId
              lastVisited
              creationDate
              state
              vp_type
              customerType
              customersData {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
              businessCustomerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
              signatureData {
                signedDocumentUrl
              }
              object {
                objectStatus
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
              }
              creator {
                isCreatedByMe
                name
                email
              }
            }
          }
        `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allViewingPasses));
  }

  public loadAllExclusiveAgreements$(): Observable<ExclusiveAgreement[]> {
    return this.apollo.query({
      query: gql`
          query allExclusiveAgreements {
            allExclusiveAgreements {
              id
              objectId
              state
              lastVisited
              contractEntityType
              contractEntities {
                customerId
                title
                firstname
                lastname
                contact {
                  email
                }
              }
              businessContractEntity {
                customerId
                customerData {
                  id
                  companyNumber
                  companyRegister
                  uidNumber
                  customers {
                    id
                    title
                    firstname
                    lastname
                    contact {
                      email
                    }
                  }
                }
              }
              signatureData {
                signedDocumentUrl
              }
              object {
                objectStatus
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
              }
              creator {
                isCreatedByMe
                name
                email
              }
            }
          }
        `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allExclusiveAgreements));
  }

  public loadAllPlainAgreements$(): Observable<PlainAgreement[]> {
    return this.apollo.query({
      query: gql`
        query allPlainAgreements {
          allPlainAgreements {
            id
            objectId
            state
            lastVisited
            contractEntityType
            contractEntities {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessContractEntity {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allPlainAgreements));
  }

  public loadAllRentalOffers$(): Observable<RentalOffer[]> {
    return this.apollo.query({
      query: gql`
        query allRentalOffers {
          allRentalOffers {
            id
            objectId
            state
            lastVisited
            tenantType
            tenants {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
            }
            businessTenant {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  moneyLaundering {
                    askForMoneyLaundering
                    residentPerson
                    investmentIncome
                    estateSales
                    endowment
                    inheritance
                    savings
                    employment
                    investments
                    pension
                    loan
                    other
                    otherDetails
                  }
                }
              }
            }
            landlordType
            landlords {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessLandlord {
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  moneyLaundering {
                    askForMoneyLaundering
                    residentPerson
                    investmentIncome
                    estateSales
                    endowment
                    inheritance
                    savings
                    employment
                    investments
                    pension
                    loan
                    other
                    otherDetails
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allRentalOffers));
  }

  public loadAllBuyingOffers$(): Observable<BuyingOffer[]> {
    return this.apollo.query({
      query: gql`
        query allBuyingOffers {
          allBuyingOffers {
            id
            objectId
            state
            workflowState
            lastVisited
            language
            buyerType
            buyers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessBuyer {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            sellerType
            sellers {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessSeller {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                uidNumber
                customers {
                  id
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            isFromCustomerPortal
            customerPortalId
            sellersAcceptance {
              sellerAcceptance
            }
            buyersAcceptanceWithChange {
              buyersAcceptanceWithChange
            }
            customerPortal {
              showSellerDecision
            }
            ownerCustomerPortal {
              showBuyerDecision
            }
            isAvailableForOwner
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allBuyingOffers));
  }

  public loadAllHandoverProtocols$(): Observable<HandoverProtocol[]> {
    return this.apollo.query({
      query: gql`
        query allHandoverProtocols {
          allHandoverProtocols {
            id
            objectId
            state
            lastVisited
            protocolType
            signatureData {
              transferors {
                type
                signatureAsSvg
                signatureText
                present
                date
                location
              }
              signedDocumentUrl
            }
            transferorType
            transferors
            transferorsData {
              id
              birthdate
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessTransferor
            businessTransferorData {
              id
              companyNumber
              companyRegister
              uidNumber
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            transfereeType
            transferees
            transfereesData {
              id
              birthdate
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessTransferee
            businessTransfereeData {
              id
              companyNumber
              companyRegister
              uidNumber
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.allHandoverProtocols));
  }
}
