import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { CustomerPortalAgent } from '@domains/customer-portal';
import { HeadingsModule } from '../headings/headings.module';
import { MatCardModule } from '@angular/material/card';
import { ButtonModule } from 'src/app/common-components/button/button.module';
import { AgentPreviewCardComponent } from 'src/app/common-components/agent-preview-card/agent-preview-card.component';

export interface SelectAgentDialogData {
  selectedAgent: CustomerPortalAgent | CustomerPortalAgent[];
  agents: CustomerPortalAgent[];
  multiple?: boolean;
}

@Component({
  selector: 'select-agent-dialog',
  standalone: true,
  templateUrl: 'select-agent-dialog.component.html',
  styleUrls: ['select-agent-dialog.component.scss'],
  imports: [
    CommonModule,
    ButtonModule,
    MatIconModule,
    HeadingsModule,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
    AgentPreviewCardComponent,
  ],
})
export class SelectAgentDialogComponent {
  agents: CustomerPortalAgent[] = [];
  selectedAgents: CustomerPortalAgent[] = [];
  isMultiple: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<SelectAgentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: SelectAgentDialogData,
  ) {
    this._dialogRef.disableClose = true;
    this.isMultiple = dialogData.multiple ?? false;

    if (this.isMultiple) {
      this.selectedAgents = Array.isArray(dialogData.selectedAgent)
        ? dialogData.selectedAgent
        : [];
    } else {
      this.selectedAgents = dialogData.selectedAgent
        ? [dialogData.selectedAgent as CustomerPortalAgent]
        : [];
    }

    this.agents = dialogData.agents;
  }

  isSelectedAgent(agent: CustomerPortalAgent): boolean {
    return this.selectedAgents.some((a) => a.id === agent.id);
  }

  selectAgent(agent: CustomerPortalAgent): void {
    if (this.isMultiple) {
      const index = this.selectedAgents.findIndex((a) => a.id === agent.id);
      if (index > -1) {
        this.selectedAgents.splice(index, 1); // Deselect the agent
      } else {
        this.selectedAgents.push(agent); // Select the agent
      }
    } else {
      this.selectedAgents = this.selectedAgents[0]?.id === agent.id ? [] : [agent];
    }
  }

  close(): void {
    this._dialogRef.close();
  }

  confirm(): void {
    const result = this.isMultiple ? this.selectedAgents : this.selectedAgents[0] || null;
    this._dialogRef.close(result);
  }
}
