import { SortState } from './filter.component';

export const sortByLastVisited = (sortDirection: SortState) => {
  return (doc1: any, doc2: any) => {
    const doc1LastVisited = +doc1.lastVisited;
    const doc2LastVisited = +doc2.lastVisited;

    return sortDirection === 'asc' ?
      doc1LastVisited - doc2LastVisited :
      doc2LastVisited - doc1LastVisited;
  };
}