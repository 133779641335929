import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { CustomerFormService } from "@domains/customer";

@Component({
  selector: 'signature-new-customers-page',
  templateUrl: './customers.component.html',
  styles: [`
      :host {
        @apply block space-y-4;
      }
  `]
})
export class SignatureNewCustomerPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formArray!: FormArray;
  @Input() customerName!: string;

  step = 0;

  private _subscription!: Subscription;

  constructor(private readonly _customerFormService: CustomerFormService) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.formArray.length === 0) {
      this.formArray.push(this._customerFormService.createCustomerFormGroup());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._subscribeToChanges(changes.formArray.currentValue);
  }

  getAbstractControlAsFormGroup(control: AbstractControl): FormGroup {
    return control as FormGroup;
  }

  addCustomer(): void {
    const newCustomer = this._customerFormService.createCustomerFormGroup();
    this.formArray.push(newCustomer);
    this.step = this.formArray.length - 1;

    this._subscribeToChanges();
  }

  removeCustomer(index: number): void {
    this.formArray.removeAt(index);
    this.step = this.formArray.length - 1;

    this._subscribeToChanges();

    if (this.formArray.length === 0) {
      this.addCustomer();
    }
  }

  setStep(event: number): void {
    this.step = event;
  }

  private _subscribeToChanges(formArray = this.formArray): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    this._subscription = new Subscription();

    formArray.controls.forEach((control) => {
      this._subscription.add(control.valueChanges.pipe((take(1))).subscribe(() => {
        const customerFormGroup = control.get('customer') as FormGroup;
        this._customerFormService.addCustomerValidators(customerFormGroup);
      }));
    });
    formArray.updateValueAndValidity();
  }

}
