import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarService} from "services/snackbar.service";

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private onlineStatus$$ = new BehaviorSubject<boolean>(navigator.onLine);
  isOnline$: Observable<boolean> = this.onlineStatus$$.asObservable();
  private wasOffline = !navigator.onLine;

  constructor(private readonly _snackbarService: SnackbarService,) {
    this._listenToNetworkChanges();
  }

  private _listenToNetworkChanges(): void {
    const online$ = fromEvent(window, 'online').pipe(mapTo(true));
    const offline$ = fromEvent(window, 'offline').pipe(mapTo(false));

    merge(online$, offline$)
      .pipe(startWith(navigator.onLine))
      .subscribe((isOnline) => {
        this.onlineStatus$$.next(isOnline);
        this._showNetworkSnackbar(isOnline);
      });
  }

  private _showNetworkSnackbar(isOnline: boolean): void {
    if (!isOnline) {
      // Show persistent offline snackbar
      this._snackbarService.showSnackbar(
        'Die Verbindung zum Internet wurde unterbrochen',
        'mat-warn',
        false,
        false,
      );
      this.wasOffline = true;
    } else if (this.wasOffline) {
      // Show online snackbar that disappears after 3 seconds
      this._snackbarService.showSnackbar(
        'Sie sind wieder Online',
        'mat-primary',
        true
      );
      this.wasOffline = false;
    }
  }
}
