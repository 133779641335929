import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsManagementComponent } from './documents-management.component';
import { HeadingsModule } from '../headings/headings.module';
import { ButtonModule } from '../button/button.module';
import { DocumentsListComponent } from './documents-list/list.component';
import { DocumentsListItemComponent } from './documents-list/list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DropzoneModule } from 'src/app/common-components/dropzone/dropzone.module';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    DocumentsManagementComponent,
    DocumentsListComponent,
    DocumentsListItemComponent,
  ],
  exports: [DocumentsManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeadingsModule,
    ButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    DropzoneModule,
    MatDatepickerModule,
    MatExpansionModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
  ],
})
export class DocumentsManagementModule { }
