import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-button',
  template: `
    <button
      class="text-base font-source font-bold py-2"
      [ngClass]="{
        'w-28 md:w-32': !dynamicWidth,
        'bg-primary text-white': !secondary,
        'bg-white text-primary': secondary,
        'bg-gray-500 cursor-not-allowed': disabled || isSaving,
        'rounded-lg px-3': !isCircle,
        'rounded-full px-2 w-10 h-10': isCircle
      }"
      type="button"
      [disabled]="disabled || isSaving"
    >
      <mat-spinner [diameter]="20" class="mr-2" *ngIf="isSaving"></mat-spinner>
      <ng-content></ng-content>
    </button>
  `,
  styles: [`
    :host {
      display: inline-block;
    }

    ::ng-deep.mat-progress-spinner circle {
      stroke: #536b91;
    }

  `],
})
export class ButtonComponent {
  @Input() dynamicWidth = false;
  @Input() secondary = false;
  @Input() disabled = false;
  @Input() isCircle = false;
  @Input() isSaving = false;
}
