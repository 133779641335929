import * as DataActions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ViewingPass, SaveViewingPassDto, LocalViewingPass } from '../viewing-pass.model';

export interface State {
  viewingPasses: {
    data: ViewingPass[];
    loading: boolean;
  };
  viewingPassDetails: {
    data: ViewingPass | null;
    loading: boolean;
  };
  savingProcess: {
    loading: boolean;
    error: boolean;
    offline: boolean;
  };
  localViewingPasses: {
    data: LocalViewingPass[];
  };
}

export const initialState: State = {
  viewingPasses: {
    data: [],
    loading: false,
  },
  viewingPassDetails: {
    data: null,
    loading: false,
  },
  savingProcess: {
    loading: false,
    error: false,
    offline: false,
  },
  localViewingPasses: {
    data: [],
  }
};

const viewingPassReducer = createReducer(
  initialState,

  on(DataActions.LoadViewingPasses, DataActions.LoadViewingPassesByCustomer, (state) => ({
    ...state,
    viewingPasses: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.ViewingPassesLoaded, (state, { payload }) => ({
    ...state,
    viewingPasses: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.ViewingPassesLoadingFailed, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: false,
    },
  })),

  on(DataActions.LoadViewingPassDetails, DataActions.LoadLocalViewingPassDetails, (state) => ({
    ...state,
    viewingPassDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.ViewingPassDetailsLoaded, (state, { payload }) => ({
    ...state,
    viewingPassDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.ViewingPassDetailsLoadingFailed, (state) => ({
    ...state,
    viewingPassDetails: {
      ...state.viewingPassDetails,
      loading: false,
    },
  })),
  on(DataActions.ClearViewingPassDetails, (state) => ({
    ...state,
    viewingPassDetails: {
      data: null,
      loading: false,
    },
  })),

  on(DataActions.SaveViewingPass, (state) => ({
    ...state,
    savingProcess: {
      loading: true,
      error: false,
      offline: false,
    },
  })),
  on(DataActions.SaveViewingPassSucceded, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: false,
      offline: false,
    },
  })),
  on(DataActions.SaveViewingPassFailed, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: true,
      offline: false,
    },
  })),

  on(DataActions.DeleteViewingPass, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: true,
    },
  })),
  on(DataActions.DeleteViewingPassSucceded, (state, { viewingPassId }) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      data: state.viewingPasses.data.filter((v: ViewingPass) => v.id !== viewingPassId),
    },
  })),
  on(DataActions.DeleteViewingPassFailed, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => viewingPassReducer(state, action);

export const getViewingPasses = (state: State): ViewingPass[] => state.viewingPasses.data;
export const getViewingPassesLoading = (state: State): boolean => state.viewingPasses.loading;
export const getViewingPassDetails = (state: State): ViewingPass | null => state.viewingPassDetails.data;
export const getViewingPassDetailsLoading = (state: State): boolean => state.viewingPassDetails.loading;
export const getSavingProcessLoading = (state: State): boolean => state.savingProcess.loading;
export const getSavingProcessError = (state: State): boolean => state.savingProcess.error;
export const getLocalViewingPasses = (state: State): SaveViewingPassDto[] => state.localViewingPasses.data;
export const getIsOfflineSave = (state: State): boolean => state.savingProcess.offline;

