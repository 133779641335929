import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'src/app/common-components/button/button.module';

import { DropzoneComponent } from './dropzone.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  exports: [DropzoneComponent],
  declarations: [DropzoneComponent],
})
export class DropzoneModule { }
