<div
  class="pt-14 lg:pt-28 pb-12 lg:pb-20 px-11 lg:px-16 space-y-10"
>
  <div>
    <common-heading
      [label]="('list_item.delete' | translate) + '?'"
      align="center"
      class="font-bold text-lg pb-4"
    >
    </common-heading>
    <p class="text-md font-normal text-center text-gray-400">
      Sind Sie sicher, dass Sie das Dokument unwiderruflich löschen möchten?
    </p>
  </div>

  <div class="space-y-6 flex flex-column justify-center">
    <div class="space-y-2">
      <div>
        <button
          (click)="deleteClicked()"
          class="w-64 lg:w-72 h-11 font-source rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary disabled:bg-gray-300"
        >
          {{'list_item.delete' | translate}}
        </button>
      </div>
      <div>
        <button
          (click)="abortClicked()"
          class="w-64 lg:w-72 h-11 font-source rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary disabled:bg-gray-300"
        >
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</div>
