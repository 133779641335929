import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AccountLinkingService } from 'services/account-linking.service';
import {
  selectIsEdiReal,
  selectIsJustimmo,
  selectIsMicrosoft,
  selectIsOnOffice,
  selectIsRemax
} from 'src/app/store/settings/settings.selectors';
import {
  selectIsEdiRealTokenValid,
  selectIsJustimmoTokenValid,
  selectIsMicrosoftTokenValid,
  selectIsOnOfficeTokenValid,
  selectIsRemaxTokenValid
} from 'src/app/store/_global/global.selectors';
import { RestoreConnectionDialog } from 'src/app/common-components/restore-connection-dialog/restore-connection-dialog';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class IntegrationStatusService {
  constructor(
    private readonly _store: Store,
    private readonly _accountLinkingService: AccountLinkingService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) { }

  noReloadRoutePrefixes: string[] = [
    'viewing-passes',
    'exclusive-agreement',
    'plain-agreement',
    'handover-protocols',
    'buying-offer',
    'rental-offer'
  ]

  initializeSubscriptions(): void {
    this.handleIntegrationStatus(
      this._store.select(selectIsMicrosoft),
      this._store.select(selectIsMicrosoftTokenValid),
      'Microsoft',
      this._accountLinkingService.generateLink('microsoft', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsEdiReal),
      this._store.select(selectIsEdiRealTokenValid),
      'EDI-Real',
      this._accountLinkingService.generateLink('edi-real', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsJustimmo),
      this._store.select(selectIsJustimmoTokenValid),
      'Justimmo',
      this._accountLinkingService.generateLink('justimmo', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsOnOffice),
      this._store.select(selectIsOnOfficeTokenValid),
      'OnOffice',
      'https://calendly.com/mreikersdorfer/plattform-support',
    );
  }

  private handleIntegrationStatus(
    integration$: Observable<boolean>,
    tokenValid$: Observable<boolean>,
    systemName: string,
    restoreLink: string
  ): void {
    combineLatest([integration$, tokenValid$])
      .pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
        if (hasConnected && !connectionValid) {
          const currentRoute = this.router.url;

          if (this.shouldSkipDialog(currentRoute)) {
            return;
          } else {
            this.openDialog(systemName, restoreLink);
          }
        }
      });
  }

  private openDialog(externalSystem: string, restoreLink: string): void {
    const dialogRef = this.dialog.open(RestoreConnectionDialog, {
      data: { name: externalSystem, link: restoreLink },
    });
    dialogRef.afterClosed().toPromise().then(() => {
      dialogRef.close();
    });
  }

  /**
   * Determines if the current route matches one of the excluded patterns.
   * @param route The current route URL
   * @returns `true` if the route should skip the dialog, `false` otherwise
   */
  private shouldSkipDialog(route: string): boolean {
    // Create a dynamic regex pattern for excluded routes
    const dynamicRoutePattern = new RegExp(
      `\\/(${this.noReloadRoutePrefixes.join('|')})\\/[a-f0-9]{24}$`
    );
    return dynamicRoutePattern.test(route);
  }
}
