import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoLinkComponent } from './info-link.component';



@NgModule({
  declarations: [InfoLinkComponent],
  exports: [InfoLinkComponent],
  imports: [CommonModule],
})
export class InfoLinkModule {}
