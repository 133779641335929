import { PointGroup } from "signature_pad";

export interface BoundingBox {
  width: number;
  height: number;
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export const getSignatureBoundingBox = (data: PointGroup[]): BoundingBox => {
  if (!data || data.length === 0) {
    return { width: 0, height: 0, minX: 0, minY: 0, maxX: 0, maxY: 0 };
  }

  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  data.forEach(group => {
    group.points.forEach(point => {
      if (point.x < minX) minX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.x > maxX) maxX = point.x;
      if (point.y > maxY) maxY = point.y;
    });
  });

  const width = maxX - minX;
  const height = maxY - minY;

  return { width, height, minX, minY, maxX, maxY };
};

export const scaleSignature = (data: PointGroup[], scaleFactor: number, offsetX: number, offsetY: number): PointGroup[] => {
  return data.map(group => ({
    ...group,
    points: group.points.map(point => ({
      ...point,
      x: point.x * scaleFactor + offsetX,
      y: point.y * scaleFactor + offsetY,
    })),
  }));
};
