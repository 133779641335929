/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, tap, mergeMap, delay } from 'rxjs/operators';
import { SnackbarService } from 'services/snackbar.service';

import { ObjectApiFacade } from '../object-api.facade';
import * as DataActions from './actions';
import { ObjectDataFacade } from '@domains/object';
import { CustomerWithContact } from "@domains/customer";
import { Router } from "@angular/router";

@Injectable()
export class ObjectEffects {
  public loadObjectDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadObjectDetails),
      switchMap(({ objectId }) =>
        this._objectApiFacade.loadObjectDetails$(objectId).pipe(
          switchMap((value) => [
            DataActions.ObjectDetailsLoaded({ payload: value }),
          ]),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${objectId}`);
            return [DataActions.ObjectDetailsLoadingFailed()];
          })
        )
      )
    )
  );

  public loadPropertySharing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadPropertySharingData),
      switchMap(({ objectId }) =>
        this._objectApiFacade.loadPropertySharing$(objectId).pipe(
          switchMap((value) => {
            return [DataActions.PropertySharingDataLoaded({ payload: value })]
          }),
          catchError(() => [DataActions.PropertySharingDataLoadingFailed()])
        )
      )
    )
  );

  public loadPropertyActivities$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadPropertyActivites),
      switchMap(({ objectId }) =>
        this._objectApiFacade.loadPropertyActivities$(objectId).pipe(
          switchMap((value) => {
            return [DataActions.PropertyActivitiesLoaded({ payload: value })]
          }),
          catchError(() => [DataActions.PropertyActivitiesLoadingFailed()])
        )
      )
    )
  );

  public createPropertySharing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreatePropertySharing),
      switchMap(({ objectId, email }) =>
        this._objectApiFacade.createPropertySharing$(objectId, email).pipe(
          switchMap((isSuccessful) => {

            if (isSuccessful) {
              this._snackbarService.showSnackbar(
                'Objekt erfolgreich geteilt',
                'mat-primary',
                true
              );
            } else {
              this._snackbarService.showSnackbar(
                'Freigabe konnten nicht erstellt werden',
                'mat-warn',
                true
              );
            }

            return [DataActions.LoadPropertySharingData({ objectId }),]
          }),
          catchError(() => [DataActions.LoadPropertySharingData({ objectId })])
        )
      )
    ),
  );

  public deletePropertySharing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeletePropertySharing),
      switchMap(({ objectId, sharingId }) =>
        this._objectApiFacade.deletePropertySharing$(sharingId).pipe(
          switchMap((isSuccessful) => {

            if (isSuccessful) {
              this._snackbarService.showSnackbar(
                'Zugriff erfolgreich gelöscht',
                'mat-primary',
                true
              );
            } else {
              this._snackbarService.showSnackbar(
                'Zugriff konnte nicht gelöscht werden',
                'mat-warn',
                true
              );
            }

            return [DataActions.LoadPropertySharingData({ objectId })]
          }),
          catchError(() => [DataActions.LoadPropertySharingData({ objectId })])
        )
      )
    ),
  );

  public syncObjectDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SyncObjectDetails),
      switchMap(({ objectId }) =>
        this._objectApiFacade.syncObjectDetails$(objectId).pipe(
          switchMap((value) => [
            DataActions.ObjectDetailsLoaded({ payload: value }),
          ]),
          catchError(() => [DataActions.ObjectDetailsLoadingFailed()])
        )
      )
    )
  );

  public linkEdirealObjectDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LinkEdirealObject),
      switchMap(({ id, externalId }) =>
        this._objectApiFacade.linkEdirealObject$(id, externalId).pipe(
          switchMap((value) => {

            if (id !== value.id) {
              this._router.navigateByUrl(`/objects/${value.id}`);
            }
            return [DataActions.ObjectDetailsLoaded({ payload: value })]
          }),
          catchError(() => [DataActions.ObjectDetailsLoadingFailed()])
        )
      )
    )
  );

  public linkOnOfficeProperty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LinkOnOfficeProperty),
      switchMap(({ id, externalId }) =>
        this._objectApiFacade.linkOnOfficeProperty$(id, externalId).pipe(
          switchMap((value) => {
            if (id !== value.id) {
              this._router.navigateByUrl(`/objects/${value.id}`);
            }
            return [DataActions.ObjectDetailsLoaded({ payload: value })]
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Objekt konnte nicht verknüpft werden',
              'mat-warn',
              true
            );

            return [DataActions.ObjectDetailsLoadingFailed()]
          })
        )
      )
    )
  );

  public loadExternalObjectDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadExternalObjectDetails),
      switchMap(({ objectId }) =>
        this._objectApiFacade.loadExternalObjectDetails$(objectId).pipe(
          switchMap((value) => [
            DataActions.ExternalObjectDetailsLoaded({ payload: value }),
          ]),
          catchError(() => [DataActions.ExternalObjectDetailsLoadingFailed()])
        )
      )
    )
  );

  public loadCadastralCommunities$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCadastralCommunities),
      switchMap(() =>
        this._objectApiFacade.loadCadastralCommunities$().pipe(
          switchMap((value) => [
            DataActions.LoadCadastralCommunitiesSucceeded({ payload: value }),
          ]),
          catchError(() => [DataActions.LoadCadastralCommunitiesFailed()])
        )
      )
    )
  );

  public initObjects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.InitObjects),
      switchMap(() =>
        this._objectApiFacade.loadAvailableAgents$().pipe(
          tap((agents) => {
            this._objectDataFacade.setAvailableAgents(agents);

            agents.forEach((agent) => {
              this._objectDataFacade.loadObjectsByAgent(agent.name);
            });
          }),
        )),
    ),
    { dispatch: false }
  );

  public loadObjectsByAgent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadObjectsByAgent),
      mergeMap(({ agentName }) =>
        this._objectApiFacade.loadObjectsByAgent$(agentName).pipe(
          switchMap((value) => [
            DataActions.LoadObjectsByAgentSucceeded({ objects: value, agentName }),
          ]),
          catchError(() => [DataActions.LoadObjectsByAgentFailed({ agentName })]),
        ),
      ),
    )
  );

  public searchObjects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SearchObjects),
      switchMap(({ searchText }) =>
        this._objectApiFacade.searchObjects$(searchText).pipe(
          switchMap((value) => [
            DataActions.SearchObjectsSucceeded({ payload: value }),
          ]),
          catchError(() => [DataActions.SearchObjectsFailed()]),
        )
      )
    )
  );

  public createObject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateObject),
      switchMap(({ data }) => {
        return this._objectApiFacade.createObject$(data).pipe(
          switchMap((value) => [
            DataActions.CreateObjectSucceded({ payload: value }),
          ]),
          catchError(() => [DataActions.CreateObjectFailed()])
        );
      })
    )
  );

  public saveProperty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveProperty),
      switchMap(({ object }) => {
        const ownersWithoutCustomers =
          object.owners.map(
            (owner: CustomerWithContact) => {
              const {
                id,
                title,
                firstname,
                lastname,
                birthdate,
                contact,
                nationality,
                ...ownersWithoutCustomers
              } = owner;
              return {
                ...ownersWithoutCustomers,
                customerId: (owner as any).customerId,
              };
            }
          );

        const objectWithoutCustomers = {
          ...object,
          owners: ownersWithoutCustomers,
        };

        return this._objectApiFacade
          .saveProperty$(objectWithoutCustomers)
          .pipe(
            switchMap((property) => {
              this._router.navigateByUrl(`/objects/${property.id}`);

              return [DataActions.CreateObjectSucceded({ payload: property })];
            })
          )
      })
    )
  );

  public startDuplicationCheck$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.StartPropertiesDuplicationCheck),
      switchMap(() => {
        return this._objectApiFacade.runPropertyDuplicationCheck$().pipe(
          delay(1000),
          switchMap((value) => [
            DataActions.FinishPropertiesDuplicationCheck({ payload: { hasUpdate: value } }),
          ]),
          catchError(() => [DataActions.ResetPropertiesDuplicationCheck()])
        );
      })
    )
  );


  public updateOnOfficeProperty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateOnOfficeProperty),
      switchMap(({ propertyId }) =>
        this._objectApiFacade.updateOnOfficeProperty$(propertyId).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Objekt erfolgreich aktualisiert',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Objekt konnte nicht aktualisiert werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public createObjectFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.CreateObjectFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt konnte nicht erstellt werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public createObjectSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.CreateObjectSucceded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt erfolgreich erstellt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updateObject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateObject),
      switchMap(({ data }) => {
        return this._objectApiFacade.updateObject$(data).pipe(
          switchMap((value) => [
            DataActions.UpdateObjectSucceded({ payload: value }),
          ]),
          catchError(() => [DataActions.UpdateObjectFailed()])
        );
      })
    )
  );


  public updatePropertyPartially$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdatePropertyPartially),
      switchMap(({ data }) => {
        return this._objectApiFacade.updatePropertyPartially$(data).pipe(
          switchMap((value) => [
            DataActions.UpdateObjectSucceded({ payload: value }),
          ]),
          catchError(() => [DataActions.UpdateObjectFailed()])
        );
      })
    )
  );

  public updateObjectFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.UpdateObjectFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt konnte nicht aktualisiert werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updateObjectSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.UpdateObjectSucceded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt erfolgreich aktualisiert',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updateObjectStatus$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateObjectStatus),
      switchMap(({ objectId, objectStatus }) => {
        return this._objectApiFacade
          .updateObjectStatus$(objectId, objectStatus)
          .pipe(
            switchMap((value) => [
              DataActions.UpdateObjectStatusSucceded({ payload: value }),
            ]),
            catchError(() => [DataActions.UpdateObjectStatusFailed()])
          );
      })
    )
  );

  public updateObjectStatusFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.UpdateObjectStatusFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt Status konnte nicht aktualisiert werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updateObjectStatusSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.UpdateObjectStatusSucceded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekt Status erfolgreich aktualisiert',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public deleteObjects$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteObjects),
      switchMap(({ objectIds }) =>
        this._objectApiFacade.deleteObjects$(objectIds).pipe(
          switchMap((value) => [
            DataActions.DeleteObjectsSucceeded({ payload: value }),
          ]),
          catchError(() => [DataActions.DeleteObjectsFailed])
        )
      )
    )
  );

  public deleteObjectsFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.DeleteObjectsFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekte konnten nicht gelöscht werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public deleteObjectsSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.DeleteObjectsSucceeded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Objekte erfolgreich gelöscht',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public triggerExternalObjectLoadSuccessSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.TriggerExternalObjectsLoadingSucceeded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Externe Daten werden geladen',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  triggerExternalObjectLoadErrorSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.TriggerExternalObjectLoadingFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Fehler beim Laden der externen Daten',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public loadCommonPropertyExtensionConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCommonPropertyExtensionConfig),
      switchMap(() =>
        this._objectApiFacade.loadCommonPropertyExtensionConfig$().pipe(
          switchMap((value) => [
            DataActions.CommonPropertyExtensionConfigLoaded({ payload: value }),
          ]),
          catchError(() => [DataActions.CommonPropertyExtensionConfigLoadingFailed()])
        )
      )
    )
  );

  public loadOnOfficeDocumentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadOnOfficeDocumentConfig),
      switchMap(() =>
        this._objectApiFacade.loadOnOfficeDocumentConfig$().pipe(
          switchMap((value) => [
            DataActions.OnOfficeDocumentConfigLoaded({ payload: value }),
          ]),
          catchError(() => [DataActions.OnOfficeDocumentConfigLoadingFailed()])
        )
      )
    )
  );

  public syncOnOfficeDocumentConfig$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SyncOnOfficeDocumentConfig),
      switchMap(() =>
        this._objectApiFacade.syncOnOfficeDocumentConfig$().pipe(
          switchMap((value) => {
            this._snackbarService.showSnackbar(
              'Externe Daten werden geladen',
              'mat-primary',
              true
            );

            return [DataActions.OnOfficeDocumentConfigLoaded({ payload: value })];
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Fehler beim Sync der externen Daten',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _objectApiFacade: ObjectApiFacade,
    private readonly _objectDataFacade: ObjectDataFacade,
    private readonly _router: Router,
  ) { }
}
