import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ownerPortalSelectors, OwnerPortalFeatureState } from './store';
import { OwnerPortalActions } from './store/action-types';
import { OwnerPortalApiFacade } from './owner-portal-api.facade';
import { Observable } from 'rxjs';
import { CreateOwnerPortal, OwnerPortal, OwnerPortalEmailVariables } from './owner-portal.model';

@Injectable({ providedIn: 'root' })
export class OwnerPortalDataFacade {
  ownerPortals$ = this._store$.select(ownerPortalSelectors.ownerPortals);
  ownerPortalsLoading$ = this._store$.select(ownerPortalSelectors.ownerPortalsLoading);
  ownerPortalDetails$ = this._store$.select(ownerPortalSelectors.ownerPortalDetails);
  ownerPortalDetailsLoading$ = this._store$.select(ownerPortalSelectors.ownerPortalDetailsLoading);
  savingProcessLoading$ = this._store$.select(ownerPortalSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(ownerPortalSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<OwnerPortalFeatureState>,
    private readonly _ownerPortalApiFacade: OwnerPortalApiFacade,
  ) { }

  public loadOwnerPortals(objectId: string): void {
    this._store$.dispatch(OwnerPortalActions.LoadOwnerPortals({ objectId }));
  }

  public loadOwnerPortalDetails(id: string): void {
    this._store$.dispatch(OwnerPortalActions.LoadOwnerPortalDetails({ id }));
  }

  public deleteOwnerPortal(id: string): void {
    this._store$.dispatch(OwnerPortalActions.DeleteOwnerPortal({ id }));
  }

  public saveOwnerPortal(data: CreateOwnerPortal, isFinal: boolean = false): void {
    this._store$.dispatch(OwnerPortalActions.SaveOwnerPortal({ data, isFinal }));
  }

  public sendOwnerPortalEmail(objectId: string, id: string, emailData: any): void {
    const emailPayload = { objectId, id, emailData };
    this._store$.dispatch(OwnerPortalActions.SendOwnerPortalEmail(emailPayload));
  }

  public createOwnerPortal$(data: CreateOwnerPortal): Observable<OwnerPortal> {
    return this._ownerPortalApiFacade.saveOwnerPortal$(data);
  }

  public getOwnerPortalDetails$(id: string): Observable<OwnerPortal> {
    return this._ownerPortalApiFacade.loadOwnerPortalDetails$(id);
  }

  public getOwnerPortalEmailVariables$(id: string, customers: any[]): Observable<OwnerPortalEmailVariables> {
    return this._ownerPortalApiFacade.getOwnerPortalEmailVariables$(id, customers);
  }

  public mergeOwnerDocuments$(id: string, urls: string[]): Observable<boolean> {
    return this._ownerPortalApiFacade.mergeOwnerDocuments$(id, urls);
  }
}
