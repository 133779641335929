import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'email-recipients',
  template: `
  <div class="flex flex-row">
    <div class="w-12 flex justify-center items-center">{{label}} :</div>
    <mat-form-field class="w-full">
      <mat-chip-list #recipientsList [formControl]="control">
        <mat-chip *ngFor="let recipient of control.value" (removed)="remove(recipient)">
          {{recipient}}
          <button matChipRemove>
            <mat-icon class="chip-cancel">cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          [matChipInputFor]="recipientsList"
          [matChipInputAddOnBlur]="true"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
        >
      </mat-chip-list>
    </mat-form-field>
  </div>
  `,
  styles: [
    `
      .chip-cancel {
        margin-top: -2px;
        margin-left: -1px;
      }

      ::ng-deep .mat-chip-list .mat-chip-list-wrapper {
        margin-right: 0 !important;
      }
    `,
  ],
})
export class EmailRecipientsComponent {
  @Input() label!: string;
  @Input() control!: FormControl;

  readonly separatorKeysCodes = [ENTER, SPACE, COMMA, TAB] as const;

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.control.setValue([...this.control.value, value.trim()]);
      this.control.updateValueAndValidity();
    }

    if (input) {
      input.value = '';
    }
  }

  remove(recipient: string): void {
    const index = this.control.value.indexOf(recipient);

    if (index >= 0) {
      this.control.value.splice(index, 1);
      this.control.updateValueAndValidity();
    }
  }
}
