<common-wizard-page-heading [title]="title"></common-wizard-page-heading>
<form [formGroup]="formGroup" class="flex flex-col">
  <ng-container *ngIf="{ customers: customers$ | async } as sync">
    <mat-form-field [appearance]="appearance">
      <mat-label>Firmenwortlaut</mat-label>
      <input formControlName="companyNumber" matInput required [matAutocomplete]="companyNumber" />
      <mat-autocomplete #companyNumber="matAutocomplete">
        <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
        <mat-option class="customer" *ngFor="let customer of sync.customers" [value]="customer.companyNumber"
          (onSelectionChange)="onCustomerSelected(customer)">
          <customer-preview
            [customer]="customer"
            [companyNumberSearchTerm]="sanitizedCompanyNumber"
          ></customer-preview>
        </mat-option>
        <mat-option class="add-new-option" value="" (onSelectionChange)="onAddNewCustomer($event)">
          <div class="border-t-2 text-primary font-bold">
            + Neuen Kontakt erfassen
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field [appearance]="appearance">
      <mat-label>Firmenbuchnummer</mat-label>
      <input formControlName="companyRegister" matInput/>
    </mat-form-field>

    <mat-form-field [appearance]="appearance">
      <mat-label>{{"customer_form.uid_number" | translate}}</mat-label>
      <input formControlName="uidNumber" matInput/>
    </mat-form-field>

    <common-hint-with-link class="mb-4" *ngIf="this.showIdenticalCustomerWarning"
      message="Ein ähnlicher Kunde besteht bereits." clickableMessage="Jetzt ansehen"
      (clickableMessageClicked)="showIdenticalCustomers()"></common-hint-with-link>

    <mat-select #identicalCustomersSelect id="identicalCustomersSelect" class="col-span-4">
      <mat-option class="headline" disabled>Bestehende Kunden</mat-option>
      <mat-option class="customer" *ngFor="let customer of identicalCustomers"
        (onSelectionChange)="onCustomerSelected(customer)">
        <customer-preview
          [customer]="customer"
          [companyNumberSearchTerm]="sanitizedCompanyNumber"
        ></customer-preview>
      </mat-option>
      <mat-option class="add-new-option" (onSelectionChange)="onAddNewCustomer($event)">
        <div class="border-t-2 text-primary font-bold">
          Mit Anlage eines neuen Kunden fortfahren
        </div>
      </mat-option>
    </mat-select>
  </ng-container>

  <ng-container formGroupName="contact">
    <ng-container formGroupName="address">
      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>Straße</mat-label>
          <input formControlName="streetName" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance">
          <mat-label>Hausnummer</mat-label>
          <input formControlName="buildingNumber" matInput />
        </mat-form-field>
      </div>

      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>{{"address.stairway" | translate}}</mat-label>
          <input formControlName="stairway" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>{{"address.door_number" | translate}}</mat-label>
          <input formControlName="doorNumber" matInput />
        </mat-form-field>
      </div>

      <div class="flex gap-x-4 flex-col md:flex-row w-full">
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>Stadt</mat-label>
          <input formControlName="city" matInput />
        </mat-form-field>
        <mat-form-field [appearance]="appearance" class="w-full">
          <mat-label>PLZ</mat-label>
          <input formControlName="zipCode" matInput />
        </mat-form-field>
      </div>

      <mat-form-field [appearance]="appearance">
        <mat-label>Land</mat-label>
        <input formControlName="country" matInput />
      </mat-form-field>
    </ng-container>
  </ng-container>

  <ng-container formGroupName="contact">
    <div class="flex gap-x-4 flex-col md:flex-row w-full">
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Telefonnummer</mat-label>
        <input formControlName="phone" matInput />
      </mat-form-field>
      <mat-form-field [appearance]="appearance" class="w-full">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput required />
        <mat-error *ngIf="isEmailValid">Bitte eine gültige Email Adresse eingeben</mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <common-hint
    *ngIf="showUpdateWarning"
    message="Änderungen am Kunden überschreiben die vorherigen Informationen über diesen Kunden."
  ></common-hint>

  <div class="pl-8 pt-8">
    <business-private-customers [formArray]="formArray" [showPep]="showPep"></business-private-customers>
  </div>
</form>
