import {Component} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";
import {TracingService} from "services/tracing.service";
import {RouterLinkActive, RouterLinkWithHref} from "@angular/router";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'banner-block-component',
  template: `

    <div class="flex items-center justify-between mb-2 cursor-pointer" (click)="toggleVideo()">
      <div class="flex items-center">
        <img [src]="imageSource" [alt]="text" class="block w-6 pr-2" />
        <h3 class="font-source leading-5 text-sm sm:text-base font-bold">{{ text }}</h3>
      </div>
      <button class="arrow-btn">
        <mat-icon>{{ isVideoVisible ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>

    <div *ngIf="isVideoVisible" class="video-container">
      <iframe
        width="240"
        height="130"
        src="https://www.youtube.com/embed/videoseries?si=Q_KtdF_0KYgnGJDz&amp;list=PLwA19okU0p8lQ9OZF64u8zuQvJJviSO_l"
        title="PROPUP Erste Schritte"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen>
      </iframe>
    </div>


  `,
  styles: [`

    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      border-radius: 8px;
      margin-top: 8px;
    }

    .video-container iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .arrow-btn {
      background: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    mat-icon {
      font-size: 24px;
      color: #555;
    }
  `],
  standalone: true,
  imports: [CommonModule, MatCardModule, TranslationComponentModule, RouterLinkActive, RouterLinkWithHref, NgOptimizedImage, MatExpansionModule, MatIconModule],
})

export class BannerBlockComponent {
  link = 'https://www.youtube.com/playlist?list=PLwA19okU0p8lQ9OZF64u8zuQvJJviSO_l'
  imageSource = 'assets/icons/autoplay.svg';
  text = 'Video Guide';
  isVideoVisible = true;

  constructor() {}

  toggleVideo() {
    this.isVideoVisible = !this.isVideoVisible;
  }
}
