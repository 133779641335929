import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'signature-customer-panel',
  templateUrl: './panel.component.html',
  styles: [],
})
export class SignatureCustomerPanelComponent {
  @Input() formGroup!: FormGroup;
  @Input() customerName!: string;
  @Input() index = 0;
  @Input() expand = true;

  @Output() openedStep = new EventEmitter<number>();
  @Output() remove = new EventEmitter<number>();

  setCurrentStep(): void {
    this.openedStep.emit(this.index);
  }

  removeStep(): void {
    this.remove.emit(this.index);
  }

}
