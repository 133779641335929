import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { getAddressAsString } from '../../address-field-autocomplete/formatting';
import { Object } from '@domains/object';
import { StaticFeatureToggles } from 'src/environments/static-feature-toggles';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { CustomerDataFacade, CustomerFormService, CustomerWithContact } from '@domains/customer';
import { BusinessCustomer, BusinessCustomerDataFacade, BusinessCustomerFormService } from '@domains/business-customer';
import { EMPTY, Subscription, forkJoin, of } from 'rxjs';
import { defaultIfEmpty, finalize, switchMap, tap } from 'rxjs/operators';
import {TranslationService} from "../../../i18n/TranslationService";

@Component({
  selector: 'common-add-customers-screen',
  templateUrl: './add-customers-screen.component.html',
  styleUrls: ['add-customers-screen.component.scss'],
})
export class AddCustomersScreenComponent {
  @Input() kind = 'Besichtigung';
  @Input() customerName = 'Verkäufer';
  @Input() customerTypeSelectorText = '';
  @Input() nextButtonText = this._translationService.instant('general.save');
  @Input() showBackButton = true;
  @Input() object!: Object;

  @Input() nextButtonLoading = false;

  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<{
    customerType: CustomerType,
    customers: CustomerWithContact[],
    businessCustomer: BusinessCustomer | null,
  }>();

  customerType = this._fb.control(CustomerType.Private);
  customers = this._fb.array([]);
  businessCustomer = this._fb.group({
    data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
    customers: this._businessCustomerFormService.createPrivateCustomers(),
  });

  private subscription = new Subscription();

  get isSaveDisabled(): boolean {
    return (this.isCustomerBusiness && this.businessCustomer.invalid) || (this.isCustomerPrivate && this.customers.invalid);
  }

  get isCustomerBusiness(): boolean {
    return this.customerType.value === CustomerType.Business;
  }

  get isCustomerPrivate(): boolean {
    return this.customerType.value === CustomerType.Private;
  }

  get address(): string {
    return this.object ? getAddressAsString(this.object.address) : '';
  }

  get feature(): StaticFeatureToggles {
    return environment.staticToggles;
  }

  get businessCustomerFormGroup(): FormGroup {
    return this.businessCustomer.get('data') as FormGroup;
  }

  get privateCustomersFormArray(): FormArray {
    return this.businessCustomer.get('customers') as FormArray;
  }

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _customerDataFacade: CustomerDataFacade,
    private readonly _businessCustomerDataFacade: BusinessCustomerDataFacade,
    private _translationService: TranslationService,
  ) { }


  save(): void {
    if (this.nextButtonLoading) {
      return;
    }

    if (this.isCustomerBusiness && this.businessCustomer.invalid) {
      console.warn('Form Invalid!');
      this.businessCustomer.markAsDirty();
      this.businessCustomer.markAllAsTouched();

      return;
    }

    if (this.isCustomerPrivate && this.customers.invalid) {
      console.warn('Form Invalid!');
      this.customers.markAsDirty();
      this.customers.markAllAsTouched();

      return;
    }

    this.nextButtonLoading = true;
    let customers: CustomerWithContact[];

    if (this.isCustomerBusiness) {
      customers = this.businessCustomer.value.customers;
    } else {
      customers = this.customers.value as CustomerWithContact[];
    }

    const saveCustomersRequest = customers?.length ?
      customers.map((c: CustomerWithContact) =>
        this._customerDataFacade.saveCustomer$(this._customerFormService.convertToFormGroup(c))
      ) : EMPTY;


    this.subscription.add(
      forkJoin(saveCustomersRequest).pipe(defaultIfEmpty([])).pipe(
        // @ts-ignore
        switchMap((savedCustomers: CustomerWithContact[]) => {
          const customerIds = savedCustomers.map((item: any) => item?.id).filter(Boolean);

          if (this.isCustomerPrivate) {
            this.next.emit({
              customerType: this.customerType.value as CustomerType,
              businessCustomer: null,
              customers: savedCustomers
            });

            return of(null);
          }


          if (this.isCustomerBusiness) {
            const ids = new FormArray(customerIds.map((item: string) => this._fb.control(item)));
            const businessCustomerDataFromGroup = this.businessCustomer.get('data') as FormGroup;
            businessCustomerDataFromGroup.setControl('customers', ids);

            return this._businessCustomerDataFacade.saveCustomer$(businessCustomerDataFromGroup).pipe(
              tap((businessCustomer) => {
                this.next.emit({
                  customerType: this.customerType.value as CustomerType,
                  businessCustomer,
                  customers: savedCustomers
                });
              }),
            );
          }
        }),
        finalize(() => {
          this.nextButtonLoading = false;
        }),
      ).subscribe()
    );

  }

  goBack(): void {
    this.back.emit();
  }
}
