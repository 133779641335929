<div class="space-y-2">
  <div class="flex sm:hidden lg:flex">
    <ng-content select="[goBackContent]"></ng-content>
    <common-heading label="{{ kind }} von {{ address }}" size="medium"></common-heading>
  </div>
</div>


<div class="flex flex-col my-8">
  <customer-type-selector [control]="customerType"
    [text]="customerTypeSelectorText"></customer-type-selector>

  <business-customer-page *ngIf="isCustomerBusiness" [formGroup]="businessCustomerFormGroup"
    [formArray]="privateCustomersFormArray"></business-customer-page>

  <signature-new-customers-page *ngIf="isCustomerPrivate" [formArray]="customers"
    [customerName]="customerName"></signature-new-customers-page>

  <div class="flex justify-between">
    <div>
      <button *ngIf="showBackButton" (click)="goBack()"
        class="font-source rounded-xl text-lg text-primary py-2 mr-2">Zurück</button>
    </div>

    <button (click)="save()" [disabled]="isSaveDisabled"
      class="flex justify-center items-center font-source rounded-xl text-lg text-white font-bold py-2 px-4 sm:mb-2 lg:mb-0 bg-primary disabled:bg-secondary-dark disabled:bg-gray-300 mr-2">
      <mat-spinner *ngIf="nextButtonLoading" class="mr-2 spinner" diameter="20"></mat-spinner>
      {{ nextButtonText }}
    </button>
  </div>
</div>
