import { ActionsSubject, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { hopSelectors, HandoverProtocolFeatureState } from './store';
import { HandoverProtocolActions } from './store/action-types';
import { HandoverProtocolApiFacade } from './handover-protocol-api.facade';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { DocumentService } from 'services/document.service';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { catchError, concatMap, first, map, switchMap, tap, toArray } from 'rxjs/operators';
import {
  SaveHandoverProtocolDto,
  HandoverProtocolSignatureCreation,
  HandoverProtocol,
  LocalHandoverProtocol,
} from './handover-protocol.model';
import { BusinessCustomerDataFacade } from '@domains/business-customer';
import { CustomerDataFacade } from '@domains/customer';
import { CustomerType } from '@common/components';
import { LOCAL_DOCUMENT_ID_PREFIX } from 'src/app/offline/local-database';
import { ofType } from '@ngrx/effects';

import { LocalHandoverProtocolService } from './local-handover-protocol.service';

@Injectable({ providedIn: 'root' })
export class HandoverProtocolDataFacade {
  handoverProtocols$ = this._store$.select(hopSelectors.handoverProtocols);
  handoverProtocolsLoading$ = this._store$.select(hopSelectors.handoverProtocolsLoading);
  handoverProtocolDetails$ = this._store$.select(hopSelectors.handoverProtocolDetails);
  handoverProtocolDetailsLoading$ = this._store$.select(hopSelectors.handoverProtocolDetailsLoading);
  savingProcessLoading$ = this._store$.select(hopSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(hopSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<HandoverProtocolFeatureState>,
    private readonly _hopApiFacade: HandoverProtocolApiFacade,
    private readonly _localHandoverProtocolService: LocalHandoverProtocolService,
    private readonly _customerDataFacade: CustomerDataFacade,
    private readonly _businessCustomerDataFacade: BusinessCustomerDataFacade,
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly actionsListener$: ActionsSubject,
  ) { }


  public loadHandoverProtocols(objectId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocols({ objectId }));
  }

  public getLocalHandoverProtocols$(objectId: string) {
    return this._localHandoverProtocolService.getHandoverProtocolsByPropertyId$(objectId);
  }

  public loadHandoverProtocolsByCustomer(customerId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocolsByCustomer({ customerId }));
  }

  public loadHandoverProtocolDetails(objectId: string, hopId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocolDetails({ objectId, hopId }));
  }

  public clearHandoverProtocolDetails(): void {
    this._store$.dispatch(HandoverProtocolActions.ClearHandoverProtocolDetails());
  }

  public saveHandoverProtocol(handoverProtocol: SaveHandoverProtocolDto, isFinal: boolean = false, shouldPrint: boolean = false): void {
    this._store$.dispatch(HandoverProtocolActions.SaveHandoverProtocol({ handoverProtocol, isFinal, shouldPrint }));
  }

  public sendHandoverProtocolEmail(objectId: string, handoverProtocolId: string, emailData: any): void {
    this._store$.dispatch(HandoverProtocolActions.SendHandoverProtocolEmail({
      objectId,
      handoverProtocolId,
      emailData,
    }));
  }

  public signAndCompleteHandoverProtocol(handoverProtocolSignature: HandoverProtocolSignatureCreation): void {
    this._store$.dispatch(HandoverProtocolActions.SignAndCompleteHandoverProtocol({ handoverProtocolSignature }));
  }

  public draftSignHandoverProtocol(
    handoverProtocolSignature: HandoverProtocolSignatureCreation,
    openEmailDialog = true,
  ): void {
    this._store$.dispatch(HandoverProtocolActions.DraftSignHandoverProtocol({
      handoverProtocolSignature,
      openEmailDialog,
    }));
  }

  public failedCreateAndUploadHandoverProtocolDocument(): void {
    this._store$.dispatch(HandoverProtocolActions.CreateAndUploadHandoverProtocolDocumentFailed());
  }

  public loadLocalHandoverProtocolDetails(localId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadLocalHandoverProtocolDetails({ id: localId }));
  }

  public deleteHandoverProtocol(hopId: string, objectId: string): void {
    this._store$.dispatch(HandoverProtocolActions.DeleteHandoverProtocol({ hopId, objectId }));
  }

  public getHandoverProtocolEmailVariables$(objectId: string, handoverProtocolId: string): Observable<any> {
    const agentCompany = getAgentCompany(this._authService);
    return this._hopApiFacade.getHandoverProtocolEmailVariables$(objectId, handoverProtocolId, agentCompany);
  }

  public saveHandoverProtocol$(hop: SaveHandoverProtocolDto): Observable<HandoverProtocol> {
    return this._hopApiFacade.saveHandoverProtocol$(hop);
  }

  public compressFiles$(objectId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._hopApiFacade.compressFiles$(objectId, filePaths, fileNames);
  }

  public getHandoverProtocolDetails$(objectId: string, hopId: string): Observable<HandoverProtocol> {
    return this._hopApiFacade.loadHandoverProtocolDetails$(objectId, hopId);
  }

  public createHandoverProtocolDocument$(objectId: string, hopId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._hopApiFacade.createHandoverProtocolDocument$(objectId, hopId, agentCompany);
  }

  public getSignedHOPsAmount$(objectId: string): Observable<{ amount: number }> {
    return this._hopApiFacade.getSignedHOPsAmount$(objectId);
  }

  public saveHandoverProtocolOffline$(handoverProtocol: any): Observable<any> {
    return this._localHandoverProtocolService.save$(handoverProtocol);
  }

  public saveFullHandoverProtocolOffline$(data: HandoverProtocol): Observable<void> {
    return this._localHandoverProtocolService.saveFullModel$(data);
  }

  public downloadSignedHOPsDocs$(objectId: string): Observable<string> {
    return this._hopApiFacade.getSignedHOPsDocsUrl$(objectId).pipe(
      map(data => data.storageUrl),
      tap((storageUrl) => {
        if (storageUrl) {
          const lastIndex = storageUrl.lastIndexOf('/');
          const a = document.createElement('a');
          a.download = storageUrl.substring(lastIndex + 1);
          a.href = storageUrl;
          document.body.appendChild(a);
          a.click();
        }
      })
    );
  }

  public showHandoverProtocolDocument(objectId: string, hopId: string): void {
    this.createHandoverProtocolDocument$(objectId, hopId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadHandoverProtocolDocument();
        throw err;
      }),
    ).subscribe();
  }

  syncLocalHandoverProtocols$(objectId: string): Observable<(string | null)[]> {
    return this._localHandoverProtocolService.getHandoverProtocolsByPropertyId$(objectId).pipe(
      first(),
      switchMap((handoverProtocols) => {
        if (!handoverProtocols || handoverProtocols.length === 0) {
          return of([]);
        }

        return from(handoverProtocols).pipe(
          concatMap((hop) => this.syncLocalHandoverProtocol$(hop.id).pipe(
            catchError((error) => {
              console.error(`Failed to sync handover protocol with ID ${hop.id}:`, error);
              return of(null);
            })
          )),
          toArray(),
        );
      }),
      catchError((error) => {
        console.error('Failed to retrieve offline handover protocols:', error);
        return of([]);
      })
    );
  }

  syncLocalHandoverProtocol$(handoverProtocolId: string): Observable<string | null> {
    return this._localHandoverProtocolService.get$(handoverProtocolId).pipe(
      switchMap((handoverProtocol: LocalHandoverProtocol) => {
        if (!handoverProtocol) {
          return of(null);
        }

        let transferorSync$: Observable<string[] | string | null> = of(null);
        let transfereeSync$: Observable<string[] | string | null> = of(null);

        const isBusinessTransferor = handoverProtocol.transferorType === CustomerType.Business;
        const isBusinessTransferee = handoverProtocol.transfereeType === CustomerType.Business;

        if (isBusinessTransferor) {
          transferorSync$ = this._businessCustomerDataFacade.syncLocalBusinessCustomer$(handoverProtocol.businessTransferor);
        } else {
          transferorSync$ = this._customerDataFacade.syncLocalCustomers$(handoverProtocol.transferors);
        }

        if (isBusinessTransferee) {
          transfereeSync$ = this._businessCustomerDataFacade.syncLocalBusinessCustomer$(handoverProtocol.businessTransferee);
        } else {
          transfereeSync$ = this._customerDataFacade.syncLocalCustomers$(handoverProtocol.transferees);
        }

        return combineLatest([transferorSync$, transfereeSync$]).pipe(
          switchMap(([updatedTransferor, updatedTransferee]) => {
            // Assign back updated values depending on types
            if (isBusinessTransferor) {
              handoverProtocol.businessTransferor = updatedTransferor as string || '';
              handoverProtocol.transferors = [];
            } else {
              handoverProtocol.transferors = (updatedTransferor as string[]) || [];
              handoverProtocol.businessTransferor = '';
            }

            if (isBusinessTransferee) {
              handoverProtocol.businessTransferee = updatedTransferee as string || '';
              handoverProtocol.transferees = [];
            } else {
              handoverProtocol.transferees = (updatedTransferee as string[]) || [];
              handoverProtocol.businessTransferee = '';
            }

            // Reset ID if it's local
            if (handoverProtocol.id.startsWith(LOCAL_DOCUMENT_ID_PREFIX)) {
              handoverProtocol.id = '';
            }

            const {
              transfereesData,
              businessTransfereeData,
              transferorsData,
              businessTransferorData,
              localSignatures,
              ...hopToSave
            } = handoverProtocol;

            return this.saveHandoverProtocol$(hopToSave).pipe(
              switchMap((createdHOP) =>
                this._localHandoverProtocolService.delete$(handoverProtocolId).pipe(
                  map(() => createdHOP.id as string)
                )
              ),
              catchError((error) => {
                console.error('Failed to save and delete local handover protocol:', error);
                return of(null);
              })
            );
          }),
          catchError((error) => {
            console.error('Failed to sync customers:', error);
            return of(null);
          })
        );
      }),
      catchError((error) => {
        console.error('Failed to retrieve handover protocol:', error);
        return of(null);
      })
    );
  }

  public duplicateHandoverProtocol(hopId: string, objectId: string): void {
    this._store$.dispatch(HandoverProtocolActions.DuplicateHandoverProtocol({ hopId, objectId }));
  }


  handoverProtocolsLoadingFinished$() {
    return this.actionsListener$.pipe(
      ofType(HandoverProtocolActions.HandoverProtocolsLoaded)
    );
  }

  handoverProtocolsLoadingFailed$() {
    return this.actionsListener$.pipe(
      ofType(HandoverProtocolActions.HandoverProtocolsLoadingFailed)
    );
  }
}
