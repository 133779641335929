import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DeleteDocumentDialogComponent } from "./delete-document-dialog.component";
import { HeadingsModule } from "../headings/headings.module";
import { TranslationComponentModule } from "../../i18n/TranslationComponentModule";

@NgModule({
  declarations: [DeleteDocumentDialogComponent],
  imports: [CommonModule, HeadingsModule, TranslationComponentModule],
  exports: [DeleteDocumentDialogComponent]
})
export class DeleteDocumentDialogModule { }

export * from "./delete-document-dialog.component";