import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged, filter,
  finalize,
  first,
  map,
  startWith,
  switchMap, take,
  tap,
} from 'rxjs/operators';
import { selectCountries } from 'src/app/store/_global/global.selectors';
import _ from 'lodash';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Country } from '@domains/viewing-pass';
import { CustomerDataFacade, CustomerFormService, CustomerWithContact } from '@domains/customer';
import { initializeCountries } from "../../../store/_global/global.actions";

const pepInfo = `
Erläuterung: Eine politisch exponierte Person gemäß § 365n GewO 1994 ist eine natürliche Person, die wichtige öffentliche Ämter ausübt / ausgeübt hat, wie:
\n* Staats-, Regierungschefs, Minister, deren Stellvertreter, Staatssekretäre; Mitglieder von: Parlamenten, Gesetzgebungsorganen, Führungsgremien politischer Parteien, letztinstanzlichen Gerichten, Rechnungshöfen; Leitungs- und Aufsichtsorgane von Zentralbanken, staatseigenen Unternehmen, internationalen Organisation sowie Botschafter, Geschäftsträger, hochrangige Offiziere.
\n* all deren Familienmitglieder (Ehepartner, Kinder, Schwiegerkinder, Eltern und all den de facto gleichgestellten Personen) sowie alle Personen mit de facto engen Geschäftsbeziehungen zu ihnen.
`

type LoadingOnField = 'firstName' | 'lastName';

@Component({
  selector: 'customers-customer-page',
  templateUrl: 'customer-page.component.html',
  styleUrls: ['customer-page.component.scss'],
})
export class CustomerPageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = '';
  @Input() formGroup!: FormGroup;
  @Input() isOptional = false;
  @Input() showPep = false;
  @Input() isBusiness = false;
  @Input() showMoneyLaundring = false;
  lastSelectedValues: { [key: string]: any } = {};

  @ViewChild('identicalCustomersSelect')
  identicalCustomersSelect!: MatSelect;

  appearance: MatFormFieldAppearance = 'outline';
  countries$: Observable<Country[]> = this.store.select(selectCountries);
  maxDate: Date = moment().subtract(18, 'years').toDate();
  pepTooltip = pepInfo;

  loading = false;
  searchStartTime = 0;
  loadingField: LoadingOnField = 'lastName';

  customers$!: Observable<CustomerWithContact[]>;
  customers: CustomerWithContact[] = [];
  imageType: string = 'customerIDImage';

  private _subscription = new Subscription();

  private initialCurrentCustomerInfo: Pick<
    CustomerWithContact,
    'title' | 'firstname' | 'lastname' | 'contact' | 'pep' | 'isConsumer'
  > = {
      title: '',
      firstname: '',
      lastname: '',
      isConsumer: true,
      contact: {
        address: {
          country: '',
          city: '',
          zipCode: '',
          streetName: '',
          buildingNumber: '',
          doorNumber: '',
          stairway: '',
          longitude: 0,
          latitude: 0,
        },
      },
      pep: {
        isPep: null,
        wasPepLastYear: null,
        isFamilyMemberPep: null,
        isKnownToBeCloseToPep: null,
        firstName: '',
        lastName: '',
      },
    };
  private currentCustomerAsPersisted = this.initialCurrentCustomerInfo;

  constructor(
    private store: Store,
    private _customerDataFacade: CustomerDataFacade,
    private _customerFormService: CustomerFormService
  ) { }

  ngOnInit(): void {
    this._subscription.add(
      this.pepFormGroup.controls.isPep.valueChanges.subscribe(() => this._updatePepValidity())
    );

    this._subscription.add(
      this.pepFormGroup.controls.wasPepLastYear.valueChanges.subscribe(() => this._updatePepValidity())
    );

    this._subscription.add(
      this.pepFormGroup.controls.isFamilyMemberPep.valueChanges.subscribe(() => this._updatePepValidity())
    );

    this._subscription.add(
      this.pepFormGroup.controls.isKnownToBeCloseToPep.valueChanges.subscribe(() => this._updatePepValidity())
    );
    this._subscription.add(
      this._customerDataFacade.getCountries$()
        .pipe(first())
        .subscribe((countries: Country[]) => {
          this.store.dispatch(initializeCountries({ countries }));
        })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnChanges(): void {
    this.customers$ = this.buildSearchStream(
      this.firstnameControl.valueChanges,
      this.lastnameControl.valueChanges
    ).pipe(
      debounceTime(300),
      filter(([firstname, lastname]) =>
        (firstname?.trim()?.length >= 3 || lastname?.trim()?.length >= 3)
      ),
      distinctUntilChanged(),
      tap(() => {
        this.loading = true;
        this.searchStartTime = performance.now(); // Start time
      }),
      switchMap(([firstname, lastname]) =>
        this._customerDataFacade.searchCustomer$(firstname ?? '', lastname ?? '').pipe(
          take(1),
          map((customers) => customers.slice(0, 50)),
          tap((c) => {
            this.customers = c;
            const elapsedTime = (performance.now() - this.searchStartTime).toFixed(2);
            console.log(`Search completed in ${elapsedTime} ms.`);
          }),
          finalize(() => this.loading = false)
        )
      )
    );

    this.currentCustomerAsPersisted = {
      title: this.formGroup.controls.title.value ?? '',
      firstname: this.formGroup.controls.firstname.value ?? '',
      lastname: this.formGroup.controls.lastname.value ?? '',
      contact: this.formGroup.controls.contact.value,
      isConsumer: this.formGroup.controls.isConsumer.value ?? true,
    };
  }

  trackByCustomer(index: number, customer: CustomerWithContact): string {
    return customer.id;
  }

  resetAllRadioButtons() {
    this.pepFormGroup.reset();
  }

  public buildSearchStream(
    firstname$: Observable<string>,
    lastname$: Observable<string>
  ): Observable<[string, string]> {
    return combineLatest([
      firstname$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith(this.firstnameControl.value ?? '')
      ),
      lastname$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        startWith(this.lastnameControl.value ?? '')
      )
    ]).pipe(
      filter(([firstname, lastname]) =>
        (firstname?.trim()?.length >= 3 || lastname?.trim()?.length >= 3)
      ),
      map(([firstname, lastname]) =>
        [firstname?.trim(), lastname?.trim()] as [string, string]
      )
    );
  }

  private get firstnameControl(): AbstractControl {
    return this.formGroup.controls.firstname;
  }

  get isEmailValid(): boolean | undefined {
    return this.formGroup.get('contact')?.get('email')?.invalid;
  }

  get pepFirstNameControl(): FormControl {
    return this.formGroup.get('pep.firstName') as FormControl;
  }

  get pepLastNameControl(): FormControl {
    return this.formGroup.get('pep.lastName') as FormControl;
  }

  get IDImagesFormArray(): FormArray {
    return this.formGroup.get('IDImages') as FormArray;
  }

  get pepFormGroup(): FormGroup {
    return this.formGroup.get('pep') as FormGroup;
  }

  get moneyLaunderingFormGroup(): FormGroup {
    return this.formGroup.get('moneyLaundering') as FormGroup;
  }

  get isConsumerControl(): FormControl {
    return this.formGroup.get('isConsumer') as FormControl;
  }

  get isBirthdateValid(): boolean | undefined {
    return this.formGroup.get('birthdate')?.invalid;
  }

  get sanitizedFirstname(): string {
    return (this.firstnameControl.value ?? '').trim();
  }

  private get lastnameControl(): AbstractControl {
    return this.formGroup.controls.lastname;
  }

  get sanitizedLastname(): string {
    return (this.lastnameControl.value ?? '').trim();
  }

  onCustomerSelected(customer: CustomerWithContact, loadingField: LoadingOnField = 'lastName'): void {
    this.currentCustomerAsPersisted = customer;
    this.loading = true;
    this.loadingField = loadingField;

    this._subscription.add(
      this._customerDataFacade.getCustomerById$(customer.id).pipe(
        first(),
        tap((c) => this.show(c)),
        finalize(() => {
          this.loading = false;
        })
      ).subscribe()
    );
  }

  onAddNewCustomer($event: MatOptionSelectionChange): void {
    if (!$event.source.selected) {
      return;
    }

    if (this.isPersistedCustomer) {
      this.clearForm();
      this.currentCustomerAsPersisted = this.initialCurrentCustomerInfo;
    } else {
      this.ensureFirstnameAndLastnameAreMaintained();
    }
  }

  /*
   INFO: MatAutocomplete is going to override the value of the field in which the onAddNewCustomer event was triggered with the value "".
   Therefore, we have restore the values of first/last name after the current event loop is done.
  */
  private ensureFirstnameAndLastnameAreMaintained(): void {
    const currentFirstname = this.firstnameControl.value;
    const currentLastname = this.lastnameControl.value;

    new Promise<void>((resolve) => resolve()).then(() => {
      this.firstnameControl.patchValue(currentFirstname);
      this.lastnameControl.patchValue(currentLastname);
    });
  }

  private show(customer: CustomerWithContact): void {
    this.updateFormGroup(customer);
  }

  private clearForm(): void {
    this.updateFormGroup(undefined);
  }

  private updateFormGroup(customer?: CustomerWithContact): void {
    this.formGroup.controls.id.setValue(customer?.id || '');
    this.formGroup.controls.title.setValue(customer?.title || '');
    this.formGroup.controls.firstname.setValue(customer?.firstname || '');
    this.formGroup.controls.lastname.setValue(customer?.lastname || '');
    this.formGroup.controls.birthdate.setValue(customer?.birthdate || '');
    this.formGroup.controls.nationality.setValue(customer?.nationality || '');
    this.formGroup.controls.isConsumer.setValue(customer?.isConsumer ?? true);

    const contactFormGroup = this.formGroup.get('contact') as FormGroup;
    contactFormGroup.controls.email.setValue(customer?.contact.email || '');
    contactFormGroup.controls.phone.setValue(customer?.contact.phone || '');

    const addressFormGroup = contactFormGroup.get('address') as FormGroup;
    addressFormGroup.controls.country.setValue(
      customer?.contact.address?.country || ''
    );
    addressFormGroup.controls.city.setValue(
      customer?.contact.address?.city || ''
    );
    addressFormGroup.controls.streetName.setValue(
      customer?.contact.address?.streetName || ''
    );
    addressFormGroup.controls.zipCode.setValue(
      customer?.contact.address?.zipCode || ''
    );
    addressFormGroup.controls.buildingNumber.setValue(
      customer?.contact.address?.buildingNumber || ''
    );
    addressFormGroup.controls.stairway.setValue(
      customer?.contact.address?.stairway || ''
    );
    addressFormGroup.controls.doorNumber.setValue(
      customer?.contact.address?.doorNumber || ''
    );
    addressFormGroup.controls.longitude.setValue(
      customer?.contact.address?.longitude || 0
    );
    addressFormGroup.controls.latitude.setValue(
      customer?.contact.address?.latitude || 0
    );

    if (customer?.pep) {
      const pepFormGroup = this.formGroup.get('pep') as FormGroup;
      pepFormGroup.patchValue({ ...customer?.pep });

      pepFormGroup.markAsPristine();
      pepFormGroup.markAsUntouched();
    }

    if (customer?.moneyLaundering) {
      const moneyLaunderingFormGroup = this.formGroup.get('moneyLaundering') as FormGroup;
      moneyLaunderingFormGroup.patchValue({ ...customer?.moneyLaundering });

      moneyLaunderingFormGroup.markAsPristine();
      moneyLaunderingFormGroup.markAsUntouched();
    }

    if (customer?.IDImages?.length) {
      const IDImagesFormArray = this.formGroup.get('IDImages') as FormArray;
      customer.IDImages.forEach((img: any) => {
        const imageForm = this._customerFormService.createImagesArrayItemForm();
        imageForm.patchValue(img);

        IDImagesFormArray.push(imageForm);
      });

      IDImagesFormArray.markAsPristine();
      IDImagesFormArray.markAsUntouched();
    }

    contactFormGroup.markAsPristine();
    contactFormGroup.markAsUntouched();
    addressFormGroup.markAsPristine();
    addressFormGroup.markAsUntouched();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  private isNameIdenticalToControls(firstname: string, lastname: string): boolean {
    return (
      this.firstnameControl.value?.toLowerCase() === firstname.toLowerCase() &&
      this.lastnameControl.value?.toLowerCase() === lastname.toLowerCase()
    );
  }

  get showIdenticalCustomerWarning(): boolean {
    const isSameNameAsCurrentCustomer = this.isNameIdenticalToControls(
      this.currentCustomerAsPersisted.firstname,
      this.currentCustomerAsPersisted.lastname
    );

    const nameExistsInCustomers = this.identicalCustomers.length > 0;

    const isFormDirty = this.formGroup && this.formGroup.dirty;

    return isFormDirty && !isSameNameAsCurrentCustomer && nameExistsInCustomers;
  }

  showIdenticalCustomers(): void {
    this.identicalCustomersSelect.open();
  }

  get identicalCustomers(): CustomerWithContact[] {
    return this.customers.filter((c) =>
      this.isNameIdenticalToControls(c.firstname, c.lastname)
    );
  }

  get showUpdateWarning(): boolean {
    return this.isPersistedCustomer && this.formIsDirty;
  }

  private get isPersistedCustomer(): boolean {
    return this.formGroup.controls.id.value;
  }

  private get formIsDirty(): boolean {
    const titleIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.title.value !==
      this.currentCustomerAsPersisted.title
      : false;

    const firstnameIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.firstname.value !==
      this.currentCustomerAsPersisted.firstname
      : false;

    const lastnameIsDirty = this.currentCustomerAsPersisted
      ? this.formGroup.controls.lastname.value !==
      this.currentCustomerAsPersisted.lastname
      : false;

    const addressIsDirty = this.currentCustomerAsPersisted
      ? !_.isEqualWith(
        _.omit(this.formGroup.controls.contact.value.address, [
          'displayAddress',
          '__typename',
        ]),
        _.omit(this.currentCustomerAsPersisted.contact.address, [
          'displayAddress',
          '__typename',
        ]),
        (value, other) => value || '' === other || ''
      )
      : false;

    const otherFieldsAreDirty =
      this.formGroup.controls.birthdate.dirty ||
      this.formGroup.controls.contact.dirty ||
      this.formGroup.controls.nationality.dirty;

    return (
      this.formGroup.dirty &&
      (titleIsDirty ||
        firstnameIsDirty ||
        lastnameIsDirty ||
        addressIsDirty ||
        otherFieldsAreDirty)
    );
  }

  private _updatePepValidity(): void {
    this.pepFirstNameControl.updateValueAndValidity();
    this.pepLastNameControl.updateValueAndValidity();
  }
}
