import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { CustomerPortalAgent, CustomerPortalDataFacade } from '@domains/customer-portal';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { AgentPreviewCardComponent } from 'src/app/common-components/agent-preview-card/agent-preview-card.component';
import { ButtonModule } from 'src/app/common-components/button/button.module';
import { SelectAgentDialogComponent } from 'src/app/common-components/select-agent-dialog/select-agent-dialog.component';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    AgentPreviewCardComponent,
  ],
  selector: 'select-agent-form',
  templateUrl: 'select-agent-form.component.html',
  styles: [`
    .agent-image {
      max-width: 6rem;
      max-height: 6rem;
      object-fit: contain;

      border-radius: 3rem;
    }
  `],
})
export class SelectAgentFormComponent implements OnInit, OnDestroy {
  @Input() agentFormGroup!: FormGroup;

  loading = true;
  agents: CustomerPortalAgent[] = [];
  availableCompanyInfos: any[] = [];
  agent: any = null;
  selectedCompanyInfo: any = null;

  private _sub = new Subscription();

  constructor(
    private _customerPortalDataFacade: CustomerPortalDataFacade,
    private readonly _dialog: MatDialog,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit(): void {
    this._fetchAgents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agentFormGroup'] && this.agentFormGroup) {

      this._sub.unsubscribe();
      this._sub = new Subscription();

      this._presetAgent(this.agentFormGroup.value);

      this._sub.add(
        this.agentFormGroup.valueChanges.subscribe((value) => {
          this._presetAgent(value);

          if (this.agents.length > 0) {
            this.availableCompanyInfos = this._buildAvailableCompanyInfos(this.agents, this.agent);
          }

          this._cdr.markForCheck();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  companyInfoCompareWith(o1: any, o2: any): boolean {
    return o1?.legalEntity === o2?.legalEntity && o1?.officeName === o2?.officeName;
  }

  openSelectAgentDialog(): void {
    const dialogConfig = new MatDialogConfig<any>();
    if (this._breakpointObserver.isMatched(Breakpoints.Handset)) {
      dialogConfig.width = '100vw';
      dialogConfig.height = '100vh';
      dialogConfig.maxWidth = '100vw';
      dialogConfig.maxHeight = '100vh';
    } else {
      dialogConfig.width = '60vw';
      dialogConfig.maxWidth = '90vw';
      dialogConfig.maxHeight = '80vh';
    }
    dialogConfig.data = {
      selectedAgent: this.agent,
      agents: this.agents,
    };

    const dialogRef = this._dialog.open(SelectAgentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((value) => {
      if (value && this.agentFormGroup) {
        this.agent = value;
        this.agentFormGroup.patchValue(value);
        this.availableCompanyInfos = this._buildAvailableCompanyInfos(this.agents, value);
        this.selectedCompanyInfo = {
          legalEntity: this.agent?.legalEntity,
          officeName: this.agent?.officeName,
        };

        this._cdr.markForCheck();
      }
    });
  }

  onCompanyInfoChange(event: MatSelectChange): void {
    const selectedValue = event.value;

    if (this.agentFormGroup) {
      this.agentFormGroup.patchValue({
        ...this.agentFormGroup.value,
        legalEntity: selectedValue.legalEntity,
        officeName: selectedValue.officeName,
        officeAddress: selectedValue.officeAddress,
      });

      this.selectedCompanyInfo = selectedValue;

      this._cdr.markForCheck();
    }
  }

  private _buildAvailableCompanyInfos(agents: CustomerPortalAgent[], agent: CustomerPortalAgent) {
    const fullAgentInfo = agents.find(a => a.id === agent?.id);

    return [
      {
        legalEntity: fullAgentInfo?.legalEntity ?? '',
        officeName: fullAgentInfo?.officeName ?? '',
        officeAddress: fullAgentInfo?.officeAddress ?? '',
      },
      ...(fullAgentInfo?.companyInformation || []).map(ci => ({
        legalEntity: ci.legalEntity ?? '',
        officeName: ci.officeName ?? '',
        officeAddress: ci.officeAddress ?? '',
      })),
    ];
  }

  private _fetchAgents(): void {
    this._customerPortalDataFacade.getTeamCustomerPortalAgents$().pipe(
      first(),
      tap((agents) => {
        this.agents = agents;

        if (this.agentFormGroup) {
          this.availableCompanyInfos = this._buildAvailableCompanyInfos(agents, this.agentFormGroup.value);
        }
      }),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe();
  }

  private _presetAgent(value: CustomerPortalAgent): void {
    this.agent = value;

    this.selectedCompanyInfo = {
      legalEntity: value?.legalEntity,
      officeName: value?.officeName,
      officeAddress: value?.officeAddress,
    };
  }
}
