import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'common-slide-toggle',
  template: `
    <div
      class="w-full flex flex-row items-center"
      [ngClass]="[compact ? 'justify-start' : 'justify-between', size]"
    >
      <div>
        <div class="font-source  mr-4">{{ label }}</div>
        <!-- TODO: replace text-gray-400 with proper color -->
        <p *ngIf="this.subLabel" class="text-xs text-gray-400 pb-2">
          {{ subLabel }}
        </p>
      </div>
      <ng-container *ngIf="control">
        <mat-slide-toggle
          [disabled]="disabled"
          class="pr-1"
          labelPosition="before"
          color="primary"
          [aria-label]="label"
          [formControl]="control"
          [checked]="checked"
          (change)="valueChanged($event)"
          [matTooltip]="disabled ? ('alerts.full_version' | translate) : ''"
        ></mat-slide-toggle>
      </ng-container>
      <ng-container *ngIf="!control">
        <mat-slide-toggle
          [disabled]="disabled"
          class="pr-1"
          labelPosition="before"
          color="primary"
          [aria-label]="label"
          [checked]="checked"
          (change)="valueChanged($event)"
          [matTooltip]="disabled ? ('alerts.full_version' | translate) : ''"
        ></mat-slide-toggle>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      :host ::ng-deep .mat-slide-toggle-bar {
        height: 24px;
        width: 60px;
      }

      :host ::ng-deep .mat-slide-toggle-thumb {
        height: 35px;
        width: 35px;
      }

      :host
        ::ng-deep
        .mat-slide-toggle.mat-checked
        .mat-slide-toggle-thumb-container {
        transform: translate3d(24px, 0, 0) !important;
      }

      :host ::ng-deep .mat-slide-toggle-thumb-container {
        width: 35px;
        height: 35px;
        top: -6px;
      }

      :host ::ng-deep .mat-slide-toggle {
        height: 54px;
      }

      :host ::ng-deep .ng-invalid {
        border: 1px solid red;
      }
    `,
  ],
})
export class SlideToggleComponent {
  @Input()
  disabled = false

  @Input()
  checked = false;

  @Input()
  label!: string;

  @Input()
  subLabel!: string;

  @Input()
  control: any;

  @Input()
  compact = false;

  @Input()
  size = 'text-lg';

  @Output()
  checkedChanged = new EventEmitter<MatSlideToggleChange>();

  valueChanged($event: MatSlideToggleChange): void {
    this.checkedChanged.emit($event);
  }
}
