<div *ngIf="(hasFreeVersion$ | async) === false" class="mt-4">

  <integration-wrapper [integration]="ExternalSystem.Justimmo">
    <status-indicator name="Justimmo" [isValid]="isJustimmoTokenValid$ | async"></status-indicator>
  </integration-wrapper>

  <integration-wrapper [integration]="ExternalSystem.OnOffice">
    <status-indicator name="OnOffice" [isValid]="isOnOfficeTokenValid$ | async"></status-indicator>
  </integration-wrapper>

  <div class="mt-2">
    <integration-wrapper [integration]="ExternalSystem.EdiReal">
      <status-indicator name="Edi-Real" [isValid]="isEdiRealTokenValid$ | async"></status-indicator>
    </integration-wrapper>
  </div>

  <div class="mt-2">
    <integration-wrapper [integration]="ExternalSystem.Remax">
      <status-indicator name="PrivateNet" [isValid]="isRemaxTokenValid$ | async"></status-indicator>
    </integration-wrapper>
  </div>

  <div class="mt-2">
    <integration-wrapper [integration]="ExternalSystem.Microsoft">
      <status-indicator name="Microsoft" [isValid]="isMicrosoftTokenValid$ | async"></status-indicator>
    </integration-wrapper>
  </div>
</div>
