<div *ngIf="showHeader" class="space-y-2">
  <div class="sm:hidden lg:block">
    <common-heading
      [label]="kind + ' ' + address"
      size="medium"
    ></common-heading>
  </div>

  <div class="hidden lg:block">
    <p>{{ signatureLocation }}, {{ signatureDate | momentDate }}</p>
  </div>
</div>

<div class="my-2">
  <ng-content></ng-content>
</div>

<div class="flex flex-col">
  <div class="flex flex-col sm:flex-row sm:gap-x-4 lg:flex-col sm:items-end">

    <ng-container *ngIf="signeesData.length">
      <mat-accordion class="mt-4 sm:w-full md:w-3/4 lg:w-full" [multi]="true">
        <mat-expansion-panel
          *ngFor="let signee of signeesData; let i = index"
          [disabled]="signee.inactive"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="flex">
                <div class="font-bold text-primary mr-2">{{ signeeRoleInfo }}</div>
                {{ signee.signeeData.firstname }} {{ signee.signeeData.lastname }}
              </div>
            </mat-panel-title>
            <mat-panel-description>
              <span *ngIf="!signee.present">{{'general.absent' | translate}}</span>
              <span *ngIf="(signee.present && signee.signed) || signee.inactive">{{'general.signed' | translate}}</span>
              <span *ngIf="(signee.present && !signee.signed) && !signee.inactive">{{'general.not_signed' | translate}}</span>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div  class="flex flex-col sm:mr-2 lg:mr-0 flex-grow">
            <div class="flex">
              <common-slide-toggle
                *ngIf="optional"
                [label]="slideText"
                class="flex-grow"
                [compact]="true"
                [checked]="signee.present"
                (checkedChanged)="disableSignature($event, i)"
              ></common-slide-toggle>
              <button
                *ngIf="signee.present"
                class="font-source rounded-xl text-lg text-primary py-2"
                (click)="clear(i)"
              >{{'list_item.delete' | translate}}</button>
            </div>
            <common-signature-input
              class="w-full h-40 lg:h-72 mb-5 lg:mb-2.5"
              (signed)="onSigned(i)"
              (cleared)="onCleared(i)"
              [isEnabled]="signee.present && !signee.inactive"
            ></common-signature-input>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <div class="flex mt-5 lg:mt-2.5 sm:flex-col sm:justify-center sm:items-end space-x-4 sm:mt-12 lg:justify-between lg:flex-row sm:mb-2">
      <div class="flex-grow items-end">
        <button
          *ngIf="showBackButton"
          (click)="abort()"
          class="font-source rounded-xl text-lg text-primary py-2 mr-2"
        >{{ 'general.back' | translate }}</button>
      </div>

      <div class="flex-grow text-right space-y-2">
        <button
          *ngIf="enableDraftSaving"
          (click)="saveDraftSignature()"
          [disabled]="isSaveDisabled || isLocationMissing"
          class="font-source rounded-xl text-lg text-white font-bold py-2 px-4 sm:mb-2 lg:mb-0 bg-primary disabled:bg-secondary-dark disabled:bg-gray-300 mr-2"
        >{{ saveDraftButtonText }}</button>
        <button
          (click)="save()"
          [disabled]="isSaveDisabled || isLocationMissing"
          class="flex justify-center items-center font-source rounded-xl text-lg text-white font-bold py-2 px-4 sm:mb-2 lg:mb-0 bg-primary disabled:bg-secondary-dark disabled:bg-gray-300 mr-2"
        >
          <mat-spinner *ngIf="nextButtonLoading" class="mr-2 spinner" diameter="20"></mat-spinner>
          {{ nextButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
