import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountLinkingService } from 'services/account-linking.service';
import {
  selectIsEdiRealTokenValid,
  selectIsJustimmoTokenValid,
  selectIsRemaxTokenValid,
  selectIsMicrosoftTokenValid,
  selectIsOnOfficeTokenValid
} from 'src/app/store/_global/global.selectors';
import {
  selectIsEdiReal,
  selectIsJustimmo, selectIsMicrosoft, selectIsOnOffice,
  selectIsRemax,
} from 'src/app/store/settings/settings.selectors';
import { environment } from 'src/environments/environment';
import { StaticFeatureToggles } from 'src/environments/static-feature-toggles';

export enum ExternalSystem {
  EdiReal = 'edi-real',
  Remax = 'remax',
  Justimmo = 'justimmo',
  Microsoft = 'microsoft',
  OnOffice = 'onOffice',
}

@Component({
  selector: 'integration-wrapper',
  templateUrl: './integration-wrapper.component.html',
  styles: [`
    :host {
      max-width: 240px;
      width: 100%;
    }
  `]
})
export class IntegrationWrapperComponent {
  @Input() integration!: ExternalSystem;

  isEdiReal$ = this._store.select(selectIsEdiReal);
  isJustimmo$ = this._store.select(selectIsJustimmo);
  isRemax$ = this._store.select(selectIsRemax);
  isMicrosoft$ = this._store.select(selectIsMicrosoft);
  isOnOffice$ = this._store.select(selectIsOnOffice);

  ExternalSystem = ExternalSystem;

  isRemaxTokenValid$ = this._store.select(selectIsRemaxTokenValid);
  isEdiRealTokenValid$ = this._store.select(selectIsEdiRealTokenValid);
  isJustimmoTokenValid$ = this._store.select(selectIsJustimmoTokenValid);
  isMicrosoftTokenValid$ = this._store.select(selectIsMicrosoftTokenValid);
  isOnOfficeTokenValid$ = this._store.select(selectIsOnOfficeTokenValid);

  ediLink = this._accountLinkingService.generateLink('edi-real', window.location.href);
  remaxLink = this._accountLinkingService.generateLink('remax-idp', window.location.href);
  justimmoLink = this._accountLinkingService.generateLink('justimmo', window.location.href);
  microsoftLink = this._accountLinkingService.generateLink('microsoft', window.location.href);
  onOfficeLink = 'https://calendly.com/mreikersdorfer/plattform-support'

  get feature(): StaticFeatureToggles {
    return environment.staticToggles;
  }

  constructor(
    private readonly _store: Store,
    private readonly _accountLinkingService: AccountLinkingService
  ) { }
}
