import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-hint',
  template: `
    <img src="assets/icons/warn.svg" />
    <p class="text-warn text-sm" [innerHtml]="messageHtml"></p>
  `,
  styleUrls: ['hint.component.scss'],
})
export class HintComponent {
  messageHtml = '';

  @Input()
  set message(value: string) {
    this.messageHtml = value.replace('\\n', '<br>');
  }
}
