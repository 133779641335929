import { Injectable } from '@angular/core';

export interface ImageInfo {
  file: File;
  id: string;
  type: string;
  thumbnailDataUrl: string;
  fullImageUrl?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageAdditionService {
  // TODO PRO-534: get config values from configuration
  private readonly GREATEST_LENGTH = 1000;
  private readonly THUMBNAIL_SIZE = 56 * 2; // * 2 to support retina displays

  async addImageFromFile(
    originalImage: File,
    imageType: string,
    supportPdf = false,
  ): Promise<ImageInfo> {
    let thumbnail;

    if (supportPdf && originalImage.type === 'application/pdf') {
      thumbnail = originalImage;
    } else {
      const largeImage = await this.createLargeImage(originalImage);
      thumbnail = await this.createThumbnail(largeImage);
    }

    const thumbnailDataUrl = await this.blobToDataURL(thumbnail);

    const id = Math.random().toString().replace('0.', ''); // Collisions could happen but are very unlikely

    return {
      file: originalImage,
      id,
      type: imageType,
      thumbnailDataUrl,
    };
  }

  async createLargeImage(image: File | Blob): Promise<Blob> {
    return await this.scaleToGreatestLength(image, this.GREATEST_LENGTH);
  }

  private async scaleToGreatestLength(
    image: File | Blob,
    greatestLength: number
  ): Promise<Blob> {
    return new Promise((res, rej) => {
      const img = new Image();

      img.onload = () => {
        const actualGreatest = Math.max(img.width, img.height);

        const scaleBy = greatestLength / actualGreatest;
        const targetWidth = Math.round(img.width * scaleBy);
        const targetHeight = Math.round(img.height * scaleBy);

        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, targetWidth, targetHeight);

        canvas.toBlob((blob) => {
          if (blob !== null) {
            res(blob);
          } else {
            rej('blob is null');
          }
        });
        URL.revokeObjectURL(img.src);
      };
      img.onerror = (error) => rej(error);

      img.src = URL.createObjectURL(image);
    });
  }

  private async createThumbnail(image: File | Blob): Promise<Blob> {
    return await this.cropToSquareAndScale(image, this.THUMBNAIL_SIZE);
  }

  private async cropToSquareAndScale(
    photo: File | Blob,
    length: number
  ): Promise<Blob> {
    const leaveUnchangedOrSwap = ([a, b]: [number, number], inOrder: boolean) =>
      inOrder ? [a, b] : [b, a];

    return new Promise((res, rej) => {
      const img = new Image();

      img.onload = () => {
        const landscape = img.width > img.height;
        const [greatest, smallest] = leaveUnchangedOrSwap(
          [img.width, img.height],
          landscape
        );
        const offset = Math.round((greatest - smallest) / 2);
        const [offsetX, offsetY] = leaveUnchangedOrSwap([offset, 0], landscape);

        const canvas = document.createElement('canvas');
        canvas.width = length;
        canvas.height = length;
        const ctx = canvas.getContext('2d');

        ctx?.drawImage(
          img,
          offsetX,
          offsetY,
          smallest,
          smallest,
          0,
          0,
          length,
          length
        );

        canvas.toBlob((blob) => {
          if (blob !== null) {
            res(blob);
          } else {
            rej('blob is null');
          }
        });
        URL.revokeObjectURL(img.src);
      };
      img.onerror = (error) => rej(error);

      img.src = URL.createObjectURL(photo);
    });
  }

  private blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result;
        if (typeof dataUrl !== 'string') {
          rej('Could not obtain dataUrl');
        } else {
          res(dataUrl as string);
        }
      };
      reader.onerror = rej;

      reader.readAsDataURL(blob);
    });
  }
}
