<section [formGroup]="formGroup">
  <mat-accordion>
    <mat-expansion-panel class="divide-y" (opened)="setCurrentStep()" [expanded]="expand">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-source">
          Vertretungsbefugte Person {{ index + 1 }}
        </mat-panel-title>
        <mat-panel-description class="flex-1 justify-between">
          <div class="flex-1">
            <span *ngIf="formGroup.invalid" class="text-red-600">Bitte füllen Sie alle Pflichtfelder aus.</span>
          </div>

          <mat-icon (click)="removeStep()">cancel</mat-icon>
        </mat-panel-description>

      </mat-expansion-panel-header>

      <div class="py-4">
        <customers-customer-page [formGroup]="formGroup" [showPep]="showPep" [isOptional]="true" [isBusiness]="true">
        </customers-customer-page>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>
