import * as DataActions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import {CustomerPortal, CustomerPortalFeedback, GenericCustomerPortalSettings} from '../customer-portal.model';

export interface State {
  customerPortals: {
    data: CustomerPortal[];
    loading: boolean;
  };
  customerPortalFeedback: {
    data: CustomerPortalFeedback[];
    loading: boolean;
  };
  customerPortalDetails: {
    data: CustomerPortal | null;
    loading: boolean;
  };
  savingProcess: {
    loading: boolean;
    error: boolean;
  };
  genericCustomerPortalSettings: {
    data: GenericCustomerPortalSettings | null;
    loading: boolean;
  }
}

export const initialState: State = {
  customerPortals: {
    data: [],
    loading: false,
  },
  customerPortalFeedback: {
    data: [],
    loading: false,
  },
  customerPortalDetails: {
    data: null,
    loading: false,
  },
  savingProcess: {
    loading: false,
    error: false,
  },
  genericCustomerPortalSettings: {
    data: null,
    loading: false,
  },
};

const customerPortalReducer = createReducer(
  initialState,

  on(DataActions.LoadCustomerPortals, DataActions.LoadCustomerPortalsByCustomer, (state) => ({
    ...state,
    customerPortals: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.CustomerPortalsLoaded, (state, { payload }) => ({
    ...state,
    customerPortals: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.CustomerPortalsLoadingFailed, (state) => ({
    ...state,
    customerPortals: {
      ...state.customerPortals,
      loading: false,
    },
  })),

  on(DataActions.LoadCustomerPortalFeedback, (state) => ({
    ...state,
    customerPortalFeedback: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.CustomerPortalFeedbackLoaded, (state, { payload }) => ({
    ...state,
    customerPortalFeedback: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.CustomerPortalFeedbackLoadingFailed, (state) => ({
    ...state,
    customerPortalFeedback: {
      ...state.customerPortalFeedback,
      loading: false,
    },
  })),

  on(DataActions.LoadCustomerPortalDetails, (state) => ({
    ...state,
    customerPortalDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.CustomerPortalDetailsLoaded, (state, { payload }) => ({
    ...state,
    customerPortalDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.CustomerPortalDetailsLoadingFailed, (state) => ({
    ...state,
    customerPortalDetails: {
      ...state.customerPortalDetails,
      loading: false,
    },
  })),

  on(DataActions.SaveCustomerPortal, (state) => ({
    ...state,
    CustomerPortal: {
      loading: true,
      error: false,
    },
  })),
  on(DataActions.SaveCustomerPortalSucceded, (state) => ({
    ...state,
    CustomerPortal: {
      loading: false,
      error: false,
    },
  })),
  on(DataActions.SaveCustomerPortalFailed, (state) => ({
    ...state,
    CustomerPortal: {
      loading: false,
      error: true,
    },
  })),

  on(DataActions.DeleteCustomerPortal, (state) => ({
    ...state,
    customerPortals: {
      ...state.customerPortals,
      loading: true,
    },
  })),
  on(DataActions.DeleteCustomerPortalSucceded, (state, { id }) => ({
    ...state,
    customerPortals: {
      data: state.customerPortals.data.filter((v: CustomerPortal) => v.id !== id),
      loading: false,
    },
  })),
  on(DataActions.DeleteCustomerPortalFailed, (state) => ({
    ...state,
    customerPortals: {
      ...state.customerPortals,
      loading: false,
    },
  })),
  on(DataActions.LoadGenericCustomerPortalSettings, (state) => ({
    ...state,
    genericCustomerPortalSettings: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.GenericCustomerPortalSettingsLoaded, (state, { payload }) => ({
    ...state,
    genericCustomerPortalSettings: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.GenericCustomerPortalSettingsLoadingFailed, (state) => ({
    ...state,
    genericCustomerPortalSettings: {
      ...state.genericCustomerPortalSettings,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => customerPortalReducer(state, action);

export const getCustomerPortals = (state: State): CustomerPortal[] => state.customerPortals.data;
export const getCustomerPortalsLoading = (state: State): boolean => state.customerPortals.loading;
export const getCustomerPortalFeedback = (state: State): CustomerPortalFeedback[] => state.customerPortalFeedback.data;
export const getCustomerPortalFeedbackLoading = (state: State): boolean => state.customerPortalFeedback.loading;
export const getCustomerPortalDetails = (state: State): CustomerPortal | null => state.customerPortalDetails.data;
export const getCustomerPortalDetailsLoading = (state: State): boolean => state.customerPortalDetails.loading;
export const getSavingProcessLoading = (state: State): boolean => state.savingProcess.loading;
export const getSavingProcessError = (state: State): boolean => state.savingProcess.error;
export const getGenericCustomerPortalSettings = (state: State): any => state.genericCustomerPortalSettings;
