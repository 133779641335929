import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { map } from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OnOfficeService {
  constructor(
    private apollo: Apollo,
  ) { }

  postOnOfficeImport(data: any) {
    return this.apollo.mutate({
      mutation: gql`
        mutation postOnOfficeImport($data: OnOfficeImportInput!) {
          postOnOfficeImport(body: $data) {
            tempIds
            message
          }
        }
      `,
      variables: {
        data: data
      },
      fetchPolicy: 'no-cache'
    }).pipe(map((res: any) => res.data.postOnOfficeImport));
  }

  checkTokenValidity$(): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query OnOfficeTokenValidity {
          onOfficeTokenValidity
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.onOfficeTokenValidity));
  }
}
