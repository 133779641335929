import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { SignatureInputComponent } from '../../../common-components/signature/signature-input/signature-input.component';
import { SignatureData } from 'src/app/common-models/signature-data';
import { getAddressAsString } from '../../address-field-autocomplete/formatting';
import { AdditionalContentDirective } from '../additionalContent.directive';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Object } from '@domains/object';
import { PersonName, PersonSignature } from '@domains/customer';
import moment, { Moment } from "moment";
import { TranslationService } from "../../../i18n/TranslationService";

@Component({
  selector: 'common-signature-screen',
  templateUrl: './signature-screen.component.html',
  styleUrls: ['signature-screen.component.scss'],
})
export class SignatureScreenComponent implements AfterViewInit {
  @ContentChild(AdditionalContentDirective, { read: TemplateRef })
  additionalContentTemplate!: any;

  @ViewChildren(SignatureInputComponent)
  signatures!: QueryList<SignatureInputComponent>;

  @Input() kind = 'Besichtigung';
  @Input() showBackButton = true;
  @Input() signeeRole = '';
  @Input() object!: Object;
  @Input() signatureType = 'regular';
  @Input() showHeader = true;
  @Input() signatureLocation!: string;
  /** Displays slide component to enable/disable signature field */
  @Input() optional = false;
  /** Userd when optional=true. Specify if at least one signature should be set */
  @Input() atLeastOneSigned = false;
  /** Slide component text to display */
  @Input() slideText = '';
  @Input() nextButtonLoading = false;

  @Input()
  set signees(value: PersonName[]) {
    this._signeesSignatureData = value.map((s) =>
      this._mapPersonToSignatureResult(s)
    );
  }

  @Input() enableDraftSaving = false;
  @Input() saveDraftButtonText = this._translationService.instant('general.save');
  @Input() nextButtonText = this._translationService.instant('list_item.sign');

  @Output() back = new EventEmitter<void>();
  @Output() next = new EventEmitter<SignaturFinalizationData>();
  @Output() saveDraft = new EventEmitter<SignaturFinalizationData>();

  signatureDate: Moment = moment();

  private _signeesSignatureData: SignatureResult[] = [];

  constructor(
    private _translationService: TranslationService,
  ) {
  }

  get signeesData(): SignatureResult[] {
    return this._signeesSignatureData ?? [];
  }

  get isSaveDisabled(): boolean {
    if (this.optional) {
      const everySignedOrNotPresent = this.signeesData.every(
        (d) => (d.present && d.signed) || !d.present
      );

      if (this.atLeastOneSigned) {
        const signaturesAmount = this.signeesData.filter(
          (d) => d.present && d.signed
        );

        return !(everySignedOrNotPresent && signaturesAmount.length);
      }

      return !everySignedOrNotPresent;
    }

    return !this.signeesData.every((d) => d.signed);
  }

  get signeeRoleInfo(): string {
    return this.signeeRole ? `${this.signeeRole}: ` : '';
  }

  get isLocationMissing(): boolean {
    return !this.signatureLocation;
  }

  get address(): string {
    return this.object ? getAddressAsString(this.object.address) : '';
  }

  ngAfterViewInit(): void {
    this.signatures.forEach((signatureField) => {
      signatureField.adjustToScreen();
    });
  }

  disableSignature(event: MatSlideToggleChange, index: number): void {
    this.signeesData[index].present = event.checked;

    if (!event.checked) {
      this.signeesData[index].signatureData.signatureAsSvg = '';
      this.signeesData[index].signed = false;
    }
  }

  abort(): void {
    this.back.emit();
  }

  clear(index: number): void {
    this.signatures.toArray()[index].clear();
  }

  save(): void {
    if (this.nextButtonLoading) {
      return;
    }

    this.signeesData.forEach((s) => {
      if (!s.signatureData.date) {
        s.signatureData.date = this.signatureDate.toISOString();
      }
      if (!s.signatureData.location) {
        s.signatureData.location = this.signatureLocation;
      }

      s.signatureData.type = this.signatureType;
    });

    this.next.emit({
      signatureResult: this.signeesData,
      objectId: this.object.id,
    });
  }

  saveDraftSignature(): void {
    this.signeesData.forEach((s) => {
      if (!s.signatureData.date) {
        s.signatureData.date = this.signatureDate.toISOString();
      }
      if (!s.signatureData.location) {
        s.signatureData.location = this.signatureLocation;
      }

      s.signatureData.type = this.signatureType;
    });

    this.saveDraft.emit({
      signatureResult: this.signeesData,
      objectId: this.object.id,
    });
  }

  onSigned(index: number): void {
    const signature = this.signatures.toArray()[index].getSignatureAsSvg();

    this.signeesData[index].signed = true;
    this.signeesData[index].signatureData.signatureAsSvg = signature;
  }

  onCleared(index: number): void {
    if (!this.signeesData[index].inactive) {
      this.signeesData[index].signed = false;
      this.signeesData[index].signatureData.signatureAsSvg = '';
    }
  }

  private _mapPersonToSignatureResult(signee: PersonName & PersonSignature): SignatureResult {
    const isSigned = !!signee.signature?.signatureAsSvg || !!signee.signature?.signedViaTAN;
    return {
      signeeData: signee,
      present: isSigned || true,
      signed: isSigned,
      inactive: isSigned,
      signatureData: {
        date: signee.signature?.date ? moment(signee.signature?.date).toISOString() : this.signatureDate.toISOString(),
        type: this.signatureType,
        location: signee.signature?.location ?? '',
        signatureAsSvg: signee.signature?.signatureAsSvg ?? '',
        signedViaTAN: signee.signature?.signedViaTAN ?? false,
      },
    };
  }
}

export interface SignaturFinalizationData {
  objectId: string;
  signatureResult: SignatureResult[];
}

export interface SignatureResult {
  signeeData: PersonName;
  inactive: boolean;
  present: boolean;
  signed: boolean;
  signatureData: SignatureData;
}

export interface DraftSignature {
  location: string;
  date: string;
  signatureAsSvg: string;
  signedViaTAN?: boolean;
  signatureText: string;
  present: boolean;
}
