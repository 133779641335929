import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'common-hint-with-link',
  template: `
    <img src="assets/icons/warn.svg" />
    <p class="text-warn text-sm">
      {{ message }}
      <span class="underline cursor-pointer" (click)="clicked()">{{
        clickableMessage
      }}</span>
    </p>
  `,
  styleUrls: ['hint-with-link.component.scss'],
})
export class HintWithLinkComponent {
  @Input()
  message = '';
  @Input()
  clickableMessage = '';

  @Output()
  clickableMessageClicked = new EventEmitter<MouseEvent>();

  clicked(): void {
    this.clickableMessageClicked.emit();
  }
}
