import { ViewingPassEmailVariables } from "src/app/domains/viewing-pass/viewing-pass.model";

const PROPERTY_ADDRESS = '{PROPERTY_ADDRESS}';
const DOCUMENT_URL = '{DOCUMENT_URL}';
const SIGNATURE = '{SIGNATURE}';
const CUSTOMER_PORTAL_TEXT = '{*CUSTOMER_PORTAL_TEXT}';
const FIMOBILIA_FINANCIAL_OFFER_TEXT = '{*FIMOBILIA_FINANCIAL_OFFER_TEXT}';

const mergeVariables = (template: string, vars: ViewingPassEmailVariables) => {
  return template
    .replaceAll(PROPERTY_ADDRESS, vars.propertyAddress)
    .replaceAll(SIGNATURE, vars.signature)
    .replaceAll(DOCUMENT_URL, vars.documentUrl)
    .replaceAll(CUSTOMER_PORTAL_TEXT, vars?.customerPortalText || '')
    .replaceAll(FIMOBILIA_FINANCIAL_OFFER_TEXT, vars?.fimobiliaFinancialOfferText || '');
}

export const buildTemplate = (title: string, body: string, vars: ViewingPassEmailVariables) => {
  const mail = {
    subject: mergeVariables(title, vars),
    body: mergeVariables(body, vars),
  };
  return mail;
}

export const ViewingPassEmail = {
  buildTemplate,
  mergeVariables,
};
