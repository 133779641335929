import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CustomerType } from './model';

@Component({
  selector: 'customer-type-selector',
  templateUrl: 'customer-type-selector.component.html'
})

export class CustomerTypeSelectorComponent {
  @Input() control!: FormControl;
  @Input() text!: string;

  options = [
    { value: CustomerType.Private, name: 'Natürliche Person' },
    { value: CustomerType.Business, name: 'Juristische Person' },
  ];
}