import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import {TeamRolesPipe} from "./role.pipe";
import {MomentDatePipe} from "./moment-date.pipe";

@NgModule({
  exports: [SafePipe, TeamRolesPipe, MomentDatePipe],
  declarations: [SafePipe, TeamRolesPipe, MomentDatePipe],
  providers: [TeamRolesPipe, MomentDatePipe],
})
export class HelperModule { }
