import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface OwnerPortalFeatureState {
  ownerPortal: fromReducer.State;
}

export const reducers: ActionReducerMap<OwnerPortalFeatureState> = {
  ownerPortal: fromReducer.reducer,
};

export const getOwnerPortalState = createFeatureSelector<fromReducer.State>('owner-portal');

export const ownerPortalSelectors = {
  ownerPortals: createSelector(getOwnerPortalState, fromReducer.getOwnerPortals),
  ownerPortalsLoading: createSelector(getOwnerPortalState, fromReducer.getOwnerPortalsLoading),
  ownerPortalDetails: createSelector(getOwnerPortalState, fromReducer.getOwnerPortalDetails),
  ownerPortalDetailsLoading: createSelector(getOwnerPortalState, fromReducer.getOwnerPortalDetailsLoading),
  savingProcessLoading: createSelector(getOwnerPortalState, fromReducer.getSavingProcessLoading),
  savingProcessHasError: createSelector(getOwnerPortalState, fromReducer.getSavingProcessError),
};
