import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

interface EmailConfigurationDataForm {
  recipients: FormControl<string[]>;
  recipientsCopy: FormControl<string[]>;
  title: FormControl<string>;
  body: FormControl<string>;
  attachments: FormArray<any>;
  includeImages?: FormControl<boolean | null>;
};

interface BulkEmailConfigurationDataForm {
  recipientsCopy: FormControl<string[]>;
  title: FormControl<string>;
  body: FormControl<string>;
  attachments: FormArray<any>;
  includeImages?: FormControl<boolean | null>;
};

export const bulkEmailConfigurationFormGroup = () => (new FormGroup<BulkEmailConfigurationDataForm>({
  recipientsCopy: new FormControl<string[]>([], { nonNullable: true }),
  title: new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  }),
  body: new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  }),
  attachments: new FormArray([] as any),
}));

export const emailConfigurationFormGroup = () => (new FormGroup<EmailConfigurationDataForm>({
  recipients: new FormControl<string[]>([], {
    validators: Validators.required,
    nonNullable: true,
  }),
  recipientsCopy: new FormControl<string[]>([], { nonNullable: true }),
  title: new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  }),
  body: new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  }),
  attachments: new FormArray([] as any),
}));

export const hopEmailConfigurationFormGroup = () => {
  const formGroup = emailConfigurationFormGroup();

  formGroup.addControl('includeImages', new FormControl<boolean>(true));

  return formGroup;
};
