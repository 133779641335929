<div class="text-end mr-4">
  <span
    class="text-primary underline cursor-pointer"
    (click)="openFullscreen()"
  >
    {{ 'general.full_screen' | translate }}
  </span>
</div>
<canvas
  #signatureCanvas
  class="w-full h-full block border border-gray-500 rounded-xl hover:border-primary"
  [ngClass]="isEnabled ? 'bg-white' : 'bg-gray-light'"
></canvas>
