import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'common-marked-text',
  template: `
    <mark [ngClass]="showMark ? 'inline' : 'hidden'">{{ markedPortion }}</mark
    >{{ unmarkedPortion }}
  `,
  styles: [
    `
      mark {
        background-color: #536b91;
        color: white;
        padding-left: 1px;
      }
    `,
  ],
})
export class MarkedTextComponent {
  @Input() text = '';
  @Input() textToMark = '';

  get markedPortion(): string {
    return this.text.substring(0, this.markLength);
  }

  get unmarkedPortion(): String {
    return this.text.substring(this.markLength);
  }

  get showMark(): boolean {
    return this.markLength > 0;
  }

  private get markLength(): number {
    const regex = new RegExp('^' + this.textToMark, 'i');
    const startsWithTextToMark = this.text.search(regex) >= 0;

    return startsWithTextToMark ? this.textToMark.length : 0;
  }
}
