import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { CountrySettings } from '@types';
import { map } from 'rxjs/operators';

import { EmailTemplate, EmailTemplates } from 'src/app/store/settings/settings.reducers';
import { selectSettings } from 'src/app/store/settings/settings.selectors';
import {
  bulkEmailConfigurationFormGroup,
  emailConfigurationFormGroup,
  hopEmailConfigurationFormGroup
} from './email-configuration-forms';
import { EmailConfigurationDialogData, EmailConfigurationDialogOutput, EmailConfigurationEntityType, } from './models';
import { Language } from 'src/app/i18n/config';
import { RentalOfferEmail } from '@domains/rental-offer';
import { ViewingPassEmail } from 'src/app/domains/viewing-pass/viewing-pass-email';
import { BuyingOfferEmail } from 'src/app/domains/buying-offer/buying-offer-email';
import { HandoverProtocolEmail } from '@domains/handover-protocol';
import { CustomerPortalEmail } from '@domains/customer-portal';
import { OwnerPortalEmail } from 'src/app/domains/owner-portal';
import { ExclusiveAgreementEmail } from '@domains/exclusive-agreement';
import { PlainAgreementEmail } from '@domains/plain-agreement';
import {TranslationService} from "../../i18n/TranslationService";

interface EmailConfig {
  templates: EmailTemplates;
  emailRecipients: string[];
}

@Component({
  selector: 'email-configuration-dialog',
  templateUrl: 'email-configuration-dialog.component.html',
  styles: [`
  ::ng-deep.mat-progress-spinner circle {
    stroke: #536b91;
  }
  `],
})
export class EmailConfigurationDialogComponent implements OnInit {
  loading = false;

  @Output() prepareEmail = new EventEmitter<EmailConfigurationDialogOutput>();

  private _subscription = new Subscription();

  public exclusiveAgreementForms = {
    contractEntities: emailConfigurationFormGroup(),
    others: emailConfigurationFormGroup(),
  }
  public plainAgreementForms = {
    contractEntities: emailConfigurationFormGroup(),
    others: emailConfigurationFormGroup(),
  }
  public viewingPassForms = {
    customers: emailConfigurationFormGroup(),
    others: emailConfigurationFormGroup(),
  };
  public rentalOfferForms = {
    tenants: emailConfigurationFormGroup(),
    landlord: emailConfigurationFormGroup(),
    others: emailConfigurationFormGroup(),
  };
  public buyingOfferForms = {
    buyers: emailConfigurationFormGroup(),
    sellers: emailConfigurationFormGroup(),
    others: emailConfigurationFormGroup(),
  };
  public handoverProtocolForms = {
    transferors: hopEmailConfigurationFormGroup(),
    transferees: hopEmailConfigurationFormGroup(),
    others: hopEmailConfigurationFormGroup(),
  };
  public customerPortalBulkForms = {
    config: bulkEmailConfigurationFormGroup(),
  };
  public customerPortalForms = {
    customers: emailConfigurationFormGroup(),
  };
  public customerPortalDocumentForms = {
    customers: emailConfigurationFormGroup(),
  };
  public customerPortalNotificationForms = {
    customers: emailConfigurationFormGroup(),
  }
  public ownerPortalForms = {
    owners: emailConfigurationFormGroup(),
  };
  public ownerPortalOfferForms = {
    owners: emailConfigurationFormGroup(),
  };


  private _data$: Observable<EmailConfig> = this._store.select(selectSettings).pipe(
    map((settings) => {
      let templates = settings.email.templates;

      if (settings.documentsSettings.countrySettings === CountrySettings.DE) {
        templates = settings.email.i18n?.[Language.DE]?.templates || settings.email.templates;
      }

      return {
        templates,
        emailRecipients: settings.email.emailRecipients,
      };
    }),
  );

  get isViewingPassEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.ViewingPass;
  }

  get isCustomerPortalEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.CustomerPortal;
  }

  get isCustomerPortalBulkEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.CustomerPortalBulkMail;
  }

  get isCustomerPortalDocumentEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.CustomerPortalDocument;
  }

  get isCustomerPortalNotificationEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.CustomerPortalNotification;
  }

  get isOwnerPortalOfferEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.OwnerPortalOffer;
  }

  get isOwnerPortalEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.OwnerPortal;
  }

  get isExclusiveAgreementEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.ExclusiveAgreement;
  }

  get isPlainAgreementEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.PlainAgreement;
  }

  get isRentalOfferEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.RentalOffer;
  }

  get isBuyingOfferEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.BuyingOffer;
  }

  get isHandoverProtocolEmails(): boolean {
    return this.dialogData.entityType === EmailConfigurationEntityType.HandoverProtocol;
  }

  constructor(
    private _store: Store,
    private _dialogRef: MatDialogRef<EmailConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EmailConfigurationDialogData,
    private _translationService: TranslationService,
  ) {
    this._dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this._data$.subscribe((config) => {
      if (this.isViewingPassEmails) {
        this._prefillViewingPassForms(config);
      }
      if (this.isRentalOfferEmails) {
        this._prefillRentalOfferForms(config);
      }
      if (this.isBuyingOfferEmails) {
        this._prefillBuyingOfferForms(config);
      }
      if (this.isHandoverProtocolEmails) {
        this._prefillHandoverProtocolForms(config);
      }
      if (this.isExclusiveAgreementEmails) {
        this._preFillExclusiveAgreementForms(config);
      }
      if (this.isPlainAgreementEmails) {
        this._preFillPlainAgreementForms(config);
      }
      if (this.isCustomerPortalBulkEmails) {
        this._preFillCustomerPortalBulkForms(config);
      }
      if (this.isCustomerPortalEmails) {
        this._preFillCustomerPortalForms(config);
      }
      if (this.isCustomerPortalDocumentEmails) {
        this._preFillCustomerPortalDocumentForms(config);
      }
      if (this.isCustomerPortalNotificationEmails) {
        this._prefillCustomerPortalNotificationForms(config)
      }
      if (this.isOwnerPortalEmails) {
        this._preFillOwnerPortalForms(config);
      }
      if (this.isOwnerPortalOfferEmails) {
        this._preFillOwnerPortalOfferForms(config);
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public sendEmail(form: FormGroup, stepper?: MatStepper, shouldClose = false): void {
    this.loading = true;

    if (form.invalid) {
      form.markAllAsTouched();
      this.loading = false;

      return;
    }

    this.prepareEmail.emit({ entityType: this.dialogData.entityType, emailData: form.value });

    if (shouldClose) {
      setTimeout(() => {
        this.closeDialog();
        this.loading = false
      }, 1000);
    } else {
      if (stepper) {
        stepper.next();
      }

      this.loading = false
    }
  }

  private _preFillExclusiveAgreementForms(data: EmailConfig): void {
    const vars = this.dialogData.data.exclusiveAgreement?.variables!;

    this._prefillEmailConfigurationForm(
      this.exclusiveAgreementForms.contractEntities,
      {
        ...data.templates?.exclusiveAgreement?.contractEntities,
        title: ExclusiveAgreementEmail.mergeVariables(data.templates?.exclusiveAgreement?.contractEntities.title, vars),
        body: ExclusiveAgreementEmail.mergeVariables(data.templates?.exclusiveAgreement?.contractEntities.body, vars),
      },
      this.dialogData.data.exclusiveAgreement?.contractEntitiesEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.exclusiveAgreementForms.others,
      {
        ...data.templates?.exclusiveAgreement?.others,
        title: ExclusiveAgreementEmail.mergeVariables(data.templates?.exclusiveAgreement?.others.title, vars),
        body: ExclusiveAgreementEmail.mergeVariables(data.templates?.exclusiveAgreement?.others.body, vars),
      },
      data.emailRecipients,
    );
  }

  private _preFillPlainAgreementForms(data: EmailConfig): void {
    const vars = this.dialogData.data.plainAgreement?.variables!;

    this._prefillEmailConfigurationForm(
      this.plainAgreementForms.contractEntities,
      {
        ...data.templates?.plainAgreement?.contractEntities,
        title: PlainAgreementEmail.mergeVariables(data.templates?.plainAgreement?.contractEntities.title, vars),
        body: PlainAgreementEmail.mergeVariables(data.templates?.plainAgreement?.contractEntities.body, vars),
      },
      this.dialogData.data.plainAgreement?.contractEntitiesEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.plainAgreementForms.others,
      {
        ...data.templates?.plainAgreement?.others,
        title: PlainAgreementEmail.mergeVariables(data.templates?.plainAgreement?.others.title, vars),
        body: PlainAgreementEmail.mergeVariables(data.templates?.plainAgreement?.others.body, vars),
      },
      data.emailRecipients,
    );
  }

  private _preFillCustomerPortalForms(data: EmailConfig): void {
    const vars = this.dialogData.data.customerPortal?.variables!;

    this._prefillEmailConfigurationForm(
      this.customerPortalForms.customers,
      {
        ...data.templates?.customerPortal?.customers,
        title: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customers.title, vars),
        body: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customers.body, vars),
      },
      this.dialogData.data.customerPortal?.customerEmails ?? [],
    );
  }

  private _preFillCustomerPortalBulkForms(data: EmailConfig): void {
    const vars = this.dialogData.data.customerPortalBulkMail?.variables!;

    this._prefillBulkEmailConfigurationForm(
      this.customerPortalBulkForms.config,
      {
        ...data.templates?.customerPortal?.customers,
        title: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customers.title, vars),
        body: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customers.body, vars),
      },
    );
  }

  private _preFillCustomerPortalDocumentForms(data: EmailConfig): void {
    const vars = this.dialogData.data.customerPortalDocument?.variables!;

    this._prefillEmailConfigurationForm(
      this.customerPortalDocumentForms.customers,
      {
        ...data.templates?.customerPortal?.customersDocument,
        title: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customersDocument.title, vars),
        body: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customersDocument.body, vars),
      },
      this.dialogData.data.customerPortalDocument?.customerEmails ?? [],
    );
  }

  private _prefillCustomerPortalNotificationForms(data: EmailConfig): void {
    const vars = this.dialogData.data.customerPortalNotification?.variables!;

    this._prefillEmailConfigurationForm(
      this.customerPortalNotificationForms.customers,
      {
        ...data.templates?.customerPortal?.customersNotification,
        title: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customersNotification.title, vars),
        body: CustomerPortalEmail.mergeVariables(data.templates?.customerPortal?.customersNotification.body, vars, this._translationService),
      },
      this.dialogData.data.customerPortalNotification?.customerEmails ?? [],
    );
  }

  private _preFillOwnerPortalForms(data: EmailConfig): void {
    const vars = this.dialogData.data.ownerPortal?.variables!;

    this._prefillEmailConfigurationForm(
      this.ownerPortalForms.owners,
      {
        ...data.templates?.customerPortal?.owners,
        title: OwnerPortalEmail.mergeVariables(data.templates?.customerPortal?.owners.title, vars),
        body: OwnerPortalEmail.mergeVariables(data.templates?.customerPortal?.owners.body, vars),
      },
      this.dialogData.data.ownerPortal?.ownerEmails ?? [],
    );
  }

  private _preFillOwnerPortalOfferForms(data: EmailConfig): void {
    const vars = this.dialogData.data.ownerPortalOffer?.variables!;

    this._prefillEmailConfigurationForm(
      this.ownerPortalOfferForms.owners,
      {
        ...data.templates?.customerPortal?.ownersOffer,
        title: OwnerPortalEmail.mergeVariables(data.templates?.customerPortal?.ownersOffer.title, vars),
        body: OwnerPortalEmail.mergeVariables(data.templates?.customerPortal?.ownersOffer.body, vars),
      },
      this.dialogData.data.ownerPortalOffer?.ownerEmails ?? [],
    );
  }

  private _prefillViewingPassForms(data: EmailConfig): void {
    const vars = this.dialogData.data.viewingPass?.variables!;

    this._prefillEmailConfigurationForm(
      this.viewingPassForms.customers,
      {
        ...data.templates?.viewingPass?.customers,
        title: ViewingPassEmail.mergeVariables(data.templates?.viewingPass?.customers.title, vars),
        body: ViewingPassEmail.mergeVariables(data.templates?.viewingPass?.customers.body, vars),
      },
      this.dialogData.data.viewingPass?.customerEmails ?? [],
      this.dialogData.data.viewingPass?.ccEmails,
    );
    this._prefillEmailConfigurationForm(
      this.viewingPassForms.others,
      {
        ...data.templates?.viewingPass?.others,
        title: ViewingPassEmail.mergeVariables(data.templates?.viewingPass?.others.title, vars),
        body: ViewingPassEmail.mergeVariables(data.templates?.viewingPass?.others.body, vars),
      },
      data.emailRecipients,
      this.dialogData.data.viewingPass?.ccEmails,
    );
  }

  private _prefillRentalOfferForms(data: EmailConfig): void {
    const vars = this.dialogData.data.rentalOffer?.variables!;

    this._prefillEmailConfigurationForm(
      this.rentalOfferForms.tenants,
      {
        ...data.templates?.rentalOffer?.tenants,
        title: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.tenants.title, vars),
        body: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.tenants.body, vars),
      },
      this.dialogData.data.rentalOffer?.tenantsEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.rentalOfferForms.landlord,
      {
        ...data.templates?.rentalOffer?.landlord,
        title: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.landlord.title, vars),
        body: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.landlord.body, vars),
      },
      this.dialogData.data.rentalOffer?.landlordEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.rentalOfferForms.others,
      {
        ...data.templates?.rentalOffer?.others,
        title: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.others.title, vars),
        body: RentalOfferEmail.mergeVariables(data.templates?.rentalOffer?.others.body, vars),
      },
      data.emailRecipients,
    );
  }

  private _prefillBuyingOfferForms(data: EmailConfig): void {
    const vars = this.dialogData.data.buyingOffer?.variables!;

    this._prefillEmailConfigurationForm(
      this.buyingOfferForms.buyers,
      {
        ...data.templates?.buyingOffer?.buyers,
        title: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.buyers.title, vars),
        body: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.buyers.body, vars),
      },
      this.dialogData.data.buyingOffer?.buyersEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.buyingOfferForms.sellers,
      {
        ...data.templates?.buyingOffer?.sellers,
        title: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.sellers.title, vars),
        body: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.sellers.body, vars),
      },
      this.dialogData.data.buyingOffer?.sellersEmails ?? [],
    );
    this._prefillEmailConfigurationForm(
      this.buyingOfferForms.others,
      {
        ...data.templates?.buyingOffer?.others,
        title: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.others.title, vars),
        body: BuyingOfferEmail.mergeVariables(data.templates?.buyingOffer?.others.body, vars),
      },
      data.emailRecipients,
    );
  }

  private _prefillHandoverProtocolForms(data: EmailConfig): void {
    const vars = this.dialogData.data.handoverProtocol?.variables!;

    this._prefillEmailConfigurationForm(
      this.handoverProtocolForms.transferors,
      {
        ...data.templates?.handoverProtocol?.transferors,
        title: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.transferors.title, vars),
        body: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.transferors.body, vars),
      },
      this.dialogData.data.handoverProtocol?.transferorEmails ?? [],
      this.dialogData.data.handoverProtocol?.ccEmails,
    );
    this._prefillEmailConfigurationForm(
      this.handoverProtocolForms.transferees,
      {
        ...data.templates?.handoverProtocol?.transferees,
        title: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.transferees.title, vars),
        body: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.transferees.body, vars),
      },
      this.dialogData.data.handoverProtocol?.transfereeEmails ?? [],
      this.dialogData.data.handoverProtocol?.ccEmails,
    );
    this._prefillEmailConfigurationForm(
      this.handoverProtocolForms.others,
      {
        ...data.templates?.handoverProtocol?.others,
        title: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.others.title, vars),
        body: HandoverProtocolEmail.mergeVariables(data.templates?.handoverProtocol?.others.body, vars),
      },
      data.emailRecipients,
      this.dialogData.data.handoverProtocol?.ccEmails,
    );
  }

  private _prefillEmailConfigurationForm(
    form: FormGroup,
    templateDefaults: EmailTemplate,
    recipients: string[],
    recipientsCopy: string[] = [],
  ): void {
    const prefillData = {
      ...(templateDefaults ?? {}),
      recipients,
      recipientsCopy,
    };

    form.patchValue(prefillData);

    if (templateDefaults?.attachments && templateDefaults.attachments.length) {
      const attachmentsFormArray = form.controls.attachments as FormArray;

      templateDefaults.attachments.forEach(a => {
        const attachment = new FormGroup({
          storageUrl: new FormControl(a.storageUrl),
          description: new FormControl(a.description),
        });
        attachmentsFormArray.push(attachment);
      });
    }
  }

  private _prefillBulkEmailConfigurationForm(
    form: FormGroup,
    templateDefaults: EmailTemplate,
    recipientsCopy: string[] = [],
  ): void {
    const prefillData = {
      ...(templateDefaults ?? {}),
      recipientsCopy,
    };

    form.patchValue(prefillData);

    if (templateDefaults?.attachments && templateDefaults.attachments.length) {
      const attachmentsFormArray = form.controls.attachments as FormArray;

      templateDefaults.attachments.forEach(a => {
        const attachment = new FormGroup({
          storageUrl: new FormControl(a.storageUrl),
          description: new FormControl(a.description),
        });
        attachmentsFormArray.push(attachment);
      });
    }
  }
}
