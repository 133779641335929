import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SlideToggleModule } from '../slide-toggle/slide-toggle.module';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'common-single-payment',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SlideToggleModule,
    MatInputModule
  ],
  template: `
    <section [formGroup]="formGroup">
      <common-slide-toggle
        label="Einmalige Investitionsablöse"
        [control]="this.formGroup.controls.singlePayment"
      ></common-slide-toggle>
      <div
        class="flex flex-col space-y-4"
        *ngIf="formGroup.controls.singlePayment.value == true"
      >
        <mat-form-field appearance="outline">
          <mat-label>Beschreibung</mat-label>
          <textarea
            formControlName="singlePaymentInfo"
            matInput
            id="notes"
            rows="6"
          ></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Betrag</mat-label>
          <input
            [min]="0"
            type="number"
            matInput
            formControlName="singlePaymentAmount"
          />
        </mat-form-field>
      </div>
    </section>
  `,
  styles: [`:host {
      @apply: block;
  }`],
})
export class SinglePaymentComponent {
  @Input()
  formGroup!: FormGroup;
}
