<section>
  <form [formGroup]="agentFormGroup" class="flex flex-col space-y-2">
    <mat-card class="agent-card">
      <mat-card-header>
        <mat-card-title>
          {{ 'general.contact_person' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="my-2 flex items-center w-full">
          <div class="flex justify-center items-center">
            <img
              *ngIf="agent?.picture; else noAgentImage"
              class="agent-image mr-4"
              [src]="agent?.picture"
              alt="Maklerbild"
            />
            <ng-template #noAgentImage>
              <mat-icon
                class="mr-2 !w-10 !h-10 text-4xl text-gray-medium"
                fontIcon="account_circle"
              ></mat-icon>
            </ng-template>
          </div>
          <div class="w-full">
            <p class="text-lg font-semibold mb-2">
              {{ agent?.firstName }} {{ agent?.lastName }}
            </p>
            <div class="flex w-full">
              <mat-form-field appearance="fill" class="w-1/2">
                <mat-label>Rechtsträger</mat-label>
                <mat-select
                  [value]="selectedCompanyInfo"
                  [compareWith]="companyInfoCompareWith"
                  (selectionChange)="onCompanyInfoChange($event)"
                >
                  <mat-option
                    *ngFor="let ci of availableCompanyInfos"
                    [value]="ci"
                  >
                    {{ ci.legalEntity }} / {{ ci.officeName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions align="start" *ngIf="!loading">
        <common-button (click)="openSelectAgentDialog()" [dynamicWidth]="true">
          {{ 'general.select' | translate }}
        </common-button>
      </mat-card-actions>
    </mat-card>
  </form>
</section>
