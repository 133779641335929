<section>
  <customer-panel
    *ngFor="let fg of formArray.controls; let i = index"
    [formGroup]="getAbstractControlAsFormGroup(fg)"
    [index]="i"
    [expand]="i === step"
    (openedStep)="setStep($event)"
    (remove)="removeCustomer($event)"
    [showPep]="showPep"
    class="m-4"
  >
  </customer-panel>
</section>

<section class="flex flex-col gap-y-8 items-start">
  <button class="text-primary" (click)="addPrivateCustomer()">
    <span class="text-xl">+</span> Weitere vertretungsbefugte Person hinzufügen
  </button>
</section>
